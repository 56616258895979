import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

import chptrElephantUrl from '../assets/chptr-elephant.png';
import { useAnalytics } from '../contexts/analytics';
import { usePurchase } from '../contexts/purchase.tsx';
import { useUser } from '../contexts/user.tsx';
import {
  ChptrVisibility,
  PurchaseOrderFragment,
  useAddOrderPromotionMutation,
  useCreateChptrMutation,
  useCreateOrderMutation,
  useGetUserOrderQuery,
  usePlacementQuery,
  useRemoveOrderPromotionMutation,
  useUpdateChptrMutation,
  useUpdateOrderMutation,
} from '../gql/generated.tsx';
import { convertToCurrency, splitDate } from '../lib/utils.ts';
import PurchaseAddon from './PurchaseAddon.tsx';
import Button from './ui/Button.tsx';
import FormPhoneInput from './ui/FormPhoneInput.tsx';

const PhoneFormSchema = z.object({
  phone: z.string().refine((value) => isValidPhoneNumber(value), {
    message: 'Invalid Phone Number',
    path: ['phone'],
  }),
});

type PhoneFormSchemaType = z.infer<typeof PhoneFormSchema>;

const CheckoutForm = () => {
  const { showBoundary } = useErrorBoundary();

  const { show, createdOrderId } = usePurchase();
  const { loaded: analyticsLoaded, segmentTrack } = useAnalytics();

  const [user] = useUser();

  useEffect(() => {
    if (analyticsLoaded) {
      segmentTrack('Viewed Enterprise —- Checkout');
    }
  }, [analyticsLoaded, segmentTrack]);

  const { data, error, loading } = useGetUserOrderQuery({
    variables: {
      userId: user?.id ?? '',
      orderId: createdOrderId ?? '',
    },
  });

  if (error) {
    showBoundary(error);
    return null;
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <div className="space-y-6 rounded-[10px] border border-[#E2E2E2] px-4 py-6">
        <p className="font-heading text-[22px]">Account Information</p>
        <p className="mt-6">
          Not you?{' '}
          <button onClick={() => show('SIGNIN')} className="underline">
            Sign In
          </button>{' '}
          or{' '}
          <button onClick={() => show('REGISTER')} className="underline">
            create account
          </button>
        </p>
        <div className="m-auto flex max-w-max flex-col items-center justify-center space-y-4 rounded-[15px] border border-[#D9D9D9] bg-[#F8F8F8] py-6 pl-4 pr-8 md:m-0 md:flex-row md:space-x-6 md:space-y-0 ">
          <div className="flex h-[51px] w-[51px] items-center justify-center rounded-full bg-gradient-to-b from-[#51BFD7] to-[#71D26F]">
            <span className="text-2xl font-medium text-white">{`${user?.firstName[0]}${user?.lastName[0]}`}</span>
          </div>
          <div className="space-y-2 text-center md:text-left">
            <p className="font-heading text-[20px] leading-[26px]">
              {`${user?.firstName} ${user?.lastName}`}
            </p>
            <p className="text-[17px] leading-[22px]">{user?.email}</p>
          </div>
        </div>
      </div>
      {data?.user?.order?.id ? (
        <UpdateOrderForm order={data.user.order} />
      ) : (
        <CreateOrderForm />
      )}
    </>
  );
};

const CreateOrderForm = () => {
  const {
    submittedDetails,
    saveDetails,
    setCreatedChptrId,
    setCreatedOrderId,
    selectedProducts,
    setPaymentIntentClientSecret,
  } = usePurchase();

  const { showBoundary } = useErrorBoundary();

  const { placementId } = useParams<{ placementId: string }>();
  const { segmentTrack } = useAnalytics();
  const [user] = useUser();

  const [createChptr] = useCreateChptrMutation({
    onCompleted: (data) => {
      segmentTrack('Chptr Created', {
        id: data.createChptr.id as string,
        email: user?.email as string,
        firstName: data.createChptr.firstName as string,
        lastName: data.createChptr.lastName as string,
      });
    },
  });
  const [createOrder] = useCreateOrderMutation({});

  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<PhoneFormSchemaType>({
    resolver: zodResolver(PhoneFormSchema),
    defaultValues: {
      phone: submittedDetails?.mainChptrSignup.phone,
    },
  });

  const onSubmit = async ({ phone }: PhoneFormSchemaType) => {
    setLoading(true);

    const cleanReps = submittedDetails?.repGroups
      ? submittedDetails?.repGroups.filter(
          (rep) =>
            rep.name?.trim() !== '' ||
            rep.email?.trim() !== '' ||
            rep.phone?.trim() !== '',
        )
      : [];

    try {
      const createChptrResult = await createChptr({
        variables: {
          input: {
            picture: submittedDetails?.mainChptrSignup.picture,
            firstName: submittedDetails?.mainChptrSignup.firstName as string,
            lastName: submittedDetails?.mainChptrSignup.lastName as string,
            propertyId:
              submittedDetails?.mainChptrSignup.propertyId === 'other'
                ? null
                : submittedDetails?.mainChptrSignup.propertyId,
            bornAt: splitDate(
              submittedDetails?.mainChptrSignup.dateOfBirth as string,
            ),
            passedAt: submittedDetails?.mainChptrSignup.dateOfDeath
              ? splitDate(
                  submittedDetails?.mainChptrSignup.dateOfDeath as string,
                )
              : undefined,
            location: submittedDetails?.mainChptrSignup.location as string,
            reps: cleanReps || [],
            visibility: ChptrVisibility.Public,
            placementId,
          },
        },
      });

      const createOrderResult = await createOrder({
        variables: {
          input: {
            chptrId: createChptrResult.data?.createChptr.id as string,
            productIds: selectedProducts,
            phoneNumber: phone,
          },
        },
      });

      setPaymentIntentClientSecret(
        createOrderResult.data?.createOrder.id as string,
      );
      setCreatedChptrId(createChptrResult.data?.createChptr.id as string);
      setCreatedOrderId(createOrderResult.data?.createOrder.id as string);

      const phoneUpdate = { ...submittedDetails?.mainChptrSignup, phone };
      saveDetails({
        ...submittedDetails,
        mainChptrSignup: phoneUpdate,
      });
    } catch (e) {
      return showBoundary(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="space-y-6 rounded-[10px] border border-[#E2E2E2]">
          <div className="px-4 py-6">
            <p className="mb-4 font-heading text-[22px]">Purchase Details</p>

            <div className="flex flex-col space-y-4">
              <FormPhoneInput label="Phone Number*" name="phone" />

              <div className="text-center">
                <Button
                  loading={loading}
                  disabled={loading}
                  className="w-[250px] text-[19px]"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const UpdateOrderForm = ({ order }: { order: PurchaseOrderFragment }) => {
  const { showBoundary } = useErrorBoundary();

  const { saveDetails, submittedDetails, show, setPaymentIntentClientSecret } =
    usePurchase();

  const { placementId } = useParams<{ placementId: string }>();

  const [loading, setLoading] = useState<boolean>(false);

  const { data: placementData } = usePlacementQuery({
    variables: { placementId: placementId ?? '' },
  });

  const addons = placementData?.placement?.property.addons ?? [];

  const orderedPackages = order.products.filter((o) => o.type === 'PACKAGE');
  const orderedAddons = order.products.filter((o) => o.type === 'ADDON');

  const methods = useForm<PhoneFormSchemaType>({
    resolver: zodResolver(PhoneFormSchema),
    defaultValues: {
      phone: submittedDetails?.mainChptrSignup.phone,
    },
  });

  const [updateChptr] = useUpdateChptrMutation({});

  const [updateOrder] = useUpdateOrderMutation({});

  const onSubmit: SubmitHandler<PhoneFormSchemaType> = async ({ phone }) => {
    setLoading(true);

    const updateChptrResult = await updateChptr({
      variables: {
        input: {
          id: order.chptr.id,
          picture: submittedDetails?.mainChptrSignup.picture,
          firstName: submittedDetails?.mainChptrSignup.firstName as string,
          lastName: submittedDetails?.mainChptrSignup.lastName as string,
          bornAt: splitDate(
            submittedDetails?.mainChptrSignup.dateOfBirth as string,
          ),
          passedAt: submittedDetails?.mainChptrSignup.dateOfDeath
            ? splitDate(submittedDetails?.mainChptrSignup.dateOfDeath as string)
            : undefined,
          location: submittedDetails?.mainChptrSignup.location as string,
          reps: submittedDetails?.repGroups || [],
        },
      },
    });

    if (updateChptrResult.errors) {
      return showBoundary(updateChptrResult.errors);
    }

    const updateOrderResult = await updateOrder({
      variables: {
        input: {
          id: order.id,
          phoneNumber: phone,
          productIds: order.products.map((o) => o.id),
        },
      },
    });

    if (updateOrderResult.errors) {
      return showBoundary(updateOrderResult.errors);
    }

    setPaymentIntentClientSecret(order?.stripeKey);

    const phoneUpdate = { ...submittedDetails?.mainChptrSignup, phone };
    saveDetails({
      ...submittedDetails,
      mainChptrSignup: phoneUpdate,
    });
    show('PAY');
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="space-y-6 rounded-[10px] border border-[#E2E2E2]">
          <div className="px-4 py-6">
            <p className="mb-4 font-heading text-[22px]">Purchase Details</p>

            <div className="flex flex-col space-y-2">
              <FormPhoneInput label="Phone Number*" name="phone" />
            </div>

            <div className="mt-6 rounded-xl bg-[#DFEBEE] px-4">
              <div className="!mt-0 grid-cols-2 gap-10 space-y-[40px] py-6 md:grid">
                {addons.length > 0 && (
                  <div className="mt-2 space-y-6">
                    {addons.map((o) => (
                      <PurchaseAddon key={o.id} order={order} product={o} />
                    ))}
                  </div>
                )}
                <div className="space-y-4 md:!mt-0">
                  <p className="font-heading text-[22px]">Order Summary</p>
                  <div className="flex flex-col space-y-[20px] md:flex-row md:space-x-4 md:space-y-0">
                    <img
                      alt="team photo"
                      src={chptrElephantUrl}
                      className="m-auto w-[172px] md:m-0"
                    />
                    <p className="text-center text-sm text-[#282828] md:text-left">
                      The team is excited to help you memorialize.
                    </p>
                  </div>
                  <div className="space-y-4 border-b border-b-[#999999] pb-4">
                    {orderedPackages.map((o) => (
                      <div key={o.id}>
                        <div className="flex justify-between font-heading text-[20px] font-medium leading-[28px]">
                          <p>{o.name}</p>
                          <p className="font-sans">
                            {convertToCurrency(o.amount, order.currency)}
                          </p>
                        </div>
                        <p className="text-[#666666]">one time payment</p>
                      </div>
                    ))}
                    {orderedAddons.map((o) => (
                      <div key={o.id}>
                        <div className="flex justify-between font-heading text-[20px] font-medium leading-[28px]">
                          <p>{o.name}</p>
                          <p className="font-sans">
                            {convertToCurrency(o.amount, order.currency)}
                          </p>
                        </div>
                        <p className="text-[#666666]">one time payment</p>
                      </div>
                    ))}
                  </div>
                  <DiscountForm order={order} />
                  <div className="space-y-2">
                    <div className="flex justify-between text-[20px] font-medium leading-[27px]">
                      <p>Total due today</p>
                      <p>
                        {convertToCurrency(
                          order.promotedAmount,
                          order.currency,
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="!mt-0 space-y-[28px] py-[30px]">
                <div className="text-center">
                  <Button
                    className="w-[250px] text-[19px]"
                    disabled={loading}
                    loading={loading}
                    type="submit"
                  >
                    Confirm Order
                  </Button>
                </div>
                <p className="text-center">
                  By placing your order, you agree to our terms of service and
                  privacy policy.
                </p>
                {/* {error && (
                  <Callout variant="error">
                    An error occurred posting your contribution. Please try
                    again.
                  </Callout>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const DiscountForm = ({ order }: { order: PurchaseOrderFragment }) => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const [promoCode, setPromoCode] = useState<string>('');

  const [addOrderPromotion] = useAddOrderPromotionMutation({});

  const addPromoCode = async () => {
    setLoading(true);

    try {
      await addOrderPromotion({
        variables: { input: { orderId: order.id, code: promoCode } },
      });

      setPromoCode('');
      setError('');
    } catch (e: unknown) {
      if (
        (e as { message: string })?.message ===
        'Promotion does not apply to any products in the order'
      ) {
        setError('The product this code is intended for is not in this cart');
      } else {
        setError('Invalid code');
      }
    } finally {
      setLoading(false);
    }
  };

  const [removeOrderPromotion] = useRemoveOrderPromotionMutation({});

  const removePromoCode = async (code: string) => {
    await removeOrderPromotion({
      variables: { input: { code, orderId: order.id } },
    });
  };

  return (
    <div className="space-y-4 border-b border-b-[#999999] pb-4">
      <div className="flex justify-between text-[17px] font-medium leading-[22px]">
        <p>Subtotal</p>
        <p>{convertToCurrency(order.amount, order.currency)}</p>
      </div>

      {order.promotions.length > 0 && (
        <div className="space-y-4 border-t border-t-[#999999] pt-4">
          <div className="flex justify-between text-[17px] font-medium leading-[22px]">
            <p>Discounts</p>
            <p>
              {convertToCurrency(
                order.amount - order.promotedAmount,
                order.currency,
              )}
            </p>
          </div>
          {order.promotions.map((o) => (
            <div key={o.id} className="text-[17px] leading-[22px]">
              <p>
                {o.code}
                <span
                  className="ml-1 cursor-pointer text-sm text-[#666666]"
                  onClick={() => removePromoCode(o.code)}
                >
                  (Remove code)
                </span>
              </p>
            </div>
          ))}
        </div>
      )}

      <div className="flex space-x-4">
        <div className="flex flex-1 flex-col space-y-2">
          <input
            value={promoCode}
            onChange={(e) => {
              setPromoCode(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                addPromoCode();
              }
            }}
            className={classNames(
              'h-[42px] rounded-[10px] border-[1px] border-[#D9D9D9] bg-[#F8F8F8] p-2',
              { 'border-[#EF341E]': error },
            )}
            placeholder="Gift card or promo code"
          />

          {error && (
            <p className="!mt-1 text-[13px] leading-[16px] text-[#EF341E]">
              {error as string}
            </p>
          )}
        </div>

        <div>
          <Button
            loading={loading}
            disabled={loading}
            type="button"
            className="!px-4 sm:!px-8"
            onClick={addPromoCode}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;

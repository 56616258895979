import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { BsArrowLeft, BsXLg } from 'react-icons/bs';
import { BsChat } from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';
import { IoLogoYoutube } from 'react-icons/io';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import { useIntersectionObserver } from 'usehooks-ts';

import Authentication from '../../components/Authentication';
import Button from '../../components/ui/Button';
import PageModal from '../../components/ui/PageModal';
import RemoteImage from '../../components/ui/RemoteImage';
import { useUser } from '../../contexts/user';
import {
  CommentStatus,
  ContributionCommentFragment,
  ContributionMediaType,
  useCreateCommentMutation,
  useGetContributionCommentsQuery,
} from '../../gql/generated';
import useAutosizeTextArea from '../../hooks/useAutosizeTextarea';
import useBreakpoints from '../../hooks/useBreakpoints';
import { abbreviateLastName } from '../../lib/utils';

const DEFAULT_PAGE_SIZE = 6;

const ContributionComments = () => {
  const [user] = useUser();

  const [showAuth, setShowAuth] = useState(false);
  useEffect(() => {
    if (user) {
      setShowAuth(false);
    }
  }, [user, showAuth]);

  const commentTopRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState('');

  const { contributionId } = useParams<{ contributionId: string }>();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const { height } = useAutosizeTextArea(textAreaRef.current, value);

  const bp = useBreakpoints();

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;

    setValue(val);
  };

  const handleCreate = async () => {
    if (!value || !contributionId) return;

    await createComment({
      variables: {
        input: {
          contributionId: contributionId,
          text: value,
        },
      },
    });

    setValue('');
    await refetch();
    if (commentTopRef.current !== null) {
      commentTopRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const { data, loading, refetch, fetchMore } = useGetContributionCommentsQuery(
    {
      variables: {
        contributionId: contributionId ?? '',
        commentsFirst: DEFAULT_PAGE_SIZE,
      },
    },
  );

  const [createComment, { loading: createLoading }] =
    useCreateCommentMutation();

  const contribution = data?.contribution;

  const captionLines = contribution?.caption
    ? contribution.caption.split('\n')
    : [];

  const { ref: commentBottomRef } = useIntersectionObserver({
    threshold: 0.1,
    onChange: (isIntersecting) => {
      if (isIntersecting && contribution?.commentsV2.pageInfo.hasNextPage) {
        fetchMore({
          variables: {
            contributionId: contributionId,
            commentsAfter: contribution?.commentsV2.pageInfo.endCursor,
          },
        });
      }
    },
  });

  if (showAuth) {
    return (
      <PageModal containerClassName="!p-0 w-full h-full rounded-none sm:h-auto sm:max-w-[730px] sm:overflow-clip sm:rounded-[10px] sm:border sm:border-1 sm:border-[#d9d9d9]">
        <div className="left-0 top-0 z-10 w-full bg-white p-4">
          <Button
            variant="stroke"
            onClick={() => {
              window.parent.postMessage(['closeModal'], '*');
            }}
            className="z-10 w-[93px] !px-2 sm:hidden"
          >
            <BsArrowLeft className="mb-1 mr-1" /> Back
          </Button>
          <button
            className="hidden sm:block"
            onClick={() => {
              window.parent.postMessage(['closeModal'], '*');
            }}
          >
            <BsXLg />
          </button>
        </div>
        <div className="h-[calc(100%_-_74px)] overflow-y-auto px-6 py-4">
          <Authentication />
        </div>
      </PageModal>
    );
  }

  return (
    contribution &&
    !loading && (
      <PageModal containerClassName="relative w-full h-full rounded-none !p-0 sm:max-w-[730px] sm:max-h-[562px] sm:overflow-clip sm:rounded-[10px] sm:border sm:border-1 sm:border-[#d9d9d9]">
        <div className="z-10 w-full bg-white p-4">
          <Button
            variant="stroke"
            onClick={() => {
              window.parent.postMessage(['closeModal'], '*');
            }}
            className="z-10 w-[93px] !px-2 sm:hidden"
          >
            <BsArrowLeft className="mb-1 mr-1" /> Back
          </Button>
          <button
            className="hidden sm:block"
            onClick={() => {
              window.parent.postMessage(['closeModal'], '*');
            }}
          >
            <BsXLg />
          </button>
        </div>
        <div className="grid h-full grid-cols-1 overflow-y-auto overscroll-y-none pb-[140px] sm:h-[488px] sm:grid-cols-2 sm:overflow-y-hidden sm:pb-0">
          <div className="sm:overflow-y-auto">
            <div className="p-4">
              {contribution.mediaType === ContributionMediaType.Image &&
                contribution.blurHash && (
                  <RemoteImage
                    className={classNames('max-w-1/3 rounded-xl')}
                    src={contribution.url!}
                    hash={contribution.blurHash}
                  />
                )}

              {contribution.mediaType === ContributionMediaType.Video &&
                contribution.url && (
                  <div className="w-full">
                    <ReactPlayer
                      width={'auto'}
                      playIcon={<IoLogoYoutube size={50} color="#e5e5e5" />}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: 15,
                        overflow: 'hidden',
                        backgroundColor: 'black',
                      }}
                      url={contribution.url}
                      controls
                    />
                  </div>
                )}

              <div className="text-[17px] leading-[22px] text-[#282828]">
                {captionLines.map((line: string, idx: number) =>
                  idx < captionLines.length - 1 ? (
                    <div key={idx}>
                      {line}
                      <br />
                    </div>
                  ) : (
                    <div key={idx}>{line}</div>
                  ),
                )}
              </div>

              <div className="mt-4">
                <span className="text-[17px] font-bold text-[#282828]">
                  {abbreviateLastName(
                    contribution.user.firstName,
                    contribution.user.lastName,
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="sm:relative">
            <div
              className={`border-t-1 h-full space-y-4 border-t pt-4 sm:max-h-[353px] sm:overflow-y-auto sm:border-0 sm:pt-0`}
              style={{ paddingBottom: bp.isXs ? height + 87 : 0 }}
            >
              <div ref={commentTopRef}></div>

              {contribution.commentsV2.edges.map((comment) => (
                <Comment key={comment.node.id} comment={comment.node} />
              ))}

              <div ref={commentBottomRef}></div>

              {contribution.commentsV2.pageInfo.hasNextPage && (
                <>
                  {Array.from({ length: 2 }).map((_, idx) => (
                    <div
                      key={idx.toString()}
                      className="mb-4 flex animate-pulse flex-col space-y-4 rounded-xl bg-[#2E5E6B0D] p-4"
                    >
                      <div className="h-3 w-12 bg-neutral-300" />
                      <div className="h-3 bg-neutral-300" />
                      <div className="h-3 w-48 bg-neutral-300" />
                    </div>
                  ))}
                </>
              )}
            </div>

            <div className="absolute inset-x-0 bottom-0 z-10 space-y-4 bg-white p-4 sm:pb-0">
              <div className="flex justify-between">
                <div className="border-1 flex h-[38px] items-center space-x-2 rounded-[100px] border border-[#E2E2E2] bg-[#FAFAFA] px-6 py-[10px] text-[#282828]">
                  <BsChat size={20} />{' '}
                  <span>{contribution.commentsV2.totalCount}</span>
                </div>

                <button
                  className={classNames({
                    'opacity-50': createLoading || !user,
                  })}
                  onClick={handleCreate}
                  disabled={createLoading || !user}
                >
                  <div className="border-1 flex h-[38px] items-center space-x-2 rounded-[100px] border border-[#E2E2E2] bg-[#FAFAFA] px-4 py-[10px] text-[#282828]">
                    {createLoading ? (
                      <AiOutlineLoading className="mr-2 animate-spin" />
                    ) : (
                      <FiSend size={20} />
                    )}
                  </div>
                </button>
              </div>

              {user?.email ? (
                <textarea
                  disabled={createLoading}
                  onChange={handleChange}
                  ref={textAreaRef}
                  value={value}
                  rows={1}
                  placeholder="Say something here..."
                  className={classNames(
                    'w-full resize-none rounded-[10px] border-[1px] border-[#D9D9D9] bg-[#F8F8F8] p-2',
                  )}
                />
              ) : (
                <div>
                  <Button
                    className="w-full"
                    onClick={() => {
                      setShowAuth(true);
                    }}
                  >
                    Please sign in to comment
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </PageModal>
    )
  );
};

const Comment = ({ comment }: { comment: ContributionCommentFragment }) => {
  const lines = comment.text.split('\n');

  return (
    <div className="mx-4 rounded-[20px] bg-[#2E5E6B0D] p-4">
      <span className="text-[17px] font-bold text-[#282828]">
        {abbreviateLastName(comment.user.firstName, comment.user.lastName)}
      </span>
      {lines.map((line: string, idx: number) =>
        idx < lines.length - 1 ? (
          <div key={idx} className="break-words">
            {line}
            <br />
          </div>
        ) : (
          <div key={idx} className="break-words">
            {line}
          </div>
        ),
      )}
      {comment.status === CommentStatus.Pending && (
        <div className="mt-2">
          <span className="rounded-full bg-[#E2E2E2] px-2 py-1 text-xs italic">
            Pending Approval
          </span>
        </div>
      )}
    </div>
  );
};

export default ContributionComments;

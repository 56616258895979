import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

type ApplicationContext = {
  scrollPosition: number;
  setScrollPosition: (position: number) => void;
  getScrollPosition: () => number;

  parentWindowHeight: number;
  setParentWindowHeight: Dispatch<SetStateAction<number>>;

  chptrId: string | null;
  setChptrId: Dispatch<SetStateAction<string | null>>;
};

const ApplicationContext = createContext<ApplicationContext | undefined>(
  undefined,
);

export const ApplicationProvider = ({
  position: initialPosition,
  children,
}: {
  position: number;
  children: React.ReactNode;
}) => {
  const position = useRef<number>(initialPosition);

  const [chptrId, setChptrId] = useState<string | null>(null);
  const [parentWindowHeight, setParentWindowHeight] = useState<number>(500);

  const setScrollPosition = useCallback((nextPosition: number) => {
    position.current = nextPosition;
  }, []);

  const getScrollPosition = useCallback(() => position.current, []);

  return (
    <ApplicationContext.Provider
      value={{
        scrollPosition: position.current,
        setScrollPosition,
        getScrollPosition,

        parentWindowHeight,
        setParentWindowHeight,

        chptrId,
        setChptrId,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useApplication = () => {
  const application = useContext(ApplicationContext);

  if (application === undefined) {
    throw new Error('useApplication must be used within a ApplicationProvider');
  }

  return application as ApplicationContext;
};

import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { ReactNode, useEffect, useState } from 'react';

import useGoogleMaps from '../../hooks/useGoogleMaps';
import AddressLink from './AddressLink';

const AddressText = ({ children }: { children: ReactNode }) => {
  const { fetchPlace, isAPIReady, fitMapBounds } = useGoogleMaps();

  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(
    null,
  );
  const [address, setAddress] = useState<string | null>(null);

  useEffect(() => {
    if (isAPIReady && children) {
      fetchPlace(children as string).then((place) => {
        setAddress(place?.formatted_address || '');
        setPlace(place);
        fitMapBounds(place);
      });
    }
  }, [children, fitMapBounds, fetchPlace, isAPIReady]);

  return (
    <div>
      {address && <AddressLink address={address} />}
      <Map
        style={{
          width: '100%',
          height: '150px',
          marginTop: '8px',
          display: place?.geometry?.location?.lat() ? 'block' : 'none',
        }}
        defaultZoom={3}
        defaultCenter={{ lat: 0, lng: 0 }}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
      >
        {place?.geometry?.location?.lat() && (
          <Marker
            position={{
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            }}
          />
        )}
      </Map>
    </div>
  );
};

const AddressTextWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY}>
      <AddressText>{children}</AddressText>
    </APIProvider>
  );
};

export default AddressTextWrapper;

import { useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { IoIosSearch } from 'react-icons/io';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import flowerUrl from '../assets/flower.png';
import { ChptrCardSkeleton } from '../components/ChptrCard';
import EventCard from '../components/EventCard';
import IntercomMessenger from '../components/IntercomMessenger';
import Button from '../components/ui/Button';
import { useUser } from '../contexts/user';
import {
  ChptrType,
  GetPlacementEventsQueryVariables,
  SortOrder,
  useGetPlacementEventsQuery,
  usePlacementQuery,
} from '../gql/generated';
import { capitalize } from '../lib/utils';
import { DEFAULT_CONFIG } from '../modules/Constants';
import { PlacementConfig } from './Home';

const EventSearch = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const { placementId } = useParams();

  const [searchParams] = useSearchParams();

  const [user] = useUser();

  useEffect(() => {
    IntercomMessenger(user);
    (window.top || window.parent).postMessage(['scrollToTop'], '*');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [searchQuery, setSearchQuery] = useState<string>(
    searchParams.get('q') ?? '',
  );

  const variables: GetPlacementEventsQueryVariables = {
    eventsQuery: searchParams.get('q') ?? '',
    placementId: placementId ?? '',
    eventsFirst: 6,
    eventsOrdersBy: {
      startAt: SortOrder.Desc,
    },
  };

  const {
    data,
    loading: eventsLoading,
    fetchMore,
  } = useGetPlacementEventsQuery({
    variables,
  });

  const { data: placementData, loading: placementLoading } = usePlacementQuery({
    variables: {
      placementId: placementId ?? '',
    },
  });

  const loading = placementLoading || eventsLoading;

  const config: PlacementConfig = placementData?.placement?.configuration;

  const eventType =
    placementData?.placement?.chptrType === ChptrType.Memorial
      ? 'service'
      : 'event';

  return (
    !loading && (
      <div className="mx-auto max-w-[1200px] space-y-6 px-6 py-[20px]">
        <div className="flex justify-between font-heading">
          <p className="text-3xl font-medium md:text-4xl">
            {`${capitalize(eventType)}s`}
          </p>

          <Link
            to={`/${placementId}/purchase`}
            state={{ redirectBack: location.pathname }}
          >
            <Button className="hidden !px-[70px] sm:block">
              {config?.purchaseButtonText ??
                DEFAULT_CONFIG.PURCHASE_BUTTON_TEXT}
            </Button>

            <Button className="!px-[20px] sm:hidden">
              {config?.purchaseButtonAbbreviatedText ??
                DEFAULT_CONFIG.PURCHASE_BUTTON_ABBREVIATED_TEXT}
            </Button>
          </Link>
        </div>

        <div className="hidden flex-col space-y-2 sm:flex">
          <div>
            <div className="w-full rounded-2xl border border-[#d9d9d9] p-4">
              <p className="mb-2 font-heading text-lg text-[#282828]">
                {`Find ${eventType}`}
              </p>

              <IoIosSearch className="absolute ml-3 mt-3 h-5 w-5 text-[#282828]" />

              <input
                placeholder={`Find ${eventType}`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="flex h-[45px] w-full rounded-[50px] border-[1px] border-[#E2E2E2] bg-neutral-100 pl-10 pr-1 font-heading focus:outline-none"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && searchQuery.length > 0) {
                    navigate(`/${placementId}/event-search?q=${searchQuery}`);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <Button
          variant="stroke"
          className="w-[93px] !px-2"
          onClick={() => navigate(`/${placementId}`)}
        >
          <BsArrowLeft className="mb-1 mr-1" /> Back
        </Button>

        {searchParams.get('q') &&
          !loading &&
          (data?.placement?.events?.totalCount ?? 0) > 0 && (
            <p className="font-heading text-xl leading-[36px] md:text-2xl">
              Showing {data?.placement?.events.totalCount} results for "
              {searchParams.get('q')}"
            </p>
          )}

        {!searchParams.get('q') && (
          <p className="font-heading text-[22px] leading-[36px] md:text-2xl">
            {`${capitalize(eventType)}s`}
          </p>
        )}

        {!loading && (
          <div>
            {data?.placement?.events?.totalCount ?? 0 > 0 ? (
              <div className="grid grid-cols-1 gap-4">
                {data?.placement?.events.edges.map((e) => (
                  <EventCard key={e.node.id} event={e.node} />
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center space-y-6 text-center">
                <img src={flowerUrl} className="max-w-[200px]" />

                <p className="w-[90vw] truncate font-heading text-[20px] leading-[26px]">
                  No results for
                  <br /> "{searchParams.get('q')}"
                </p>

                <p className="w-[90vw] truncate font-heading text-[20px] leading-[26px]">
                  Want to create a Chptr for
                  <br /> {searchParams.get('q')}?
                </p>

                <Button onClick={() => navigate(`/${placementId}/purchase`)}>
                  Purchase Chptr
                </Button>
              </div>
            )}
          </div>
        )}

        {loading && (
          <div className="grid grid-cols-1 gap-4">
            {Array.from({ length: 2 }).map((_, idx) => (
              <ChptrCardSkeleton key={idx.toString()} />
            ))}
          </div>
        )}

        {data?.placement?.events.pageInfo.hasNextPage && (
          <div className="flex justify-center space-x-4">
            <Button
              variant="stroke"
              className="!px-[50px]"
              onClick={() => {
                fetchMore({
                  variables: {
                    query: searchQuery,
                    eventsAfter: data?.placement?.events.pageInfo.endCursor,
                  },
                });
              }}
            >
              See More
            </Button>
          </div>
        )}
      </div>
    )
  );
};

export default EventSearch;

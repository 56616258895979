import cn from 'classnames';
import heic2any from 'heic2any';
import { ReactNode, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { FaX } from 'react-icons/fa6';
import { MdOutlinePhotoLibrary } from 'react-icons/md';

import { useAnalytics } from '../../contexts/analytics.tsx';
import Button from './Button.tsx';

export default function FormImageFileInput({
  name,
  label,
  fileTypeLabel,
}: {
  name: string;
  label: string;
  fileTypeLabel?: ReactNode;
}) {
  const {
    control,
    formState: { errors, defaultValues },
    setValue,
  } = useFormContext();

  const { segmentTrack } = useAnalytics();

  const initialFile =
    defaultValues && name in defaultValues ? defaultValues[name] : undefined;

  const [file, setFile] = useState<File | undefined>(initialFile);
  const [loading, setLoading] = useState<boolean>(false);
  const [objectUrl, setObjectUrl] = useState<string | null | undefined>();

  const err = name in errors ? errors[name] : undefined;

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    multiple: false,
    maxFiles: 1,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png', '.heic'],
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file.name.toLowerCase().endsWith('.heic')) {
        setLoading(true);

        heic2any({
          blob: file,
          toType: 'image/png',
          quality: 1,
        })
          .then((result) => {
            if (result instanceof Blob) {
              setFile(new File([result], file.name.replace('.heic', '.png')));
              setLoading(false);
            }
          })
          .catch(() => {
            setLoading(false);
            setObjectUrl(null);
          });
      } else {
        setFile(acceptedFiles[0]);
      }
    },
  });

  useEffect(() => {
    if (file) {
      setValue(name, file, { shouldValidate: true });

      try {
        setObjectUrl(URL.createObjectURL(file));

        segmentTrack('Chptr Photo Added');
      } catch {
        setObjectUrl(null);
      }
    }
  }, [file, name, setValue, segmentTrack]);

  return (
    <div className="flex flex-col space-y-2">
      <label
        htmlFor={name}
        className={cn('font-heading', { 'text-[#EF341E]': err })}
      >
        {label}
        <p className="italic">{fileTypeLabel}</p>
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange } }) => (
          <div {...getRootProps()}>
            {file && (
              <div className="flex justify-center">
                <div className="relative">
                  {objectUrl && (
                    <img
                      alt="uploaded image"
                      src={objectUrl}
                      className="-z-10 w-[210px] rounded-[12px] drop-shadow"
                    />
                  )}

                  {!objectUrl && (
                    <div className="rounded-lg bg-neutral-300 shadow-md">
                      {file.name}
                    </div>
                  )}
                  <FaX
                    className="px absolute right-[-16px] top-[-16px] z-10 h-[32px] w-[32px] cursor-pointer rounded-full bg-[#f8f8f8] p-2 drop-shadow-md"
                    onClick={() => {
                      setFile(undefined);
                      setValue(name, undefined, { shouldValidate: true });
                    }}
                  />
                </div>
              </div>
            )}

            {!file && (
              <>
                {loading && (
                  <div className="mx-auto h-36 w-48 animate-pulse rounded-xl bg-neutral-300" />
                )}

                {!loading && (
                  <div className="space-y-[40px] rounded-[10px] border-[1px] border-dashed border-[#2E5E6B] bg-[#F8F8F8] py-6 text-center">
                    <div className="space-y-4 text-[#999999]">
                      <MdOutlinePhotoLibrary className="m-auto h-[50px] w-[50px]" />
                      <p>Tap to upload photo.</p>
                    </div>
                    <input {...getInputProps({ onChange })} />
                    <Button
                      className="w-[175px]"
                      variant="stroke"
                      onClick={(e) => {
                        e.preventDefault();
                        open();
                      }}
                    >
                      Select Photo
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      />
      {err && (
        <p className="!mt-1 text-[13px] leading-[16px] text-[#EF341E]">
          {err.message as string}
        </p>
      )}
    </div>
  );
}

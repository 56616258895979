import { useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import { useAnalytics } from '../contexts/analytics';
import {
  PurchaseOrderFragment,
  PurchaseProductFragment,
  useRemoveOrderPromotionMutation,
  useUpdateOrderMutation,
} from '../gql/generated';
import { convertToCurrency } from '../lib/utils';
import Button from './ui/Button';

export default function PurchaseAddon({
  product,
  order,
}: {
  product: PurchaseProductFragment;
  order: PurchaseOrderFragment;
}) {
  const { showBoundary } = useErrorBoundary();

  const { segmentTrack } = useAnalytics();

  const [loading, setLoading] = useState<boolean>(false);

  const [updateOrder] = useUpdateOrderMutation({});

  const isAddonOrdered = order.products.find((o) => o.id === product.id);

  const selectedPackagesIds = order.products
    .filter((o) => o.type === 'PACKAGE')
    .map((o) => o.id);

  const [removeOrderPromotion] = useRemoveOrderPromotionMutation({});

  const removeOrderPromotions = async (order: PurchaseOrderFragment) => {
    for (const promotion of order.promotions) {
      await removeOrderPromotion({
        variables: {
          input: {
            code: promotion.code,
            orderId: order.id,
          },
        },
      });
    }
  };

  const addAddon = async () => {
    setLoading(true);

    try {
      await updateOrder({
        variables: {
          input: {
            id: order.id,
            productIds: [...selectedPackagesIds, product.id],
          },
        },
      });

      segmentTrack('Add-On Selected', { name: product.name });
    } catch (e) {
      return showBoundary(e);
    }

    setLoading(false);
  };

  const removeAddon = async () => {
    setLoading(true);

    try {
      await updateOrder({
        variables: {
          input: {
            id: order.id,
            productIds: [...selectedPackagesIds],
          },
        },
      });

      await removeOrderPromotions(order);
    } catch (e) {
      return showBoundary(e);
    }

    setLoading(false);
  };

  return (
    <>
      <div>
        <div className="flex justify-between font-heading text-[20px] font-medium leading-[28px]">
          <p>{product.name}</p>

          <p>{convertToCurrency(product.amount, order.currency)}</p>
        </div>

        <p className="text-[#666666]">one time payment</p>
      </div>

      <div className="flex flex-col space-y-[20px] md:flex-row md:space-x-4 md:space-y-0">
        {product.mediaUrl && (
          <img
            alt={`addon ${product.name} image`}
            src={product.mediaUrl}
            className="m-auto w-[172px]"
          />
        )}
        <p className="text-center text-sm text-[#282828] md:text-left">
          {product.description}
        </p>
      </div>

      <div className="flex justify-center">
        {isAddonOrdered ? (
          <div className="text-[17px] leading-[22px] text-[#282828]">
            {product.name} added to order!{' '}
            <button
              disabled={loading}
              type="button"
              className="underline"
              onClick={removeAddon}
            >
              Remove
            </button>
          </div>
        ) : (
          <Button
            disabled={loading}
            loading={loading}
            variant="secondary"
            onClick={addAddon}
          >
            Add {product.name}
          </Button>
        )}
      </div>
    </>
  );
}

import { AnalyticsBrowser, JSONObject } from '@segment/analytics-next';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import {
  deviceType,
  mobileModel,
  mobileVendor,
  osName,
} from 'react-device-detect';
import { matchPath } from 'react-router-dom';

import { usePlacementQuery } from '../gql/generated';
import { useUser } from './user';

interface AnalyticsInterface {
  analytics: AnalyticsBrowser | undefined;
  loaded: boolean;
  segmentTrack: (event: string, properties?: JSONObject) => void;
}

const AnalyticsContext = createContext<AnalyticsInterface>(undefined!);

const useSegmentAnalytics = () => {
  const writeKey = import.meta.env.VITE_SEGMENT_WRITE ?? '';

  // Not using useParams because provider is outside of router paths
  const match = matchPath(
    { path: '/:placementId/*' },
    window.location.pathname,
  );

  const analytics = useMemo(() => {
    if (!writeKey) return;
    return AnalyticsBrowser.load({ writeKey });
  }, [writeKey]);

  const [location, setLocation] = useState([]);
  const [locationLoading, setLocationLoading] = useState(true);
  const [user] = useUser();

  const { data: placementData, loading } = usePlacementQuery({
    variables: { placementId: match?.params.placementId ?? '' },
  });

  // Get user unique id
  const randomId = useId();
  const userUniqueId = user?.id ?? randomId;

  // Initial env state with device info
  const env = useMemo(
    () => ({
      deviceName: mobileVendor,
      deviceType: deviceType,
      model: mobileModel,
      os: osName,
      distinctId: userUniqueId,
      location: location.length > 0 ? location[0] : null,
    }),
    [location, userUniqueId],
  );

  // Get location information
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        fetch(
          `http://api.geonames.org/findNearbyPlaceNameJSON?formatted=true&lat=${position.coords.latitude}&lng=${position.coords.longitude}&username=chptr&style=full`,
        )
          .then((response) => response.json())
          .then((data) => {
            setLocation(data.geonames);
            setLocationLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLocationLoading(false);
          });
      },
      () => setLocationLoading(false),
      { maximumAge: Infinity },
    );
  }, []);

  // Segment track client
  const segmentTrack = useCallback(
    (event: string, properties?: JSONObject) => {
      if (analytics) {
        analytics.track(event, {
          ...properties,
          placement: placementData?.placement?.name,
          property: placementData?.placement?.property?.name,
          partner: placementData?.placement?.property?.partner?.name,
          env,
        });
      }
    },
    [analytics, placementData, env],
  );
  return { analytics, loaded: !loading && !locationLoading, segmentTrack };
};

export const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  return (
    <AnalyticsContext.Provider value={useSegmentAnalytics()}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAnalytics = () => {
  const analytics = useContext(AnalyticsContext);

  if (analytics === undefined) {
    throw new Error(
      'useAnalytics must be used within a AnalyticsContextProvider',
    );
  }
  return analytics;
};

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ContributeBar from '../components/ContributeBar.tsx';
import IntercomMessenger from '../components/IntercomMessenger.ts';
import Button from '../components/ui/Button.tsx';
import { CopyChtprLinkButton } from '../components/ui/CopyLinkButton.tsx';
import LoadingFlowers from '../components/ui/LoadingFlowers.tsx';
import Problem from '../components/ui/Problem.tsx';
import { useUser } from '../contexts/user.tsx';
import {
  ChptrType,
  OrderStatus,
  useGetUserOrderLazyQuery,
  usePlacementQuery,
} from '../gql/generated.tsx';
import { DEFAULT_CONFIG } from '../modules/Constants.ts';
import FromDateParts from '../modules/FromDateParts.ts';
import LifeSpan from '../modules/LifeSpan.ts';

export default function PurchaseComplete() {
  const [user] = useUser();
  const [getOrder, { data, loading: orderLoading }] =
    useGetUserOrderLazyQuery();
  const { orderId } = useParams();

  const { placementId } = useParams<{ placementId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    IntercomMessenger(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: placementData, loading: placementLoading } = usePlacementQuery({
    variables: { placementId: placementId ?? '' },
  });

  const loading = placementLoading || orderLoading;

  useEffect(() => {
    if (!orderId || !user) {
      return;
    }

    getOrder({
      variables: {
        userId: user.id,
        orderId,
      },
    });
  }, [orderId, user, getOrder]);

  if (loading) {
    return (
      <div className="mx-auto max-w-[1200px] px-4 py-[20px]">
        <div className="space-y-6">
          <LoadingFlowers />
        </div>
      </div>
    );
  }

  if (!data?.user?.order) {
    return (
      <div className="mx-auto max-w-[1200px] px-4 py-[20px]">
        <div className="space-y-6">
          <Problem
            title="Something Went Wrong"
            description={
              <>
                <p className="text-[#666666]">
                  We are sorry, but something went wrong and we were unable to
                  fetch your memorial.
                </p>

                <Button
                  variant="stroke"
                  onClick={() => navigate(`/${placementId}`)}
                  className="w-[102px]"
                >
                  Return Home
                </Button>
              </>
            }
          />
        </div>
      </div>
    );
  }

  if (data.user.order.status !== OrderStatus.Succeeded) {
    return (
      <div className="mx-auto max-w-[1200px] px-4 py-[20px]">
        <div className="space-y-6">
          <Problem
            title="Something Went Wrong"
            description={
              <p className="text-[#666666]">
                We are sorry, but something went wrong and we were unable to
                create your memorial. If you would like additional assistance,
                please reach out to{' '}
                <a href="mailto:support@chptr.com" className="underline">
                  support@chptr.com
                </a>{' '}
                and we will be happy to help.
              </p>
            }
          />
        </div>
      </div>
    );
  }

  const chptr = data.user.order.chptr;

  const bornAt = FromDateParts(chptr.bornAt);
  const passedAt = FromDateParts(chptr.passedAt);
  const lifeSpan = LifeSpan(bornAt, passedAt, chptr?.type);
  const dates = chptr.type === ChptrType.Memorial ? lifeSpan : bornAt;

  const config = placementData?.placement?.configuration;
  const chptrType = placementData?.placement?.chptrType;

  return (
    <div className="mx-auto max-w-[1200px] px-4 py-[20px]">
      <div className="space-y-6">
        <div className="flex flex-col overflow-hidden rounded-2xl border border-[#E2E2E2] pb-6">
          <div
            className="p-4 px-4"
            style={{
              background:
                'linear-gradient(96.65deg, rgba(255, 227, 137, 0.8) 1.47%, rgba(255, 180, 110, 0.776) 99.16%',
            }}
          >
            <Button
              variant="stroke"
              onClick={() => navigate(`/${placementId}`)}
              className="w-[102px]"
            >
              Home
            </Button>

            <p className="pl-2 pt-6 font-heading text-4xl leading-snug text-neutral-900">
              Purchase Complete
            </p>

            <p className="py-4 pl-2 text-sm text-neutral-500">
              {config?.chptrCreateText ??
                DEFAULT_CONFIG.CHPTR_CREATE_TEXT[
                  chptrType ?? ChptrType.Memorial
                ]}
            </p>
          </div>
          <div className="mx-4 -mt-6 mb-4">
            <div className="flex flex-col items-center rounded-2xl border-[1px] border-neutral-300 bg-white px-4 py-4 md:px-8 md:py-6">
              <p className="pb-4 font-heading text-xl md:pb-6">
                Invite the Community
              </p>
              <p className="pb-2 text-center md:pb-6">
                Share the link below to invite friends and family to contribute
                and shape a lasting digital tribute of a life well-lived.
              </p>
              <Button
                variant={'blue'}
                className="mb-4 w-3/4 sm:w-1/3"
                onClick={() => navigate(`/${placementId}/chptr/${chptr.id}`)}
              >
                {DEFAULT_CONFIG.VIEW_CHPTR_BUTTON_TEXT[chptr.type] ??
                  'View Memorial'}
              </Button>
              <CopyChtprLinkButton
                chptrId={chptr.id}
                className="w-3/4 sm:w-1/3"
              />
            </div>
          </div>
        </div>

        <div className="relative w-full">
          {(chptr.webPictureUrl || chptr.pictureUrl) && (
            <img
              className="h-[280px] w-full overflow-hidden object-cover md:h-[320px]"
              src={(chptr.webPictureUrl || chptr.pictureUrl)!}
            />
          )}

          {!chptr.webPictureUrl && !chptr.pictureUrl && (
            <div className="h-[280px] w-full animate-pulse bg-neutral-300 md:h-[320px]" />
          )}

          <div className="absolute top-0 h-[280px] w-full bg-gradient-to-t from-black to-[120%] md:h-[320px]" />

          <div className="absolute top-[25%] w-full">
            <div className="w-full text-center text-white">
              {chptr && (
                <p className="font-heading text-4xl font-medium">
                  {chptr.type === ChptrType.Wedding
                    ? `${chptr.firstName} & ${chptr.lastName}`
                    : `${chptr.firstName} ${chptr.lastName}`}
                </p>
              )}
              {!chptr && (
                <div className="mx-auto h-8 w-1/2 animate-pulse rounded-full bg-neutral-300" />
              )}
              {dates && <p className="text-lg tracking-wide">{dates}</p>}
            </div>

            <div className="px-4 pt-10">
              <ContributeBar chptr={chptr} placementId={placementId!} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { format as dateFormat } from 'date-fns-tz';

import { parseTime } from '../../lib/utils';

const TimeText = ({
  format = 'h:mm a z',
  children,
}: {
  format?: string;
  children: React.ReactNode;
}) => {
  const formatted = dateFormat(parseTime(children as string), format);

  return <span>{formatted}</span>;
};

export default TimeText;

import cn from 'classnames';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { useApplication } from '../contexts/application';
import { useUser } from '../contexts/user';
import { ChptrType, GetChptrContributionsQuery } from '../gql/generated';
import useBreakpoints from '../hooks/useBreakpoints';
import ChptrDetailsModal from './ChptrDetailsModal';
import EventModal from './EventModal';

const GRADIENT_COLORS = [
  ['from-[#672391]', 'to-[#80D1F4]'],
  ['from-[#1FDDFF]', 'to-[#FF4B1F]'],
  ['from-[#4CA1AF]', 'to-[#C4E0E5]'],
  ['from-[#4DA0B0]', 'to-[#D39D38]'],
  ['from-[#F0CB35]', 'to-[#C02425]'],
  ['from-[#403A3E]', 'to-[#BE5869]'],
  ['from-[#B24592]', 'to-[#F15F79]'],
  ['from-[#114357]', 'to-[#403A3E]'],
  ['from-[#BA5370]', 'to-[#F29492]'],
];

interface ChptrCommunityParams {
  chptr: GetChptrContributionsQuery['chptr'];
}

export const GradientCircle = ({
  initials,
  colors,
  size,
  className,
}: {
  initials: string[];
  colors: string[];
  size: number;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        `h-${size} w-${size} rounded-full bg-gradient-to-t ${colors[0]} ${colors[1]}`,
        className,
      )}
    >
      <p
        className={cn(
          'text-center font-normal text-white',
          { 'pt-2': size === 10 },
          { 'pt-1': size === 8 },
        )}
      >
        {initials[0]}
        {initials[1]}
      </p>
    </div>
  );
};

const ChptrCommunity = ({ chptr }: ChptrCommunityParams) => {
  const { isMd } = useBreakpoints();
  const [user] = useUser();
  const { getScrollPosition } = useApplication();

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState<boolean>(false);
  const [scroll, setScroll] = useState<number>(getScrollPosition());

  const colorCount = GRADIENT_COLORS.length;
  const userCount = chptr.users.length;

  const allowedUsers = isMd ? 10 : 5;
  const visibleUsers = userCount > allowedUsers ? allowedUsers : 5;

  const isOwner = user?.id === chptr.owner.user.id;

  const hasDetails = chptr?.about;
  const hasEvents = chptr.events.length > 0;

  const showDetailsButton = isOwner || (!isOwner && hasDetails);
  const showEventButton = isOwner || (!isOwner && hasEvents);

  useEffect(() => {
    setScroll(getScrollPosition());
  }, [isDetailsModalOpen, getScrollPosition]);

  return (
    <div className="grid w-full grid-cols-1 gap-4 sm:auto-cols-max">
      <div className="mb-2 flex flex-col items-center sm:col-span-2 sm:items-start">
        <div className="mb-4 flex flex-row">
          <p className="mr-2 pt-1 font-heading text-base leading-[32px] text-neutral-600 sm:text-base md:text-lg">
            Created By
          </p>
          <GradientCircle
            initials={[
              chptr.owner.user.firstName[0],
              chptr.owner.user.lastName[0],
            ]}
            colors={['from-[#71D26F]', 'to-[#51BFD7]']}
            size={8}
            className="mr-2"
          />
          <p className="pt-1 text-base text-neutral-600">
            {chptr.owner.user.firstName} {chptr.owner.user.lastName}
          </p>
        </div>
        <div className="flex space-x-2">
          {Array.from(Array(visibleUsers)).map((_, idx) => {
            const cu = chptr.users[idx];

            if (!cu) {
              return (
                <div
                  key={idx.toString()}
                  className={`h-8 w-8 rounded-full border-[1px] border-dashed border-neutral-500 bg-neutral-300`}
                />
              );
            }

            return (
              <GradientCircle
                key={cu.user.id}
                initials={[cu.user.firstName[0], cu.user.lastName[0]]}
                colors={GRADIENT_COLORS[idx % colorCount]}
                size={8}
              />
            );
          })}
          {userCount - visibleUsers > 0 && (
            <p className="pt-2 text-sm font-light text-neutral-500">
              +{userCount - visibleUsers} others
            </p>
          )}
        </div>
      </div>
      <div className="mx-auto flex place-items-center space-x-2 sm:col-span-3 sm:col-end-6 sm:!ml-auto md:space-x-4">
        {showDetailsButton && (
          <button
            className="flex h-9 w-[150px] items-center justify-center gap-1 rounded-3xl border border-neutral-200 bg-neutral-100 px-2 text-sm font-medium text-neutral-800 active:bg-neutral-300 sm:w-[120px] md:w-[200px]"
            onClick={() => setIsDetailsModalOpen(true)}
          >
            {isOwner && hasDetails
              ? 'Edit'
              : isOwner && !hasDetails
                ? 'Add'
                : !isOwner && hasDetails
                  ? 'View'
                  : null}{' '}
            {chptr?.type === ChptrType.Memorial ? 'Obituary' : 'Details'}
          </button>
        )}
        {showEventButton && (
          <button
            className="flex h-9 w-[150px] items-center justify-center gap-1 rounded-3xl border border-neutral-200 bg-neutral-100 px-2 text-sm font-medium text-neutral-800 active:bg-neutral-300 sm:w-[120px] md:w-[200px]"
            onClick={() => setIsEventModalOpen(true)}
          >
            {isOwner && hasEvents
              ? 'Edit'
              : isOwner && !hasEvents
                ? 'Add'
                : !isOwner && hasEvents
                  ? 'View'
                  : null}{' '}
            {chptr?.type === ChptrType.Memorial ? 'Service' : 'Event'}
          </button>
        )}
      </div>
      {isDetailsModalOpen && (
        <ChptrDetailsModal
          isOpen={isDetailsModalOpen}
          mode={isOwner ? 'edit' : 'view'}
          onClose={() => setIsDetailsModalOpen(false)}
          top={scroll + 100}
          chptr={chptr}
        />
      )}
      {isEventModalOpen && (
        <EventModal
          chptrId={chptr.id}
          isOpen={isEventModalOpen}
          onClose={() => setIsEventModalOpen(false)}
          top={scroll + 100}
          viewOnly={!isOwner}
        />
      )}
    </div>
  );
};

export default ChptrCommunity;

import { format } from 'date-fns';

const FromDateParts = (
  parts?: {
    year?: number | null;
    month?: number | null;
    day?: number | null;
  },
  fullMonth?: boolean,
) => {
  if (!parts) {
    return null;
  }

  const { year, month, day } = parts;

  if (year === undefined || month === undefined || day === undefined) {
    return null;
  }

  if (month && day && year) {
    return format(
      new Date(year, month - 1, day),
      `${fullMonth ? 'MMMM' : 'MMM'} d, yyyy`,
    );
  }

  if (month && year) {
    return format(
      new Date(year, month - 1),
      `${fullMonth ? 'MMMM' : 'MMM'} yyyy`,
    );
  }

  if (year) {
    return year;
  }
};

export default FromDateParts;

import { isValid, parse } from 'date-fns';
import { format } from 'date-fns-tz';

import { DEFAULT_CURRENCY } from '../modules/Constants';

export interface SplitDateFormat {
  year: number;
  month: number;
  day: number;
}

export const splitDate = (date: string): SplitDateFormat => {
  if (date === '') {
    throw new Error('splitDate: invalid input');
  }

  const [year, month, day] = date.split('-');
  return {
    year: parseInt(year, 10),
    month: parseInt(month, 10),
    day: parseInt(day, 10),
  };
};

/**
 * Parse a time string to a Date object.
 *
 * @param time - The time string to parse.
 *
 * @returns A Date object with the time set.
 *
 * @example
 * parseTime('12:00') // returns a Date object with the time set to 12:00.
 */
export const parseTime = (time: string): Date => {
  const [hours, minutes] = time.split(':');

  const date = new Date();
  date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);

  if (!isValid(date)) {
    return new Date();
  }

  return date;
};

export const convertToCurrency = (
  value: number,
  currency = DEFAULT_CURRENCY,
) => {
  // even though you can specify a different currency like 'eur' or 'gbp', the locale is still 'en-US'. Meaning that the correct symbol will be used and formatted as per the currency but follow US conventions.
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency.toUpperCase(),
    minimumFractionDigits: 2,
  }).format(value / 100);
};

export const parseDateTimeInput = (date: string, time: string): string =>
  parse(`${date} ${time}`, 'yyyy-MM-dd HH:mm', new Date()).toISOString();

export const formatDateTimeInput = (
  datetime: string,
): { date: string; time: string } | null => {
  const date = new Date(datetime);

  if (date.getTime() === 0) {
    return null;
  }

  return {
    date: format(date, 'yyyy-MM-dd'),
    time: format(date, 'HH:mm'),
  };
};

export const validateDate =
  (options?: { skipEmptyValidation?: boolean }) => (date: string) => {
    if (options?.skipEmptyValidation && date === '') {
      return true;
    }

    try {
      const result = parse(date, 'yyyy-MM-dd', new Date());

      return isValid(result);
    } catch (error) {
      return false;
    }
  };

export const validateTime =
  (options?: { skipEmptyValidation?: boolean }) => (time: string) => {
    if (options?.skipEmptyValidation && time === '') {
      return true;
    }

    try {
      // Parse the time string to a Date object. '2000-01-01' is an arbitrary date.
      const result = parse(
        `2000-01-01 ${time}`,
        'yyyy-MM-dd HH:mm',
        new Date(),
      );

      return isValid(result);
    } catch (error) {
      return false;
    }
  };

export const capitalize = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const abbreviateLastName = (firstName: string, lastName: string) => {
  if (lastName && lastName.length > 0) {
    return `${firstName} ${lastName.charAt(0)}.`;
  }

  return firstName;
};

/**
 * Check if a given date is valid.
 *
 * @param date - The date to check.
 *
 * @returns `true` if the date is valid, `false` otherwise.
 *
 * @example isValidDate(new Date()) // returns true
 */
export const isValidDate = (date: Date): boolean => {
  return !isNaN(date.getTime());
};

const AddressLink = ({ address }: { address: string }) => {
  return (
    <a
      href={`https://www.google.com/maps/dir/?api=1&destination=${address}`}
      target="_blank"
      className="underline"
    >
      {address}
    </a>
  );
};

export default AddressLink;

import { Combobox } from '@headlessui/react';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import cn from 'classnames';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import useGoogleMaps from '../../hooks/useGoogleMaps';

export const PlaceInput = ({
  onChange,
  value,
  className,
  placeholder,
  disabled,
}: {
  onChange: (place: string | null) => void;
  value: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}) => {
  const {
    fetchPlace,
    fetchPredictions,
    isAPIReady,
    fitMapBounds,
    resetSession,
  } = useGoogleMaps();

  const [query, setQuery] = useState<string>('');

  useEffect(() => {
    if (value) {
      fetchPlace(value).then((place) => {
        if (!place?.place_id || !place?.formatted_address) return;
        setSelectedPlace(place);
        setQuery(place.formatted_address);
        fitMapBounds(place);
      });
      return;
    }

    setSelectedPlace(null);
    setQuery('');
  }, [value, isAPIReady, fetchPlace, fitMapBounds]);

  const [predictionResults, setPredictionResults] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([]);

  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  const handlePredictionQuery = useCallback(
    async (event: FormEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement)?.value;
      setQuery(value);

      const predictions = await fetchPredictions(value);
      setPredictionResults(predictions);
    },
    [fetchPredictions],
  );

  const handleChange = useCallback(
    async (value: google.maps.places.AutocompletePrediction) => {
      if (!value.place_id) return;

      const place = await fetchPlace(value.place_id);

      // Reset session token to group autocomplete and place selection results into one request.
      // See: https://developers.google.com/maps/documentation/places/web-service/session-tokens
      resetSession();
      setSelectedPlace(place);
      setQuery(place?.formatted_address || '');
      fitMapBounds(place);
      onChange(place?.place_id || null);
    },
    [onChange, fetchPlace, fitMapBounds, resetSession],
  );

  const handleBlur = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement)?.value;

      resetSession();

      if (!value) {
        setQuery('');
        onChange(null);
        return;
      }

      setQuery(selectedPlace?.formatted_address || '');
    },
    [onChange, resetSession, selectedPlace],
  );

  return (
    <>
      <Combobox onChange={handleChange} disabled={disabled}>
        <Combobox.Input
          value={query}
          className={cn('w-full rounded-md py-1.5 text-gray-900', className)}
          placeholder={placeholder}
          onChange={handlePredictionQuery}
          displayValue={(place: google.maps.places.AutocompletePrediction) => {
            return place.description;
          }}
          onBlur={handleBlur}
        />
        {predictionResults.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {predictionResults.map((result) => (
              <Combobox.Option
                key={result.place_id}
                value={result}
                className={({ active, selected }) =>
                  cn(
                    'relative cursor-default py-2 pl-3 pr-9',
                    selected || active
                      ? 'bg-indigo-600 text-white'
                      : 'text-gray-900',
                  )
                }
              >
                {result.description}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
      <Map
        style={{
          width: '100%',
          height: '150px',
          marginTop: '8px',
          display: selectedPlace?.geometry?.location?.lat() ? 'block' : 'none',
        }}
        defaultZoom={3}
        defaultCenter={{ lat: 0, lng: 0 }}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
      >
        {selectedPlace?.geometry?.location?.lat() && (
          <Marker
            position={{
              lat: selectedPlace.geometry.location.lat(),
              lng: selectedPlace.geometry.location.lng(),
            }}
          />
        )}
      </Map>
    </>
  );
};

const FormPlaceInputWrapper = ({
  name,
  placeholder,
  inputClassName,
  disabled,
}: {
  name: string;
  placeholder?: string;
  inputClassName?: string;
  disabled?: boolean;
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const err = name in errors ? errors[name] : undefined;

  return (
    <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY}>
      <div>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <PlaceInput
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              className={cn(
                'h-[48px] rounded-[10px] border-[1px] border-[#D9D9D9] bg-[#F8F8F8] p-2',
                { 'border-[#EF341E]': err },
                inputClassName,
              )}
              disabled={disabled}
            />
          )}
        />

        {err && (
          <p className="!mt-1 text-[13px] leading-[16px] text-[#EF341E]">
            {err.message as string}
          </p>
        )}
      </div>
    </APIProvider>
  );
};

export default FormPlaceInputWrapper;

import classNames from 'classnames';
import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  useCallback,
  useRef,
  useState,
} from 'react';
import { Blurhash } from 'react-blurhash';
import { useTimeout } from 'usehooks-ts';

type RemoteImageProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  containerClassName?: string;
  hash: string;
};

const RemoteImage = ({
  containerClassName,
  hash,
  className,
  ...rest
}: RemoteImageProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [loaded, setLoaded] = useState(false);
  const [containerHeight, setContainerHeight] = useState<number | null>(null);

  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  useTimeout(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight);
    }
  }, 50);

  return (
    <div
      ref={containerRef}
      className={classNames(
        'relative w-full overflow-hidden rounded-xl',
        containerClassName,
      )}
    >
      <div className="absolute bottom-0 left-0 right-0 top-0">
        <Blurhash
          width="100%"
          height={containerHeight || '100%'}
          hash={hash}
          className={classNames({
            'opacity-0': loaded,
          })}
        />
      </div>

      <img
        {...rest}
        className={classNames(
          'relative w-full object-cover transition-all duration-1000 ease-in-out',
          className,
          {
            'opacity-0': !loaded,
            'opacity-100': loaded,
          },
        )}
        onLoad={onLoad}
        onError={console.log}
      />
    </div>
  );
};

export default RemoteImage;

import { zodResolver } from '@hookform/resolvers/zod';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import cn from 'classnames';
import { compareAsc } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormState,
} from 'react-hook-form';
import {
  BsCalendar4Event,
  BsCameraVideo,
  BsClock,
  BsGeoAlt,
} from 'react-icons/bs';
import { IoAddCircleOutline, IoClose } from 'react-icons/io5';
import { SlLocationPin } from 'react-icons/sl';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { useApplication } from '../contexts/application';
import {
  ChptrType,
  GetChptrContributionsQuery,
  GetChptrEventsQuery,
  useCreateEventMutation,
  useGetChptrEventsQuery,
  useUpdateEventMutation,
} from '../gql/generated';
import useBreakpoints from '../hooks/useBreakpoints';
import useGoogleMaps from '../hooks/useGoogleMaps';
import {
  formatDateTimeInput,
  parseDateTimeInput,
  validateDate,
  validateTime,
} from '../lib/utils';
import FromDateParts from '../modules/FromDateParts';
import LifeSpan from '../modules/LifeSpan';
import AffiliateInput from './AffiliateInput';
import ChptrDetailsModal from './ChptrDetailsModal';
import EventPreviewDetails from './EventPreviewDetails';
import AddressText from './ui/AddressText';
import Button from './ui/Button';
import ChptrPlaceholderProfileImage from './ui/ChptrPlaceholderProfileImage';
import DateText from './ui/DateText';
import FormInput from './ui/FormInput';
import FormPlaceInput from './ui/FormPlaceInput';
import FormTextarea from './ui/FormTextarea';
import Modal from './ui/Modal';
import TimeText from './ui/TimeText';

const EventSchema = z
  .object({
    location: z.string(),
    locationPlaceId: z.string().nullable().optional(),
    link: z
      .string()
      .regex(/^(http|https):\/\//, { message: 'Please include https://' })
      .url({ message: 'Invalid Link' })
      .or(z.literal('')),
    startDate: z
      .string()
      .min(1, { message: 'Required' })
      .refine(validateDate(), { message: 'Invalid Date' }),
    startTime: z
      .string()
      .min(1, { message: 'Required' })
      .refine(validateTime(), { message: 'Invalid Time' }),
    endTime: z.string().refine(validateTime({ skipEmptyValidation: true }), {
      message: 'Invalid Time',
    }),
    visitLocation: z.string(),
    visitLocationPlaceId: z.string().nullable().optional(),
    visitLink: z
      .string()
      .regex(/^(http|https):\/\//, { message: 'Please include https://' })
      .url({ message: 'Invalid Link' })
      .or(z.literal('')),
    visitStartDate: z
      .string()
      .refine(validateDate({ skipEmptyValidation: true }), {
        message: 'Invalid Date',
      }),
    visitStartTime: z
      .string()
      .refine(validateTime({ skipEmptyValidation: true }), {
        message: 'Invalid Time',
      }),
    visitEndTime: z
      .string()
      .refine(validateTime({ skipEmptyValidation: true }), {
        message: 'Invalid Time',
      }),
    propertyId: z.string().min(1, { message: 'Required' }).nullable(),
    notes: z.string(),
  })
  .superRefine((data, ctx) => {
    const now = new Date();

    if (data.startDate && data.startTime) {
      const startAt = parseDateTimeInput(data.startDate, data.startTime);
      if (compareAsc(now, startAt) >= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Event date can only be in the future',
          path: ['startDate'],
        });
      }
    }

    if (data.visitStartDate && data.visitStartTime) {
      const visitStartAt = parseDateTimeInput(
        data.visitStartDate,
        data.visitStartTime,
      );
      if (compareAsc(now, visitStartAt) >= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Visitation date can only be in the future',
          path: ['visitStartDate'],
        });
      }
    }
  });

export type EventSchemaType = z.infer<typeof EventSchema>;

const VisitationForm = ({
  viewOnly,
  chptrType,
}: {
  viewOnly: boolean;
  chptrType: ChptrType;
}) => {
  const handleDateTimeClick = (
    dateInputRef: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    if (dateInputRef.currentTarget) {
      dateInputRef.currentTarget.focus();
      dateInputRef.currentTarget.showPicker(); // For modern browsers that support the showPicker() method
    }
  };

  const { defaultValues } = useFormState<EventSchemaType>();

  return (
    <>
      {!viewOnly ? (
        <>
          <p className="font-sans text-[16px] font-normal text-[#666666]">
            {chptrType === ChptrType.Memorial &&
              'Please provide details for the community about any visitation hours.'}
            {chptrType === ChptrType.Wedding &&
              'Please provide details for the community about the wedding reception hours.'}
            {chptrType === ChptrType.Celebration &&
              'Please provide details for the community about any visitation hours.'}
          </p>
          <div className="relative w-full">
            <BsCalendar4Event
              className="absolute left-2 top-12"
              strokeWidth={0.5}
              size={16}
            />
            <FormInput
              onClick={(e) => {
                handleDateTimeClick(e);
              }}
              type="date"
              name="visitStartDate"
              label="Date"
              inputClassName="hide-calendar-icon pl-8 w-full"
            />
          </div>
          <div className="flex space-x-1">
            <div className="relative flex-1">
              <BsClock
                className="absolute left-2 top-12"
                strokeWidth={0.5}
                size={16}
              />
              <FormInput
                label="Start"
                onClick={(e) => {
                  handleDateTimeClick(e);
                }}
                type="time"
                name="visitStartTime"
                inputClassName="hide-calendar-icon pl-8  w-full"
              />
            </div>
            <div className="relative flex-1">
              <BsClock
                className="absolute left-2 top-12"
                strokeWidth={0.5}
                size={16}
              />
              <FormInput
                label="End"
                onClick={(e) => {
                  handleDateTimeClick(e);
                }}
                type="time"
                name="visitEndTime"
                inputClassName="hide-calendar-icon pl-8  w-full"
              />
            </div>
          </div>
          <div>
            <div className="relative flex w-full items-center">
              <SlLocationPin size={18} className="inline" />
              <span className="ml-1 font-heading">Location</span>
            </div>
            <div className="relative w-full space-y-2">
              <FormInput
                name="visitLocation"
                placeholder={`Name (i.e. "Martha's house")`}
                inputClassName="mt-1"
              />
              <FormPlaceInput
                name="visitLocationPlaceId"
                placeholder="Address"
                inputClassName="mt-1"
              />
            </div>
          </div>
          <div className="relative w-full">
            <BsCameraVideo className="absolute left-2 top-4" size={18} />
            <FormInput
              name="visitLink"
              placeholder="Virtual Service Link"
              inputClassName="pl-8"
            />
          </div>
        </>
      ) : (
        <div className="space-y-3">
          {defaultValues?.visitStartDate && (
            <div className="flex items-center space-x-2">
              <BsCalendar4Event strokeWidth={0.5} size={16} />
              <DateText>{defaultValues.visitStartDate}</DateText>
            </div>
          )}
          {defaultValues?.visitStartTime && (
            <div className="flex items-center space-x-2">
              <BsClock strokeWidth={0.5} size={16} />
              <TimeText format="h:mm a">
                {defaultValues.visitStartTime}
              </TimeText>
              {defaultValues?.visitEndTime && (
                <>
                  <span>-</span>
                  <TimeText format="h:mm a">
                    {defaultValues.visitEndTime}
                  </TimeText>
                </>
              )}
              <TimeText format="z">{defaultValues.visitEndTime}</TimeText>
            </div>
          )}
          {(defaultValues?.visitLocation ||
            defaultValues?.visitLocationPlaceId) && (
            <div className="flex items-start space-x-2">
              <div className="relative mt-1">
                <BsGeoAlt strokeWidth={0.5} size={16} />
              </div>
              <div className="w-full">
                <span>{defaultValues?.visitLocation}</span>
                <AddressText>{defaultValues?.visitLocationPlaceId}</AddressText>
              </div>
            </div>
          )}
          {defaultValues?.visitLink && (
            <div className="flex items-center space-x-2">
              <BsCameraVideo strokeWidth={0.5} size={16} />
              <a
                href={defaultValues?.visitLink}
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Virtual Service Link
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const ServiceForm = ({
  viewOnly,
  chptrType,
}: {
  viewOnly: boolean;
  chptrType: ChptrType;
}) => {
  const handleDateTimeClick = (
    dateInputRef: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    if (dateInputRef.currentTarget) {
      dateInputRef.currentTarget.focus();
      dateInputRef.currentTarget.showPicker(); // For modern browsers that support the showPicker() method
    }
  };
  const { defaultValues } = useFormState<EventSchemaType>();

  return (
    <div className="w-full space-y-4 rounded-xl border border-[#D9D9D9] bg-white p-4">
      <p className="font-heading text-[18px] text-[#3F3F3F]">
        {chptrType === ChptrType.Wedding
          ? 'Ceremony Details'
          : 'Service Details'}
      </p>
      {!viewOnly ? (
        <>
          <p className="font-sans text-[16px] font-normal text-[#666666]">
            {chptrType === ChptrType.Wedding
              ? 'Please provide details for the community about the wedding ceremony.'
              : 'Please provide details for the community about the upcoming service.'}
          </p>
          <div className="relative w-full">
            <BsCalendar4Event
              className="absolute left-2 top-12"
              strokeWidth={0.5}
              size={16}
            />
            <FormInput
              onClick={(e) => {
                handleDateTimeClick(e);
              }}
              type="date"
              name="startDate"
              label="Date"
              inputClassName="hide-calendar-icon pl-8 w-full"
              required
            />
          </div>
          <div className="flex space-x-1">
            <div className="relative flex-1">
              <BsClock
                className="absolute left-2 top-12"
                strokeWidth={0.5}
                size={16}
              />
              <FormInput
                label="Start"
                onClick={(e) => {
                  handleDateTimeClick(e);
                }}
                type="time"
                name="startTime"
                inputClassName="hide-calendar-icon pl-8  w-full"
                required
              />
            </div>
            <div className="relative flex-1">
              <BsClock
                className="absolute left-2 top-12"
                strokeWidth={0.5}
                size={16}
              />
              <FormInput
                label="End"
                onClick={(e) => {
                  handleDateTimeClick(e);
                }}
                type="time"
                name="endTime"
                inputClassName="hide-calendar-icon pl-8  w-full"
              />
            </div>
          </div>
          <div>
            <div className="relative flex w-full items-center">
              <SlLocationPin size={18} className="inline" />
              <span className="ml-1 font-heading">Location</span>
            </div>
            <div className="relative w-full space-y-2">
              <FormInput
                name="location"
                placeholder={`Name (i.e. "Martha's house")`}
                inputClassName="mt-1"
              />
              <FormPlaceInput
                name="locationPlaceId"
                placeholder="Address"
                inputClassName="mt-1"
              />
            </div>
          </div>
          <div className="relative w-full">
            <BsCameraVideo className="absolute left-2 top-4" size={18} />
            <FormInput
              name="link"
              placeholder="Virtual Service Link"
              inputClassName="pl-8"
            />
          </div>
        </>
      ) : (
        <div className="space-y-3">
          {defaultValues?.startDate && (
            <div className="flex items-center space-x-2">
              <BsCalendar4Event strokeWidth={0.5} size={16} />
              <DateText>{defaultValues.startDate}</DateText>
            </div>
          )}
          {defaultValues?.startTime && (
            <div className="flex items-center space-x-2">
              <BsClock strokeWidth={0.5} size={16} />
              <TimeText format="h:mm a">{defaultValues.startTime}</TimeText>
              {defaultValues?.endTime && (
                <>
                  <span>-</span>
                  <TimeText format="h:mm a">{defaultValues.endTime}</TimeText>
                </>
              )}
              <TimeText format="z">{defaultValues.startTime}</TimeText>
            </div>
          )}
          {(defaultValues?.location || defaultValues?.locationPlaceId) && (
            <div className="flex items-start space-x-2">
              <div className="relative mt-1">
                <BsGeoAlt strokeWidth={0.5} size={16} />
              </div>
              <div className="w-full">
                <span>{defaultValues?.location}</span>
                <AddressText>{defaultValues?.locationPlaceId}</AddressText>
              </div>
            </div>
          )}
          {defaultValues?.link && (
            <div className="flex items-center space-x-2">
              <BsCameraVideo strokeWidth={0.5} size={16} />
              <a
                href={defaultValues?.link}
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Virtual Service Link
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

type InputEventModalProps = {
  isOpen: boolean;
  onClose: () => void;
  top?: number;
  viewOnly: boolean;
  showNav?: boolean;
  chptr: GetChptrEventsQuery['chptr'];
  refetch: () => void;
};

const InputEventModal = ({
  isOpen,
  onClose,
  top,
  viewOnly,
  showNav,
  chptr,
  refetch,
}: InputEventModalProps) => {
  const { active } = useBreakpoints();

  const navigate = useNavigate();

  const { placementId } = useParams();

  const { fetchPlace } = useGoogleMaps();

  const [createEvent, { loading: createLoading }] = useCreateEventMutation({});
  const [updateEvent, { loading: updateLoading }] = useUpdateEventMutation({});

  const [visitExpanded, setVisitExpanded] = useState<boolean>(true);

  const event = chptr?.events?.[0];

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  // Logic for preset affiliate value:
  // If there is an event take the property registered there (Id or other)
  // If there is no event take the proeprty registered (if any) on chptr creation (Id or other)
  const defaultAffiliate = event
    ? event.property !== null
      ? event.property
      : {
          id: 'other',
          name: 'Other',
          imageUrl: undefined,
        }
    : chptr.reps.length > 0
      ? chptr.property
        ? chptr.property
        : {
            id: 'other',
            name: 'Other',
            imageUrl: undefined,
          }
      : undefined;

  const methods = useForm<EventSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(EventSchema),
    defaultValues: {
      location: event?.location || '',
      locationPlaceId: event?.locationPlaceId || null,
      link: event?.link || '',
      startDate: event?.startAt ? formatDateTimeInput(event.startAt)?.date : '',
      startTime: event?.startAt ? formatDateTimeInput(event.startAt)?.time : '',
      endTime: event?.endAt ? formatDateTimeInput(event.endAt)?.time : '',
      visitStartDate: event?.visitStartAt
        ? formatDateTimeInput(event.visitStartAt)?.date
        : '',
      visitStartTime: event?.visitStartAt
        ? formatDateTimeInput(event.visitStartAt)?.time
        : '',
      visitEndTime: event?.visitEndAt
        ? formatDateTimeInput(event.visitEndAt)?.time
        : '',
      visitLocation: event?.visitLocation || '',
      visitLocationPlaceId: event?.visitLocationPlaceId || null,
      visitLink: event?.visitLink || '',
      propertyId: event?.property?.id || defaultAffiliate?.id || null,
      notes: event?.notes || '',
    },
  });
  const formValues = methods.watch();

  const isSmall = active === 'sm' || active === 'xs';
  const loading = createLoading || updateLoading;
  const hideVisitation = !event?.visitStartAt && viewOnly;
  const hasHeaderImage = chptr?.pictureUrl || chptr?.webPictureUrl;
  const bornAt = FromDateParts(chptr?.bornAt, true);
  const passedAt = FromDateParts(chptr?.passedAt, true);
  const lifeSpan = LifeSpan(bornAt, passedAt, chptr?.type);
  const dates = chptr?.type === ChptrType.Memorial ? lifeSpan : bornAt;

  const { getScrollPosition } = useApplication();

  const [scroll, setScroll] = useState<number>(getScrollPosition());

  useEffect(() => {
    setScroll(getScrollPosition());
  }, [isDetailsModalOpen, getScrollPosition]);

  const onSubmit: SubmitHandler<EventSchemaType> = async (formData) => {
    const {
      startDate,
      startTime,
      endTime,
      visitStartDate,
      visitStartTime,
      visitEndTime,
      visitLocation,
      visitLink,
      propertyId,
      ...data
    } = formData;

    const startAt = parseDateTimeInput(startDate, startTime);
    const visitStartAt =
      visitExpanded && visitStartDate && visitStartTime
        ? parseDateTimeInput(visitStartDate, visitStartTime)
        : null;

    // NOTE: end times use the same date as start times
    const endAt = endTime ? parseDateTimeInput(startDate, endTime) : null;
    const visitEndAt =
      visitExpanded && visitStartDate && visitEndTime
        ? parseDateTimeInput(visitStartDate, visitEndTime)
        : null;

    let locationAddress = undefined;
    if (event?.locationPlaceId !== formData?.locationPlaceId) {
      if (formData.locationPlaceId) {
        const place = await fetchPlace(formData.locationPlaceId);
        locationAddress = {
          formatted_address: place?.formatted_address,
          address_components: place?.address_components,
        };
      } else {
        locationAddress = null;
      }
    }

    let visitLocationAddress = undefined;
    if (event?.visitLocationPlaceId !== formData?.visitLocationPlaceId) {
      if (formData.visitLocationPlaceId) {
        const place = await fetchPlace(formData.visitLocationPlaceId);
        visitLocationAddress = {
          formatted_address: place?.formatted_address,
          address_components: place?.address_components,
        };
      } else {
        visitLocationAddress = null;
      }
    }

    if (!event?.id) {
      try {
        await createEvent({
          variables: {
            input: {
              chptrId: chptr.id,
              startAt,
              endAt,
              visitStartAt,
              visitEndAt,
              visitLocation: visitExpanded ? visitLocation : null,
              visitLink: visitExpanded ? visitLink : null,
              propertyId: propertyId === 'other' ? null : propertyId,
              locationAddress,
              visitLocationAddress,
              ...data,
            },
          },
        });
        refetch();
        onClose();
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        await updateEvent({
          variables: {
            input: {
              id: event?.id,
              startAt,
              endAt,
              visitStartAt,
              visitEndAt,
              visitLocation: visitExpanded ? visitLocation : null,
              visitLink: visitExpanded ? visitLink : null,
              propertyId: propertyId === 'other' ? null : propertyId,
              locationAddress,
              visitLocationAddress,
              ...data,
            },
          },
        });
        refetch();
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      containerClassName={cn('w-full max-w-[800px] sm:mx-[5%] !p-0')}
      style={{
        top: top || '10%',
      }}
    >
      <div className="relative">
        <div className="absolute right-2 top-2 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-white/40">
          <IoClose size={26} className="cursor-pointer" onClick={onClose} />
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-5">
              {!isSmall && (
                <div className="col-span-2 space-y-4 !bg-[#2E5E6B]/[.1] p-4">
                  {!hideVisitation && (
                    <div className="space-y-4 rounded-xl border border-[#D9D9D9] bg-white p-4">
                      <div className="flex justify-between">
                        <p className="font-heading text-[18px] text-[#3F3F3F]">
                          {chptr.type === ChptrType.Wedding
                            ? 'Reception Hours'
                            : 'Visitation Hours'}
                        </p>
                        {visitExpanded && !viewOnly && (
                          <IoClose
                            size={26}
                            className="cursor-pointer"
                            onClick={() => setVisitExpanded(false)}
                          />
                        )}
                        {!visitExpanded && !viewOnly && (
                          <IoAddCircleOutline
                            size={26}
                            className="cursor-pointer"
                            onClick={() => setVisitExpanded(true)}
                          />
                        )}
                      </div>

                      {visitExpanded && (
                        <VisitationForm
                          viewOnly={viewOnly}
                          chptrType={chptr.type}
                        />
                      )}
                    </div>
                  )}
                  <ServiceForm viewOnly={viewOnly} chptrType={chptr.type} />
                  {!viewOnly && (
                    <p className="text-[#666666]">
                      Select the affiliated{' '}
                      {chptr.type === ChptrType.Memorial
                        ? 'funeral home'
                        : 'partner'}
                      .
                    </p>
                  )}
                  <AffiliateInput
                    defaultValue={defaultAffiliate}
                    disabled={viewOnly}
                    name="propertyId"
                    value={formValues.propertyId ?? undefined}
                    error={methods.getFieldState('propertyId').error?.message}
                  ></AffiliateInput>
                </div>
              )}
              <div className="flex flex-col justify-between rounded-2xl bg-white md:col-span-3 md:rounded-r-2xl">
                <div>
                  <div className="relative h-[280px] w-full overflow-hidden rounded-t-2xl md:rounded-t-none md:rounded-tr-2xl">
                    {hasHeaderImage && (
                      <img
                        className="h-full w-full bg-neutral-300 object-cover"
                        src={
                          chptr.webPictureUrl
                            ? chptr.webPictureUrl
                            : chptr.pictureUrl!
                        }
                        alt="Profile Banner Image"
                      />
                    )}

                    {!hasHeaderImage && (
                      <div className="h-full w-full">
                        <ChptrPlaceholderProfileImage
                          className="h-full px-24"
                          iconWrapperClassName="scale-75"
                        />
                      </div>
                    )}
                  </div>
                  {isSmall && (
                    <div className="space-y-4 !bg-[#2E5E6B]/[.1] p-4">
                      {!hideVisitation && (
                        <div className="w-full space-y-4 rounded-xl border border-[#D9D9D9] bg-white p-4">
                          <div className="flex justify-between">
                            <p className="font-heading text-[18px] text-[#3F3F3F]">
                              {chptr.type === ChptrType.Wedding
                                ? 'Reception Hours'
                                : 'Visitation Hours'}
                            </p>
                            {visitExpanded && !viewOnly && (
                              <IoClose
                                size={26}
                                className="cursor-pointer"
                                onClick={() => setVisitExpanded(false)}
                              />
                            )}
                            {!visitExpanded && !viewOnly && (
                              <IoAddCircleOutline
                                size={26}
                                className="cursor-pointer"
                                onClick={() => setVisitExpanded(true)}
                              />
                            )}
                          </div>
                          {visitExpanded && (
                            <VisitationForm
                              viewOnly={viewOnly}
                              chptrType={chptr.type}
                            />
                          )}
                        </div>
                      )}
                      <ServiceForm viewOnly={viewOnly} chptrType={chptr.type} />
                      {!viewOnly && (
                        <p className="text-[#666666]">
                          Select the affiliated{' '}
                          {chptr.type === ChptrType.Memorial
                            ? 'funeral home'
                            : 'partner'}
                          .
                        </p>
                      )}
                      <AffiliateInput
                        defaultValue={defaultAffiliate}
                        disabled={viewOnly}
                        name="propertyId"
                        value={formValues.propertyId ?? undefined}
                        error={
                          methods.getFieldState('propertyId').error?.message
                        }
                      ></AffiliateInput>
                    </div>
                  )}
                  {chptr && (
                    <EventPreviewDetails
                      firstName={chptr.firstName}
                      lastName={chptr.lastName}
                      chptrType={chptr.type}
                      dates={dates}
                      location={formValues.location}
                      locationPlaceId={formValues.locationPlaceId ?? ''}
                      chptrLocation={chptr.location ?? undefined}
                      startDate={formValues.startDate}
                      startTime={formValues.startTime}
                      endTime={formValues.endTime}
                      visitStartDate={formValues.visitStartDate}
                      visitStartTime={formValues.visitStartTime}
                      visitEndTime={formValues.visitEndTime}
                      visitLocation={formValues.visitLocation}
                      visitLocationPlaceId={
                        formValues.visitLocationPlaceId ?? ''
                      }
                      notes={
                        viewOnly && formValues.notes
                          ? formValues.notes
                          : undefined
                      }
                      viewOnly={viewOnly}
                    />
                  )}
                  {!viewOnly && (
                    <div className="mb-8 px-4">
                      <FormTextarea
                        name="notes"
                        placeholder="Notes and additional information you’d like guests to know."
                        rows={4}
                      />
                    </div>
                  )}
                </div>
                {!viewOnly && (
                  <div className="flex justify-center py-8">
                    <Button
                      type="submit"
                      disabled={loading}
                      loading={loading}
                      variant="primary"
                      className="!mx-4 grow !px-6 font-sans text-[17px] sm:!px-10"
                    >
                      {event ? 'Update Event' : 'Publish Event'}
                    </Button>
                  </div>
                )}
                {viewOnly && showNav && (
                  <div className="flex justify-center gap-4 pb-8">
                    {chptr?.about && (
                      <Button
                        variant="stroke"
                        onClick={() => setIsDetailsModalOpen(true)}
                        className="!border !border-[#E2E2E2] bg-[#FAFAFA] !px-6 font-sans sm:!px-10"
                      >
                        {chptr.type === ChptrType.Wedding
                          ? 'Wedding Details'
                          : 'View Obituary'}
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      onClick={() => {
                        window.scrollTo(0, 0); // Scroll to top so chptr page shows from the top
                        chptr
                          ? navigate(`/${placementId}/chptr/${chptr.id}`)
                          : null;
                      }}
                      className="!px-6 font-sans sm:!px-10"
                    >
                      See Memories
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
        {isDetailsModalOpen && (
          <ChptrDetailsModal
            isOpen={isDetailsModalOpen}
            mode={'view'}
            onClose={() => setIsDetailsModalOpen(false)}
            top={scroll + 100}
            chptr={chptr as GetChptrContributionsQuery['chptr']}
          />
        )}
      </div>
    </Modal>
  );
};

const InputEventModalWrapper = (
  props: Omit<InputEventModalProps, 'chptr' | 'refetch'> & { chptrId: string },
) => {
  const { chptrId, ...rest } = props;

  const { data, refetch } = useGetChptrEventsQuery({ variables: { chptrId } });

  return (
    data?.chptr && (
      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY}>
        <>
          <InputEventModal chptr={data.chptr} refetch={refetch} {...rest} />
          <Map
            style={{
              width: '100%',
              height: '150px',
              marginTop: '8px',
              display: 'none',
            }}
            defaultZoom={3}
            defaultCenter={{ lat: 0, lng: 0 }}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
          />
        </>
      </APIProvider>
    )
  );
};

export default InputEventModalWrapper;

import Problem from '../components/ui/Problem';

const NotFound = () => (
  <div className="flex h-[50vw]">
    <Problem
      title="Not Found"
      description={<p>Sorry that page doesn't exist.</p>}
    />
  </div>
);

export default NotFound;

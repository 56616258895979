import { ReactNode } from 'react';

import problemUrl from '../../assets/images/problem.png';

export default function Problem({
  title,
  description,
}: {
  title: string;
  description: ReactNode;
}) {
  return (
    <div className="m-auto text-[#282828]">
      <div className="mx-auto flex w-[40vw] justify-center">
        <img alt="problem" src={problemUrl} />
      </div>
      <div className="space-y-4">
        <div className="text-center font-heading text-[28px] font-medium md:text-[34px]">
          {title}
        </div>
        <div className="m-auto max-w-[661px] text-center">{description}</div>
      </div>
    </div>
  );
}

import Masonry from '@mui/lab/Masonry';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { BsArrowLeft } from 'react-icons/bs';
import { LuListFilter } from 'react-icons/lu';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntersectionObserver } from 'usehooks-ts';

import ChptrCommunity from '../components/ChptrCommunity';
import ContributeBar from '../components/ContributeBar';
import Contribution, { FeaturedContribution } from '../components/Contribution';
import IntercomMessenger from '../components/IntercomMessenger';
import Button from '../components/ui/Button';
import ChptrPlaceholderProfileImage from '../components/ui/ChptrPlaceholderProfileImage';
import { CopyChtprLinkButton } from '../components/ui/CopyLinkButton';
import DropDown from '../components/ui/DropDown';
import { useAnalytics } from '../contexts/analytics';
import { useUser } from '../contexts/user';
import {
  ChptrType,
  ContributionMediaType,
  ContributionOrderByInput,
  GetChptrContributionsQuery,
  SortOrder,
  useGetChptrContributionsLazyQuery,
} from '../gql/generated';
import {
  DEFAULT_FEATURED_CONTRIBUTION,
  DEFAULT_PAGE_SIZE,
} from '../modules/Constants';
import FromDateParts from '../modules/FromDateParts';
import LifeSpan from '../modules/LifeSpan';

//const VIDEO_THRESHOLD_PERCENTAGE = 40;

const ChptrMain = () => {
  const navigate = useNavigate();

  const { placementId, id } = useParams();
  const { loaded: analyticsLoaded, segmentTrack } = useAnalytics();
  const [user] = useUser();

  useEffect(() => {
    IntercomMessenger(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showVideo] = useState<boolean>(true);
  const [filter, setFilter] = useState<
    | ContributionMediaType.Image
    | ContributionMediaType.Video
    | ContributionMediaType.Text
    | null
  >(null);
  const [orderLabel, setOrderLabel] = useState<
    'Newest' | 'Oldest' | 'By Contributor' | null
  >(null);
  const [order, setOrder] = useState<ContributionOrderByInput | null>(null);

  const [loadChptr, { data, fetchMore, refetch }] =
    useGetChptrContributionsLazyQuery();

  useEffect(() => {
    if (id) {
      loadChptr({
        variables: { chptrId: id, contributionsFirst: DEFAULT_PAGE_SIZE },
      });
    }
  }, [id, loadChptr]);

  useEffect(() => {
    if (id && analyticsLoaded) {
      segmentTrack('Chptr Clicked', {
        id: id as string,
      });
    }
  }, [analyticsLoaded, id, segmentTrack]);

  const { ref } = useIntersectionObserver({
    threshold: 0.1,
    onChange: (isIntersecting) => {
      if (isIntersecting && data?.chptr.contributions.pageInfo.hasNextPage) {
        fetchMore({
          variables: {
            chptrId: id,
            contributionsAfter: data?.chptr.contributions.pageInfo.endCursor,
          },
        });
      }
    },
  });

  const chptr = useMemo(() => data?.chptr, [data]);
  const bornAt = FromDateParts(chptr?.bornAt);
  const passedAt = FromDateParts(chptr?.passedAt);
  const lifeSpan = LifeSpan(bornAt, passedAt, chptr?.type);
  const dates = data?.chptr.type === ChptrType.Memorial ? lifeSpan : bornAt;

  useEffect(() => {
    (window.top || window.parent).postMessage(['scrollToTop'], '*');
  }, []);

  const featuredContributions =
    chptr?.featuredContributions.length === 0
      ? [
          DEFAULT_FEATURED_CONTRIBUTION as GetChptrContributionsQuery['chptr']['featuredContributions'][number],
        ]
      : chptr?.featuredContributions;

  const hasHeaderImage = chptr && (chptr.pictureUrl || chptr.webPictureUrl);

  const onFilterChange = (filterBy: typeof filter) => {
    if (filter === filterBy) {
      filterBy = null;
    }

    setFilter(filterBy);
    refetch({
      chptrId: id,
      contributionsFirst: DEFAULT_PAGE_SIZE,
      mediaType: filterBy,
      orderBy: order,
    });
  };

  const onOrderChange = (orderBy: typeof orderLabel) => {
    setOrderLabel(orderBy);

    let orderInput = null;
    switch (orderBy) {
      case 'Newest':
        orderInput = { createdAt: SortOrder.Desc };
        break;
      case 'Oldest':
        orderInput = { createdAt: SortOrder.Asc };
        break;
      case 'By Contributor':
        orderInput = { userId: SortOrder.Asc };
        break;
    }
    setOrder(orderInput);

    refetch({
      chptrId: id,
      contributionsFirst: DEFAULT_PAGE_SIZE,
      mediaType: filter,
      orderBy: orderInput,
    });
  };

  return (
    <div className="relative mx-auto max-w-[1200px]">
      <div className="relative mx-auto">
        {/* Profile Image */}
        <div className="relative w-full">
          <Button
            variant="stroke"
            onClick={() => navigate(-1)}
            className="absolute left-4 top-4 z-10 w-[93px] !px-2"
          >
            <BsArrowLeft className="mb-1 mr-1" /> Back
          </Button>
          {chptr?.id && (
            <div className="absolute right-4 top-4 z-10 w-[120px] !px-2">
              <CopyChtprLinkButton
                chptrId={chptr.id}
                label={'Share'}
                className="mb-1 mr-1"
              />
            </div>
          )}

          {chptr && (chptr.pictureUrl || chptr.webPictureUrl) && (
            <>
              <img
                className="h-[100vw] w-full overflow-hidden object-cover md:h-[50vw]"
                src={
                  chptr.webPictureUrl ? chptr.webPictureUrl : chptr.pictureUrl!
                }
              />
              <div
                className={classNames(
                  'absolute top-0 h-[100vw] w-full bg-gradient-to-t from-black to-60% md:h-[50vw]',
                  {
                    'to-100%': !showVideo,
                  },
                )}
              />
            </>
          )}

          {!hasHeaderImage && (
            <ChptrPlaceholderProfileImage
              className="h-[280px] w-full md:h-[420px]"
              iconWrapperClassName="scale-100 md:scale-150 space-x-4"
            />
          )}

          <div
            className={classNames('absolute bottom-6 w-full text-center', {
              'text-neutral-900': !hasHeaderImage,
              'text-white': hasHeaderImage,
            })}
          >
            {chptr && (
              <p className="font-heading text-[40px] font-medium leading-[46px]">
                {chptr.type === ChptrType.Wedding
                  ? `${chptr.firstName} & ${chptr.lastName}`
                  : `${chptr.firstName} ${chptr.lastName}`}
              </p>
            )}
            {!chptr && (
              <div className="mx-auto h-8 w-1/2 animate-pulse rounded-full bg-neutral-300" />
            )}
            <div className="flex justify-center">
              {dates && <p className="text-lg tracking-wide">{dates}</p>}
              {(chptr?.location ||
                (chptr?.events && chptr.events[0]?.location)) && (
                <li className="pl-2 text-lg tracking-wide">
                  <span className="relative -left-3">{`${chptr?.location || chptr?.events[0].location}`}</span>
                </li>
              )}
            </div>
            {chptr?.property && (
              <p className="text-xl font-normal italic leading-8">
                Presented by: {chptr.property.name}
              </p>
            )}
          </div>
        </div>
        <div className="relative w-full">
          {/* Contributors & Fixed Video */}
          <AnimateHeight height={showVideo ? 'auto' : 0}>
            <div className="items-center px-6 py-4">
              {chptr && (
                <div className="mb-4 flex justify-center">
                  <ChptrCommunity chptr={chptr}></ChptrCommunity>
                </div>
              )}

              {!chptr && (
                <div className="mb-4 flex justify-center">
                  <div className="h-8 w-2/3 animate-pulse rounded-full bg-neutral-300" />
                </div>
              )}
            </div>
          </AnimateHeight>

          {chptr && placementId && (
            <div className="px-4 pt-4">
              <ContributeBar chptr={chptr} placementId={placementId} />
            </div>
          )}

          <div className="mb-4 flex justify-between px-4 pt-4">
            <DropDown
              listClassName="pl-6 pr-12 rounded"
              buttonClassName="pl-2 text-[#2E5E6B] text-base flex gap-1"
              buttonLabel={
                <>
                  <LuListFilter size={22} /> Filter By
                </>
              }
              elements={[
                {
                  label: (
                    <FilterElement
                      filter={filter}
                      type={ContributionMediaType.Image}
                    />
                  ),
                  onClick: () => onFilterChange(ContributionMediaType.Image),
                },
                {
                  label: (
                    <FilterElement
                      filter={filter}
                      type={ContributionMediaType.Video}
                    />
                  ),
                  onClick: () => onFilterChange(ContributionMediaType.Video),
                },
                {
                  label: (
                    <FilterElement
                      filter={filter}
                      type={ContributionMediaType.Text}
                    />
                  ),
                  onClick: () => onFilterChange(ContributionMediaType.Text),
                },
              ]}
            ></DropDown>

            <DropDown
              listClassName="pl-6 pr-12 right-4 rounded"
              buttonClassName="pl-2 text-[#2E5E6B] text-base flex gap-1"
              buttonLabel={`Sort By: ${orderLabel ? orderLabel : ''}`}
              elements={[
                { label: 'Newest', onClick: () => onOrderChange('Newest') },
                { label: 'Oldest', onClick: () => onOrderChange('Oldest') },
                {
                  label: 'By Contributor',
                  onClick: () => onOrderChange('By Contributor'),
                },
              ]}
            ></DropDown>
          </div>

          <div className="mx-4 mb-4">
            <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2} sequential>
              {featuredContributions &&
                featuredContributions
                  .filter(
                    (edge) => edge.mediaType !== ContributionMediaType.Audio,
                  ) // Temporarily filter out audio types until finalised designs
                  .map((edge) => (
                    <FeaturedContribution key={edge.id} contribution={edge} />
                  ))}
              {data?.chptr.contributions.edges &&
                data?.chptr.contributions.edges
                  .filter(
                    (edge) =>
                      edge.node.mediaType !== ContributionMediaType.Audio,
                  ) // Temporarily filter out audio types until finalised designs
                  .map((edge) => (
                    <Contribution key={edge.node.id} contribution={edge.node} />
                  ))}
            </Masonry>

            {(!chptr || chptr?.contributions.pageInfo.hasNextPage) && (
              <>
                <div
                  className="mb-4 flex animate-pulse flex-col rounded-xl border-2 border-neutral-300 p-4"
                  ref={ref}
                >
                  <div className="mx-4 h-32 bg-neutral-300" />
                  <div className="mx-4 mt-6 h-6 bg-neutral-300" />
                </div>
                {Array.from({ length: 3 }).map((_, idx) => (
                  <div
                    key={idx.toString()}
                    className="mb-4 flex animate-pulse flex-col rounded-xl border-2 border-neutral-300 p-4"
                  >
                    <div className="mx-4 h-32 bg-neutral-300" />
                    <div className="mx-4 mt-6 h-6 bg-neutral-300" />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterElement = ({
  filter,
  type,
}: {
  filter: ContributionMediaType | null;
  type: ContributionMediaType;
}) => {
  return (
    <div className="flex gap-3 capitalize">
      {filter === type ? (
        <MdCheckBox className="text-[#2E5E6B]" size={22} />
      ) : (
        <MdCheckBoxOutlineBlank size={22} />
      )}
      {type.toLowerCase()}
    </div>
  );
};

export default ChptrMain;

// https://dev.to/justincy/4-patterns-for-responsive-props-in-react-39ak#conditional-rendering
import { useMediaQuery } from 'usehooks-ts';

export default function useBreakpoints() {
  const breakpoints = {
    isXs: useMediaQuery('(max-width: 499px)'),
    isSm: useMediaQuery('(min-width: 500px) and (max-width: 824px)'),
    isMd: useMediaQuery('(min-width: 825px) and (max-width: 999px)'),
    isLg: useMediaQuery('(min-width: 1000px)'),
    active: 'xs',
  };

  if (breakpoints.isXs) breakpoints.active = 'xs';
  if (breakpoints.isSm) breakpoints.active = 'sm';
  if (breakpoints.isMd) breakpoints.active = 'md';
  if (breakpoints.isLg) breakpoints.active = 'lg';

  return breakpoints;
}

import cn from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input/input';

export default function FormPhoneInput({
  name,
  label,
  placeholder,
}: {
  name: string;
  label?: string;
  placeholder?: string;
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let err: any = errors;
  name.split('.').forEach((part) => {
    if (err && err[part]) {
      err = err[part];
    }
  });

  if (!err.message) {
    err = undefined;
  }

  return (
    <div className="flex flex-col space-y-2">
      {label && (
        <label
          htmlFor={name}
          className={cn('font-heading', { 'text-[#EF341E]': err })}
        >
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <PhoneInput
              country="US"
              placeholder={placeholder}
              onChange={(e) => {
                if (e === undefined) {
                  onChange('');
                  return;
                }
                onChange(e);
              }}
              className={cn(
                'h-[48px] rounded-[10px] border-[1px] border-[#D9D9D9] bg-[#F8F8F8] p-2',
                { 'border-[#EF341E]': err },
              )}
              value={value}
            />
          );
        }}
      />
      {err && (
        <p className="!mt-1 text-[13px] leading-[16px] text-[#EF341E]">
          {err.message as string}
        </p>
      )}
    </div>
  );
}

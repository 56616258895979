import cn from 'classnames';
import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

export default function FormInput({
  name,
  label,
  required = false,
  inputClassName,
  labelClassName,
  ...rest
}: {
  name: string;
  label?: string;
  required?: boolean;
  labelClassName?: string;
  inputClassName?: string;
} & InputHTMLAttributes<HTMLInputElement>) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let err: any = errors;
  name.split('.').forEach((part) => {
    if (err && err[part]) {
      err = err[part];
    }
  });

  if (!err.message) {
    err = undefined;
  }

  return (
    <div className="flex flex-col space-y-2">
      {label && (
        <label
          htmlFor={name}
          className={cn(
            'font-heading',
            { 'text-[#EF341E]': err },
            labelClassName,
          )}
        >
          {label} {required && '*'}
        </label>
      )}
      <input
        className={cn(
          'h-[48px] rounded-[10px] border-[1px] border-[#D9D9D9] bg-[#F8F8F8] p-2',
          { 'border-[#EF341E]': err },
          inputClassName,
        )}
        {...register(name)}
        {...rest}
      />
      {err && (
        <p className="!mt-1 text-[13px] leading-[16px] text-[#EF341E]">
          {err.message as string}
        </p>
      )}
    </div>
  );
}

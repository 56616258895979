import classNames from 'classnames';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { BsArrowLeft, BsPersonCircle, BsXLg } from 'react-icons/bs';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import { User, useUser } from '../contexts/user';
import {
  AuthenticateUserInput,
  LoginUserInput,
  RegisterUserInput,
  UpdateUserInput,
  useAuthenticateUserMutation,
  useLoginUserMutation,
  useRegisterUserMutation,
  useUpdateUserMutation,
} from '../gql/generated';
import Button from './ui/Button';
import { LoginRegisterForm } from './ui/LoginRegisterForm';
import Modal from './ui/Modal';

type AuthenticateRegisterUpdateLoginProps = {
  placementId: string;
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
};

const AuthenticateRegisterUpdateLogin = ({
  user,
  setUser,
  placementId,
}: AuthenticateRegisterUpdateLoginProps) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const [createUser] = useRegisterUserMutation({
    onCompleted: (data) => {
      setUser({
        ...data.registerUser.user,
        email: data.registerUser.user.email as string,
        token: data.registerUser.token,
      });
      window.location.reload(); // Reloading page so Intercom Messsenger updates for new user
    },
  });

  const [updateUser] = useUpdateUserMutation({
    onCompleted: (data) => {
      setUser((prevUser) => ({
        ...prevUser!,
        ...data.updateUser,
        email: data.updateUser.email as string,
      }));
      window.location.reload(); // Reloading page so Intercom Messsenger updates for new user
    },
  });

  const [authenticateUser] = useAuthenticateUserMutation({
    onCompleted: (data) => {
      setUser({
        ...data.authenticateUser.user,
        email: data.authenticateUser.user.email as string,
        token: data.authenticateUser.token,
      });
      window.location.reload(); // Reloading page so Intercom Messsenger updates for new user
    },
  });

  const [loginUser] = useLoginUserMutation({
    onError: console.log,
    onCompleted: (data) => {
      setUser({
        ...data.loginUser.user,
        email: data.loginUser.user.email as string,
        token: data.loginUser.token,
      });
      window.location.reload(); // Reloading page so Intercom Messsenger updates for new user
    },
  });

  const onRegisterUser = useCallback(
    async (data: RegisterUserInput) => {
      const result = await createUser({
        variables: {
          input: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            placementId,
          },
        },
        errorPolicy: 'all',
      });

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
    },
    [createUser, placementId],
  );

  const onUpdateUser = useCallback(
    async (data: Omit<UpdateUserInput, 'id'>) => {
      const result = await updateUser({
        variables: {
          input: {
            id: user!.id,
            ...data,
          },
        },
        errorPolicy: 'all',
      });

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
    },
    [updateUser, user],
  );

  const onAuthenticate = useCallback(
    async (data: AuthenticateUserInput) => {
      console.log('onAuthenticate :: data', {
        input: {
          ...data,
          placementId,
        },
      });

      try {
        const result = await authenticateUser({
          variables: {
            input: {
              ...data,
              placementId,
            },
          },
          errorPolicy: 'all',
        });

        console.log('onAuthenticate', result);

        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
      } catch (e) {
        console.error(e);

        if (e instanceof Error) {
          console.log(e.stack);
        }

        throw e;
      }
    },
    [authenticateUser, placementId],
  );

  const onLogin = useCallback(
    async (data: LoginUserInput) => {
      const result = await loginUser({
        variables: {
          input: data,
        },
        errorPolicy: 'all',
      });

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
    },
    [loginUser],
  );

  return (
    <>
      <button
        onClick={() => setModalOpen(true)}
        className="flex flex-row items-center space-x-2 font-heading text-lg text-white underline hover:no-underline"
      >
        <BsPersonCircle />
        <span>Sign In/Sign Up</span>
      </button>

      <Modal
        enableMobile
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        containerClassName={classNames('lg:w-1/2 lg:mx-auto w-full pt-4')}
      >
        <div className="z-10 mb-4 w-full bg-white">
          <Button
            variant="stroke"
            onClick={() => {
              setModalOpen(false);
            }}
            className="z-10 w-[93px] !px-2 sm:hidden"
          >
            <BsArrowLeft className="mb-1 mr-1" /> Back
          </Button>
          <button
            className="hidden sm:block"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <BsXLg />
          </button>
        </div>
        <LoginRegisterForm
          onAuthenticate={onAuthenticate}
          onLogin={onLogin}
          onRegisterCreate={onRegisterUser}
          onRegisterUpdate={onUpdateUser}
        />
      </Modal>
    </>
  );
};

type AuthenticateUserProps = {
  user: User;
  onSignOut: () => void;
};

const AuthenticateUser = ({ user, onSignOut }: AuthenticateUserProps) => {
  const [isMenuExpanded, setMenuExpanded] = useState(false);

  return (
    <div className="relative">
      <button
        onClick={() => setMenuExpanded(!isMenuExpanded)}
        className="flex flex-row items-center space-x-2 font-heading text-lg text-white underline hover:no-underline"
      >
        <BsPersonCircle />
        <span>Hi, {user.firstName}</span>
        {isMenuExpanded ? (
          <FaChevronUp size={12} />
        ) : (
          <FaChevronDown size={12} />
        )}
      </button>

      {isMenuExpanded && (
        <div className="absolute left-6 z-10 rounded-md bg-white shadow-md">
          <button
            onClick={onSignOut}
            className="rounded-md px-8 py-1 font-heading hover:bg-neutral-100"
          >
            Sign out
          </button>
        </div>
      )}
    </div>
  );
};

type AuthenticationModalProps = {
  top?: number;
  placementId: string;
};

export const AuthenticationModal = ({
  placementId,
}: AuthenticationModalProps) => {
  const [user, setUser] = useUser();

  const onSignOut = useCallback(() => {
    setUser(null);
    window.location.reload(); // Reloading page so Intercom Messsenger updates for new user
  }, [setUser]);

  if (user) {
    return <AuthenticateUser user={user} onSignOut={onSignOut} />;
  }

  return (
    <AuthenticateRegisterUpdateLogin
      user={user}
      placementId={placementId}
      setUser={setUser}
    />
  );
};

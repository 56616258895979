import { GoogleOAuthProvider } from '@react-oauth/google';
import { Outlet } from 'react-router-dom';
import { useScript } from 'usehooks-ts';

import App from './App.tsx';
import { ApplicationProvider } from './contexts/application.tsx';
import { USER_KEY, User, UserProvider } from './contexts/user.tsx';

function Root() {
  useScript(
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
  );

  let user: User | null;

  try {
    const localStorageUser = localStorage.getItem(USER_KEY);

    if (localStorageUser && localStorageUser === 'undefined') {
      localStorage.removeItem(USER_KEY);
    }

    user = JSON.parse(localStorageUser || 'null');
  } catch (e) {
    user = null;
  }

  return (
    <GoogleOAuthProvider clientId="1002152007198-gb9fvf835eq4i9ragcvkg5vc1jhuhbt6.apps.googleusercontent.com">
      <UserProvider user={user}>
        <ApplicationProvider position={0}>
          <App>
            <Outlet />
          </App>
        </ApplicationProvider>
      </UserProvider>
    </GoogleOAuthProvider>
  );
}

export default Root;

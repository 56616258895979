import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'unfonts.css';

import Root from './Root.tsx';
import './index.css';
import ChptrMain from './screens/ChptrMain.tsx';
import EventSearch from './screens/EventSearch.tsx';
import Home from './screens/Home.tsx';
import NotFound from './screens/NotFound.tsx';
import Purchase from './screens/Purchase.tsx';
import PurchaseComplete from './screens/PurchaseComplete.tsx';
import Search from './screens/Search.tsx';
import ContributionComments from './screens/modals/ContributionComments.tsx';

const router = createBrowserRouter([
  {
    path: '/',
    id: 'NOT_FOUND',
    element: <NotFound />,
  },
  {
    path: '/',
    id: 'ROOT',
    element: <Root />,
    children: [
      {
        id: 'HOME',
        path: ':placementId',
        element: <Home />,
      },
      {
        id: 'PURCHASE',
        path: ':placementId/purchase',
        element: <Purchase />,
      },
      {
        path: ':placementId/order/:orderId',
        element: <PurchaseComplete />,
      },
      {
        path: ':placementId/chptr/:id',
        element: <ChptrMain />,
      },
      {
        path: ':placementId/search',
        element: <Search />,
      },
      {
        path: ':placementId/event-search',
        element: <EventSearch />,
      },
      {
        path: ':placementId/modals/contributions/:contributionId/comments',
        element: <ContributionComments />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

import { IoLogoYoutube } from 'react-icons/io';
import ReactPlayer from 'react-player/lazy';
import { Link } from 'react-router-dom';

import { ChptrType, Contribution } from '../gql/generated';

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

interface FeaturedVideoCardParams {
  contribution: RecursivePartial<Contribution>;
  linkTo: string;
}

const FeaturedVideoCard = ({
  contribution,
  linkTo,
}: FeaturedVideoCardParams) => (
  <div key={contribution.id} className="flex-none overflow-clip sm:w-[70%]">
    <div className="aspect-video">
      <ReactPlayer
        playing
        playsinline
        width={'100%'}
        height={'100%'}
        style={{
          display: 'flex',
          justifyContent: 'center',
          borderRadius: 20,
          overflow: 'hidden',
          backgroundColor: 'black',
        }}
        playIcon={<IoLogoYoutube size={50} color="#e5e5e5" />}
        url={contribution.url ?? ''}
        light={contribution.thumbnailUrl ?? ''}
        controls
      />
    </div>
    <Link to={linkTo}>
      <p className="mt-2 text-justify text-[17px] leading-[22px]">
        {contribution.caption ??
          `A video contribution from ${
            contribution.chptr?.type === ChptrType.Wedding
              ? `${contribution.chptr.firstName} & ${contribution.chptr.lastName}`
              : `${contribution.chptr?.firstName} ${contribution.chptr?.lastName}'s`
          } ${contribution.chptr?.type?.toLowerCase()}`}
      </p>
    </Link>
  </div>
);

export default FeaturedVideoCard;

export const FeaturedVideoCardSkeleton = () => (
  <div className="flex h-48 flex-none animate-pulse flex-col justify-center overflow-clip rounded-xl border-2 border-neutral-300 bg-neutral-300 sm:w-[48%] md:w-full">
    <IoLogoYoutube className="mx-auto" size={50} color="#e5e5e5" />
  </div>
);

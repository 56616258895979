import Intercom, { boot, shutdown } from '@intercom/messenger-js-sdk';
import { isMobile } from 'react-device-detect';

import { User } from '../contexts/user';

const IntercomMessenger = (user: User | null) => {
  if (isMobile) return;

  Intercom({
    app_id: 'sktfigmq',
  });
  shutdown();

  if (!user) {
    return boot({
      app_id: 'sktfigmq',
    });
  }
  boot({
    app_id: 'sktfigmq',
    user_id: user.id,
    user_name: user.firstName,
    email: user.email,
    created_at: new Date().getTime() / 1000,
  });
};

export default IntercomMessenger;

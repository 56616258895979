import cn from 'classnames';
import { PropsWithChildren } from 'react';

import LoadingFlowers from './LoadingFlowers';

export type PageModalProps = PropsWithChildren<{
  containerClassName?: string;
}>;

const PageModal = ({ containerClassName, children }: PageModalProps) => (
  <>
    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
    <div className="fixed inset-0 w-screen">
      <div className="relative flex h-full w-full flex-1 items-center justify-center sm:p-4">
        <div
          className={cn('mx-auto rounded-2xl bg-white p-6', containerClassName)}
        >
          {children && children}

          {!children && <LoadingFlowers />}
        </div>
      </div>
    </div>
  </>
);

export default PageModal;

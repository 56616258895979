import { FetchResult } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import { jwtDecode } from 'jwt-decode';
import {
  DetailedHTMLProps,
  Dispatch,
  InputHTMLAttributes,
  SetStateAction,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import AppleLogin from 'react-apple-login';
import { useForm } from 'react-hook-form';
import { FiArrowLeft } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { z } from 'zod';

import ButtonDownloadApple from '../../assets/button-download-apple.png';
import ButtonDownloadGoogle from '../../assets/button-download-google.png';
import IconApple from '../../assets/icon-apple.png';
import IconGoogle from '../../assets/icon-google.png';
import { useAnalytics } from '../../contexts/analytics';
import { User } from '../../contexts/user';
import {
  AuthProvider,
  AuthenticateUserMutation,
  UpdateUserMutation,
} from '../../gql/generated';
import useGoogleLogin from '../../hooks/useGoogleLogin';
import usePlacementTerms from '../../hooks/usePlacementTerms';
import ContributeMediaTypeButton, {
  ContributionMediaType,
} from './ContributeMediaTypeButton';

export type ContributeContributorInformationPayload = {
  firstName: string;
  lastInitial: string;
  location?: string | null;
};

export type ContributeAccountInformationPayload =
  | {
      email: string;
      password: string;
      passwordConfirm: string;
    }
  | {
      email: string;
      provider: AuthProvider;
      externalId: string;
      pictureUrl?: string;
      firstName: string;
      lastName: string;
    };

const isValidPassword = (password: string) => {
  if (password.length < 8) {
    return false;
  }

  if (!/[a-z]/.test(password)) {
    return false;
  }

  if (!/[A-Z]/.test(password)) {
    return false;
  }

  if (!/\d/.test(password)) {
    return false;
  }

  return true;
};

const SCHEMA_CONTRIBUTOR_INFORMATION = z.object({
  firstName: z.string().min(1),
  lastInitial: z.string().min(1),
  location: z.string().optional().nullable(),
});

const SCHEMA_ACCOUNT_INFORMATION = z
  .object({
    email: z.string().email(),
    password: z
      .string()
      .refine(
        isValidPassword,
        'Password must contain at least 8 characters, including uppercase, lowercase, and numbers',
      ),
    passwordConfirm: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Password doesn't match",
    path: ['passwordConfirm'],
  });

type ContributeFlowTextInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  containerClassName?: string;
  label: string;
  required?: boolean;
  error?: string;
};

export const ContributeFlowTextInput = forwardRef<
  HTMLInputElement,
  ContributeFlowTextInputProps
>(({ label, required, containerClassName, className, error, ...rest }, ref) => (
  <div className={containerClassName}>
    <p className="pb-1 font-heading text-neutral-600">
      {label}
      {required && '*'}
    </p>

    <div className="rounded-xl border-[1px] border-neutral-300 bg-neutral-100 p-2 px-3">
      <input
        type="text"
        className={classNames(
          'w-full bg-transparent font-heading outline-none',
          className,
        )}
        ref={ref}
        {...rest}
      />
    </div>

    {error && (
      <p className="mt-1 text-xs leading-[16px] text-[#EF341E]">{error}</p>
    )}
  </div>
));

type ContributeFlowModalHeaderProps = {
  LeftComponent?: React.ReactNode;
  RightComponent?: React.ReactNode;
  title: string;
};

export const ContributeFlowModalHeader = ({
  LeftComponent,
  RightComponent,
  title,
}: ContributeFlowModalHeaderProps) => (
  <div className="flex flex-row items-center justify-between">
    <div className="w-12">{LeftComponent || null}</div>

    <p className="font-heading text-xl text-neutral-600">{title}</p>

    <div className="flex w-12 justify-end">{RightComponent || null}</div>
  </div>
);

type BaseContributeFlowStepProps = {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  onClose: () => void;
  user: User | null;
};

type ContributeFlowContributorInformationStepProps =
  BaseContributeFlowStepProps & {
    onContributorInformation: (
      payload: ContributeContributorInformationPayload,
    ) => unknown;
  };

export const ContributeFlowContributorInformationStep = ({
  onClose,
  onContributorInformation,
  setStep,
}: ContributeFlowContributorInformationStepProps) => {
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastInitial: '',
      location: '',
    },
    resolver: zodResolver(SCHEMA_CONTRIBUTOR_INFORMATION),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await onContributorInformation({
        ...data,
        location:
          data.location && data.location.length > 0 ? data.location : null,
      });
    } catch (error) {
      console.error(error);

      throw error;
    }
  }, console.error);

  return (
    <div>
      <ContributeFlowModalHeader
        title="Fill information"
        LeftComponent={
          <FiArrowLeft
            size={24}
            className="cursor-pointer"
            onClick={() => setStep((prev) => prev - 1)}
          />
        }
        RightComponent={
          <IoMdClose size={24} className="cursor-pointer" onClick={onClose} />
        }
      />

      <form onSubmit={onSubmit}>
        <div className="mt-4 rounded-2xl border-[1px] border-neutral-200 p-4 px-8 shadow-sm">
          <div className="flex flex-col justify-between pt-2 md:flex-row">
            <ContributeFlowTextInput
              required
              label="First Name"
              containerClassName="w-full md:w-halfish"
              {...register('firstName')}
            />

            <ContributeFlowTextInput
              required
              label="Last Initial"
              containerClassName="w-full md:w-halfish pt-4 md:pt-0"
              {...register('lastInitial')}
            />
          </div>

          <ContributeFlowTextInput
            label="Location (optional)"
            containerClassName="w-full pt-4"
            {...register('location')}
          />

          <div className="flex flex-row justify-center pt-4">
            <button className="mb-2 w-full rounded-full bg-amber-400 p-2 font-heading text-lg hover:opacity-80 md:w-2/3">
              Contribute
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

type ContributeFlowThankYouStepProps = BaseContributeFlowStepProps & {
  chptr: { id: string };
  onAccountInformation: (
    payload: ContributeAccountInformationPayload,
  ) => Promise<FetchResult<UpdateUserMutation | AuthenticateUserMutation>>;
  onMediaTypeClick: (type: ContributionMediaType) => unknown;
};

export const ContributeFlowThankYouStep = ({
  onClose,
  onAccountInformation,
  onMediaTypeClick,
  user,
}: ContributeFlowThankYouStepProps) => {
  const { segmentTrack } = useAnalytics();
  const { login: googleLogin } = useGoogleLogin({
    onSuccess: (profile) => {
      onAccountInformation(profile);
    },
  });

  const [error, setError] = useState<Error | null>(null);

  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
    },
    resolver: zodResolver(SCHEMA_ACCOUNT_INFORMATION),
  });

  const onAppleLogin = useCallback(
    (data: {
      authorization: {
        id_token: string;
        user?: {
          name: { firstName: string; lastName: string };
          email: string;
        };
      };
    }) => {
      const decoded = jwtDecode(data.authorization.id_token) as {
        email: string;
        sub: string;
      };

      if (data.authorization.user) {
        const { firstName, lastName } = data.authorization.user.name;

        onAccountInformation({
          firstName,
          lastName,
          email: decoded.email,
          provider: AuthProvider.Apple,
          externalId: decoded.sub,
        });
      } else {
        onAccountInformation({
          firstName: 'Apple',
          lastName: 'User',
          email: decoded.email,
          provider: AuthProvider.Apple,
          externalId: decoded.sub,
        });
      }
    },
    [onAccountInformation],
  );

  const onSubmit = handleSubmit(
    async (data) => {
      setError(null);

      try {
        const result = await onAccountInformation(data);

        if (result.errors && result.errors.length > 0) {
          segmentTrack('Account Creation Failed');
          setError(new Error(result.errors[0].message));
        }
      } catch (err) {
        setError(err as Error);
      }
    },
    () => {
      setError(new Error('One or more fields are invalid'));
    },
  );

  const {
    chptrTermsAccepted,
    setChptrTermsAccepted,
    partnerTermsAccepted,
    setPartnerTermsAccepted,
    hasPartnerTerms,
    validateTerms,
  } = usePlacementTerms({ mode: 'register' });

  const checkTerms = useCallback(
    (cb: () => void) => {
      validateTerms((isValid) => {
        if (!isValid) {
          setError(new Error('Please accept the terms and conditions'));
          return;
        }

        cb();
        setError(null);
      });
    },
    [validateTerms],
  );

  return (
    <div>
      <div>
        <div
          className="rounded-t-2xl p-4 px-4"
          style={{
            background:
              'linear-gradient(96.65deg, rgba(255, 227, 137, 0.8) 1.47%, rgba(255, 180, 110, 0.776) 99.16%',
          }}
        >
          <div className="flex flex-row justify-end">
            <IoMdClose size={24} className="cursor-pointer" onClick={onClose} />
          </div>

          <p className="pl-2 font-heading text-4xl leading-snug text-neutral-900">
            Your memory is
            <br />
            appreciated
          </p>

          <p className="py-4 pl-2 text-sm text-neutral-500">
            Stay tuned, your contribution will be posted within 24 hours.
          </p>
        </div>
      </div>

      <div className="mx-4 -mt-6 mb-4">
        <div className="flex flex-col items-center rounded-2xl border-[1px] border-neutral-300 bg-white px-4 py-4 md:px-8 md:py-6">
          <p className="pb-4 font-heading text-xl md:pb-8">Share another</p>

          <div className="flex w-full flex-row px-4 md:px-0">
            <ContributeMediaTypeButton
              inverted
              type={ContributionMediaType.Video}
              onClick={() => onMediaTypeClick(ContributionMediaType.Video)}
            />
            <div className="w-10 py-2 md:py-0" />
            <ContributeMediaTypeButton
              inverted
              type={ContributionMediaType.Image}
              onClick={() => onMediaTypeClick(ContributionMediaType.Image)}
            />
            <div className="w-10 py-2 md:py-0" />
            <ContributeMediaTypeButton
              inverted
              type={ContributionMediaType.Text}
              onClick={() => onMediaTypeClick(ContributionMediaType.Text)}
            />
          </div>
        </div>

        <form
          onSubmit={(o) => {
            o.preventDefault();
            checkTerms(() => onSubmit(o));
          }}
        >
          <div className="mt-4 rounded-2xl border-[1px] border-neutral-200 p-4 px-8 shadow-sm">
            {(!user || user.isContributionUser) && (
              <div className="mb-4">
                <div className="flex flex-col items-center pb-4">
                  <p className="font-heading text-xl">Create an account</p>
                  <p className="pt-2 text-sm text-neutral-600">
                    Save your information by creating an account
                  </p>

                  <div className="pb-2 pt-6 text-[#282828]">
                    <div>
                      <input
                        type="checkbox"
                        defaultChecked={chptrTermsAccepted}
                        onClick={() => setChptrTermsAccepted((v) => !v)}
                      />{' '}
                      <div className="inline">
                        By checking this box and creating an account, you also
                        agree to be bound by{' '}
                        <a
                          href="https://www.chptr.com/privacy"
                          target="_blank"
                          className="underline"
                        >
                          Chptr’s Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a
                          href="https://www.chptr.com/terms"
                          target="_blank"
                          className="underline"
                        >
                          Chptr’s Terms of Service
                        </a>
                        . You also expressly agree that Chptr, Inc. and its
                        affiliates, subsidiaries and partners may access your
                        personal information and communicate with you using this
                        information, subject to{' '}
                        <a
                          href="https://www.chptr.com/privacy"
                          target="_blank"
                          className="underline"
                        >
                          Chptr’s Privacy Policy
                        </a>
                        .
                      </div>
                    </div>
                    {hasPartnerTerms && (
                      <div>
                        <input
                          type="checkbox"
                          defaultChecked={partnerTermsAccepted}
                          onClick={() => setPartnerTermsAccepted((v) => !v)}
                        />{' '}
                        <div className="inline">
                          By checking this box and creating an account, you (1)
                          represent and warrant that you are at least eighteen
                          (18) years of age and (2) agree to be bound by{' '}
                          <a
                            href="https://sbgi.net/privacy-policy/"
                            target="_blank"
                            className="underline"
                          >
                            Sinclair’s Privacy Policy
                          </a>{' '}
                          and{' '}
                          <a
                            href="https://sbgi.net/terms-conditions/"
                            target="_blank"
                            className="underline"
                          >
                            Sinclair’s Terms & Conditions
                          </a>
                          . You also expressly agree that Sinclair, Inc. and its
                          affiliates, subsidiaries and partners may access your
                          personal information and communicate with you using
                          this information, subject to{' '}
                          <a
                            href="https://sbgi.net/privacy-policy/"
                            target="_blank"
                            className="underline"
                          >
                            Sinclair’s Privacy Policy
                          </a>
                          .
                        </div>
                      </div>
                    )}
                  </div>

                  {error && (
                    <p className="pt-2 text-sm text-red-500">{error.message}</p>
                  )}
                </div>

                <div className="flex flex-col items-center space-y-4 px-[15%]">
                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      checkTerms(() => googleLogin());
                    }}
                    className="flex w-full flex-row items-center justify-center space-x-2 rounded-full border-2 border-neutral-300 p-2.5 font-bold text-neutral-700 hover:border-neutral-400 hover:bg-neutral-50"
                  >
                    <img className="h-4" src={IconGoogle} />
                    <p>Continue with Google</p>
                  </button>

                  <AppleLogin
                    usePopup
                    clientId="com.chptr.chptr.enterprise"
                    callback={(o) => checkTerms(() => onAppleLogin(o))}
                    scope="name email"
                    redirectURI="https://partners.chptr.com"
                    render={({ onClick }) => (
                      <button
                        className="flex w-full flex-row items-center justify-center space-x-2 rounded-full border-2 border-neutral-300 p-2.5 font-bold text-neutral-700 hover:border-neutral-400 hover:bg-neutral-50"
                        onClick={onClick}
                      >
                        <img className="h-4" src={IconApple} />
                        <p>Continue with Apple</p>
                      </button>
                    )}
                  />

                  <p className="py-4">or</p>
                </div>

                <div className="space-y-4">
                  <ContributeFlowTextInput
                    required
                    label="Email"
                    containerClassName="w-full"
                    {...register('email')}
                  />

                  <ContributeFlowTextInput
                    required
                    type="password"
                    label="Password"
                    containerClassName="w-full"
                    {...register('password')}
                  />

                  <ContributeFlowTextInput
                    required
                    type="password"
                    label="Password Confirmation"
                    containerClassName="w-full"
                    {...register('passwordConfirm')}
                  />
                </div>

                <div className="flex flex-row justify-center pt-4">
                  <button className="w-full rounded-full bg-amber-400 p-2 font-heading text-lg hover:opacity-80 md:w-2/3">
                    Create Account
                  </button>
                </div>
              </div>
            )}

            <div className="flex flex-col items-center">
              <p className="pt-2 text-sm text-neutral-600">
                Download the Chptr app to continue building their digital
                legacy.
              </p>

              <div className="flex flex-row items-center justify-center pt-4">
                <a
                  href="https://apps.apple.com/app/apple-store/id1672492101?pt=124689463&mt=8"
                  target="_blank"
                >
                  <img className="mx-2 h-10" src={ButtonDownloadApple} />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.chptr.chptr.v2"
                  target="_blank"
                >
                  <img className="mx-2 h-10" src={ButtonDownloadGoogle} />
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

import { formatInTimeZone } from 'date-fns-tz';

import { GMT_TIMEZONE } from '../../modules/Constants';

const DateText = ({
  format = 'EEEE, MMMM d, yyyy',
  children,
}: {
  format?: string;
  children: React.ReactNode;
}) => {
  const formatted = formatInTimeZone(
    new Date(children as string),
    GMT_TIMEZONE,
    format,
  );

  return <span>{formatted}</span>;
};

export default DateText;

import classNames from 'classnames';
import Linkify from 'linkify-react';
import { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { BsChat, BsPinAngleFill } from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';
import { IoLogoYoutube } from 'react-icons/io';
import Markdown from 'react-markdown';
import ReactPlayer from 'react-player/lazy';
import TruncateMarkup from 'react-truncate-markup';
import { useResizeObserver } from 'usehooks-ts';

import { useApplication } from '../contexts/application';
import {
  ContributionMediaType,
  GetChptrContributionsQuery,
} from '../gql/generated';
import useBreakpoints from '../hooks/useBreakpoints';
import RemoteImage from './ui/RemoteImage';

const MAX_LINES = 14;

type ContributionProps = {
  contribution: GetChptrContributionsQuery['chptr']['contributions']['edges'][number]['node'];
  className?: string;
};

const Contribution = ({
  contribution,
  className,
  ...rest
}: ContributionProps) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<ReactPlayer>(null);

  const { parentWindowHeight } = useApplication();
  const { isXs, active } = useBreakpoints();

  const { width = 0, height = 0 } = useResizeObserver({
    ref: videoContainerRef,
  });

  const containerHeight = isXs
    ? parentWindowHeight * 0.75
    : parentWindowHeight * 0.6;

  const Text = () => {
    const [isTruncated, setIsTruncated] = useState<boolean>(true);

    const ShowButton = () => (
      <>
        {' '}
        <button
          className="text-[#006B99] underline"
          onClick={() => setIsTruncated((o) => !o)}
        >
          ...View {isTruncated ? 'more' : 'less'}
        </button>
      </>
    );

    if (!contribution?.caption) {
      return null;
    }

    const lines = contribution.caption.split('\n');

    // On mobile, we show a maximum of 14 lines, with a "show more/less" button
    if (isMobile) {
      return isTruncated ? (
        <TruncateMarkup lines={MAX_LINES} ellipsis={<ShowButton />}>
          <div className="font-heading">
            {lines.map((line: string, idx) => (
              <Linkify
                key={idx}
                options={{ className: 'text-cyan-900', target: '_blank' }}
              >
                {line}
                <br />
              </Linkify>
            ))}
          </div>
        </TruncateMarkup>
      ) : (
        <div className="font-heading">
          {lines.map((line: string, idx) => (
            <Linkify key={idx}>
              {line}
              <br />
            </Linkify>
          ))}
          <ShowButton />
        </div>
      );
    }

    // On desktop, we make scrollable and cap the div at 336px which is line height of 24px * 14 lines.
    return (
      <div className="max-h-[336px] overflow-auto font-heading">
        {lines.map((line: string, idx) => (
          <Linkify
            key={idx}
            options={{ className: 'text-cyan-900', target: '_blank' }}
          >
            {line}
            <br />
          </Linkify>
        ))}
      </div>
    );
  };

  const openCommentModal = () => {
    if (videoRef.current) {
      videoRef.current.getInternalPlayer()?.pause();
    }

    window.parent.postMessage(
      ['openModal', `contributions/${contribution.id}/comments`],
      '*',
    );
  };

  return (
    <div
      className={classNames(
        'w-full break-inside-avoid-column rounded-2xl border border-neutral-300 bg-white p-3 shadow-sm',
        className,
      )}
      {...rest}
    >
      {contribution.mediaType === ContributionMediaType.Image &&
        contribution.blurHash && (
          <RemoteImage
            className={classNames('max-w-1/3 rounded-xl', {
              '!object-contain': active === 'sm',
            })}
            src={contribution.url!}
            hash={contribution.blurHash}
            style={{
              minHeight: parentWindowHeight * 0.3,
              maxHeight: containerHeight,
            }}
          />
        )}

      {contribution.mediaType === ContributionMediaType.Video &&
        contribution.url && (
          <div className="w-full" ref={videoContainerRef}>
            {width > 0 && (
              <ReactPlayer
                ref={videoRef}
                width={'auto'}
                playing
                playsinline
                playIcon={<IoLogoYoutube size={50} color="#e5e5e5" />}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: 15,
                  overflow: 'hidden',
                  backgroundColor: 'black',
                }}
                light={
                  contribution.thumbnailUrl
                    ? contribution.thumbnailUrl
                        .replace(/width=\d+/, `width=${Math.round(width)}`)
                        .replace(/height=\d+/, `height=${Math.round(height)}`)
                    : true
                }
                url={contribution.url}
                controls
              />
            )}
          </div>
        )}

      {contribution.mediaType !== ContributionMediaType.Text && (
        <Markdown
          className="font-heading"
          components={{
            p(props) {
              return (
                <p className="overflow-hidden text-ellipsis">
                  <Linkify
                    options={{ className: 'text-cyan-900', target: '_blank' }}
                  >
                    {props.children}
                  </Linkify>
                </p>
              );
            },
          }}
        >
          {contribution.caption || contribution.title}
        </Markdown>
      )}

      {contribution.mediaType === ContributionMediaType.Text && <Text />}

      <div className="my-2 ml-2 grid grid-cols-2">
        <p className="text-sm font-bold">{`${contribution.user.firstName} ${contribution.user.lastName}`}</p>
      </div>

      <div className="flex justify-between">
        <div
          className="border-1 flex h-[38px] cursor-pointer items-center space-x-2 rounded-[100px] border border-[#E2E2E2] bg-[#FAFAFA] px-6 py-[10px] text-[#282828]"
          onClick={openCommentModal}
        >
          <BsChat size={20} /> <span>{contribution.commentsV2.totalCount}</span>
        </div>

        <button onClick={openCommentModal}>
          <div className="border-1 flex h-[38px] items-center space-x-2 rounded-[100px] border border-[#E2E2E2] bg-[#FAFAFA] px-4 py-[10px] text-[#282828]">
            <FiSend size={20} />
          </div>
        </button>
      </div>
    </div>
  );
};

type FeaturedContributionProps = {
  contribution: GetChptrContributionsQuery['chptr']['featuredContributions'][number];
  className?: string;
};

export const FeaturedContribution = ({
  contribution,
  className,
  ...rest
}: FeaturedContributionProps) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<ReactPlayer>(null);

  const { parentWindowHeight } = useApplication();
  const { isXs } = useBreakpoints();

  const { width = 0, height = 0 } = useResizeObserver({
    ref: videoContainerRef,
  });

  const containerHeight = isXs
    ? parentWindowHeight * 0.75
    : parentWindowHeight * 0.6;

  const Text = () => {
    const [isTruncated, setIsTruncated] = useState<boolean>(true);

    const ShowButton = () => (
      <>
        {' '}
        <button
          className="text-[#006B99] underline"
          onClick={() => setIsTruncated((o) => !o)}
        >
          ...View {isTruncated ? 'more' : 'less'}
        </button>
      </>
    );

    if (!contribution?.caption) {
      return null;
    }

    const lines = contribution.caption.split('\n');

    // On mobile, we show a maximum of 14 lines, with a "show more/less" button
    if (isMobile) {
      return isTruncated ? (
        <TruncateMarkup lines={MAX_LINES} ellipsis={<ShowButton />}>
          <div className="font-heading">
            {lines.map((line: string, idx) => (
              <Linkify
                key={idx}
                options={{ className: 'text-cyan-900', target: '_blank' }}
              >
                {line}
                <br />
              </Linkify>
            ))}
          </div>
        </TruncateMarkup>
      ) : (
        <div className="font-heading">
          {lines.map((line: string, idx) => (
            <Linkify
              key={idx}
              options={{ className: 'text-cyan-900', target: '_blank' }}
            >
              {line}
              <br />
            </Linkify>
          ))}
          <ShowButton />
        </div>
      );
    }

    // On desktop, we make scrollable and cap the div at 336px which is line height of 24px * 14 lines.
    return (
      <div className="max-h-[336px] overflow-auto font-heading">
        {lines.map((line: string, idx) => (
          <div key={idx}>
            {line}
            <br />
          </div>
        ))}
      </div>
    );
  };

  const openCommentModal = () => {
    if (videoRef.current) {
      videoRef.current.getInternalPlayer()?.pause();
    }

    window.parent.postMessage(
      ['openModal', `contributions/${contribution.id}/comments`],
      '*',
    );
  };

  return (
    <div
      className={classNames(
        'w-full break-inside-avoid-column rounded-2xl border border-[#FFC612] bg-white p-3 shadow-sm',
        className,
      )}
      {...rest}
    >
      <div className="mb-2 h-6 w-full">
        <BsPinAngleFill className="float-right" size={22} />
      </div>

      {contribution.mediaType === ContributionMediaType.Image &&
        contribution.blurHash && (
          <RemoteImage
            className="max-w-1/3 rounded-xl"
            src={contribution.url!}
            hash={contribution.blurHash}
            style={{
              minHeight: parentWindowHeight * 0.3,
              maxHeight: containerHeight,
            }}
          />
        )}

      {contribution.mediaType === ContributionMediaType.Video &&
        contribution.url && (
          <div className="w-full" ref={videoContainerRef}>
            {width > 0 && (
              <ReactPlayer
                ref={videoRef}
                width={'auto'}
                playing
                playsinline
                playIcon={<IoLogoYoutube size={50} color="#e5e5e5" />}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: 15,
                  overflow: 'hidden',
                  backgroundColor: 'black',
                }}
                light={
                  contribution.thumbnailUrl
                    ? contribution.thumbnailUrl
                        .replace(/width=\d+/, `width=${Math.round(width)}`)
                        .replace(/height=\d+/, `height=${Math.round(height)}`)
                    : true
                }
                url={contribution.url}
                controls
              />
            )}
          </div>
        )}

      {contribution.mediaType !== ContributionMediaType.Text && (
        <Markdown
          className="font-heading"
          components={{
            p(props) {
              return (
                <p className="overflow-hidden text-ellipsis">
                  {props.children}
                </p>
              );
            },
          }}
        >
          {contribution.caption || contribution.title}
        </Markdown>
      )}

      {contribution.mediaType === ContributionMediaType.Text && <Text />}

      {contribution.id !== '1' && (
        <div className="mt-4 flex justify-between">
          <div
            className="border-1 flex h-[38px] cursor-pointer items-center space-x-2 rounded-[100px] border border-[#E2E2E2] bg-[#FAFAFA] px-6 py-[10px] text-[#282828]"
            onClick={openCommentModal}
          >
            <BsChat size={20} />{' '}
            <span>{contribution.commentsV2.totalCount}</span>
          </div>

          <button onClick={openCommentModal}>
            <div className="border-1 flex h-[38px] items-center space-x-2 rounded-[100px] border border-[#E2E2E2] bg-[#FAFAFA] px-4 py-[10px] text-[#282828]">
              <FiSend size={20} />
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default Contribution;

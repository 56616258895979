import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { usePurchase } from '../contexts/purchase.tsx';
import StripeForm from './StripeForm.tsx';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

export default function PurchasePaymentForm() {
  const { paymentIntentClientSecret } = usePurchase();

  return (
    <>
      <p className="font-heading text-[22px]">Payment</p>
      <Elements
        options={{
          clientSecret: paymentIntentClientSecret,
        }}
        stripe={stripePromise}
      >
        <StripeForm />
      </Elements>
    </>
  );
}

import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

export type CardProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const ContributeCard = ({ className, ...rest }: CardProps) => (
  <div
    className={classNames(
      'rounded-3xl bg-white px-6 py-4 shadow-[0_0_3px_0_rgba(0,0,0,0.35)]',
      className,
    )}
    {...rest}
  />
);

export default ContributeCard;

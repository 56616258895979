import classNames from 'classnames';

import LoadingFlower from '../../assets/loading-flower.png';

type LoadingFlowersProps = {
  className?: string;
};

const LoadingFlowers = ({ className }: LoadingFlowersProps) => (
  <div
    className={classNames(
      'flex flex-row items-center justify-around',
      className,
    )}
  >
    <img
      src={LoadingFlower}
      className="h-8 w-8 animate-[flower0_2s_ease-in-out_infinite]"
    />
    <img
      src={LoadingFlower}
      className="h-14 w-14 animate-[flower1_2s_ease-in-out_infinite]"
    />
    <img
      src={LoadingFlower}
      className="h-10 w-10 animate-[flower2_2s_ease-in-out_infinite]"
    />
    <img
      src={LoadingFlower}
      className="h-8 w-8 animate-[flower3_2s_ease-in-out_infinite]"
    />
    <img
      src={LoadingFlower}
      className="h-10 w-10 animate-[flower4_2s_ease-in-out_infinite]"
    />
    <img
      src={LoadingFlower}
      className="h-12 w-12 animate-[flower5_2s_ease-in-out_infinite]"
    />
  </div>
);

export default LoadingFlowers;

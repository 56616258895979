import { useGoogleLogin as useGoogleLoginOAuth } from '@react-oauth/google';
import { useCallback, useState } from 'react';

import { AuthProvider } from '../gql/generated';

type Profile = {
  email: string;
  externalId: string;
  firstName: string;
  lastName: string;
  pictureUrl?: string;
  provider: AuthProvider.Google;
};

type UseGoogleLoginProps = {
  onSuccess?: (profile: Profile) => void;
  onError?: (error: Error) => void;
};

const useGoogleLogin = (props?: UseGoogleLoginProps) => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const googleLogin = useGoogleLoginOAuth({
    scope: 'profile email',
    flow: 'implicit',
    //    ux_mode: 'popup',
    //    redirect_uri: 'https://platform.chptr.com/integrations/google',
    onSuccess: async (response) => {
      try {
        console.log('google login success', response);

        const profileResponse = await fetch(
          'https://www.googleapis.com/userinfo/v2/me',
          {
            headers: { Authorization: `Bearer ${response.access_token}` },
          },
        );

        const textResponse = await profileResponse.text();

        const profile = JSON.parse(textResponse);

        if (profile.error) {
          console.error(profile.error);
          const err = new Error(profile.error.message);
          setError(err);
          props?.onError?.(err);
        } else {
          const result: Profile = {
            email: profile.email,
            externalId: profile.id,
            firstName: profile.given_name,
            lastName: profile.family_name || 'N/A',
            pictureUrl: profile.picture || undefined,
            provider: AuthProvider.Google,
          };

          setProfile(result);
          props?.onSuccess?.(result);
        }
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      setError(new Error(error.error_description));
      setLoading(false);
    },
  });

  const login = useCallback(async () => {
    setLoading(true);

    await googleLogin();
  }, [googleLogin]);

  return {
    login,
    loading,
    profile,
    error,
  };
};

export default useGoogleLogin;

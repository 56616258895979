import classNames from 'classnames';

import LoadingFlower from '../../assets/loading-flower.png';

type ChptrPlaceholderProfileImageProps = {
  className?: string;
  iconWrapperClassName?: string;
};

const ChptrPlaceholderProfileImage = ({
  className,
  iconWrapperClassName,
}: ChptrPlaceholderProfileImageProps) => (
  <div
    className={classNames(
      'relative flex flex-col items-center justify-center bg-[#f7f7f3]',
      className,
    )}
  >
    {/* <div className="absolute top-6 flex gap-2"> */}
    {/*   <p className="text-md font-heading">Powered by</p> */}
    {/*   <img src={chptLogo} className="w-22 h-6"></img> */}
    {/* </div> */}
    <div
      className={classNames(
        'flex flex-row items-center justify-around space-x-2',
        iconWrapperClassName,
      )}
    >
      <img src={LoadingFlower} className="h-8 w-8" />
      <img src={LoadingFlower} className="h-14 w-14" />
      <img src={LoadingFlower} className="h-10 w-10" />
      <img src={LoadingFlower} className="h-8 w-8" />
      <img src={LoadingFlower} className="h-10 w-10" />
      <img src={LoadingFlower} className="h-12 w-12" />
    </div>
  </div>
);

export default ChptrPlaceholderProfileImage;

export const ChptrPlaceholderProfileImageSingle = ({
  className,
  iconWrapperClassName,
}: ChptrPlaceholderProfileImageProps) => (
  <div
    className={classNames(
      'flex flex-col items-center justify-center bg-[#f7f7f3]',
      className,
    )}
  >
    <div
      className={classNames(
        'flex flex-row items-center justify-around',
        iconWrapperClassName,
      )}
    >
      <img src={LoadingFlower} className="h-14 w-14" />
    </div>
  </div>
);

import classNames from 'classnames';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAnalytics } from '../contexts/analytics.tsx';
import { ChptrType, usePlacementQuery } from '../gql/generated.tsx';
import { DEFAULT_CONFIG, DEFAULT_CURRENCY } from '../modules/Constants.ts';
import PurchasePackageCard from './PurchasePackageCard.tsx';

export default function PurchasePackage() {
  const { placementId } = useParams<{ placementId: string }>();

  const { loaded: analyticsLoaded, segmentTrack } = useAnalytics();

  useEffect(() => {
    if (analyticsLoaded) {
      segmentTrack('Viewed Enterprise —- Packages');
    }
  }, [analyticsLoaded, segmentTrack]);

  const { data } = usePlacementQuery({
    variables: { placementId: placementId ?? '' },
  });

  const currency = data?.placement?.property.currency ?? DEFAULT_CURRENCY;

  const config = data?.placement?.configuration;

  const packages = data?.placement?.property.products || [];

  return (
    <div
      className={classNames('space-y-6 pb-8', {
        'md:flex md:flex-col md:items-center': packages.length === 3,
      })}
    >
      <p className="font-heading text-[22px] font-normal leading-[30px]">
        Select Package
      </p>
      <p className="font-sans text-[20px] font-light leading-[28px]">
        {config?.packageSubText ??
          DEFAULT_CONFIG.PACKAGE_SUB_TEXT[
            data?.placement?.chptrType ?? ChptrType.Memorial
          ]}
      </p>
      <div
        className={classNames(
          'grid grid-cols-1 gap-3 align-top sm:auto-cols-fr sm:grid-flow-col',
          { 'md:w-[800px]': packages.length === 3 },
        )}
      >
        {packages.map((product) => (
          <PurchasePackageCard
            key={product.id}
            id={product.id}
            title={product.name}
            description={product.description ?? ''}
            amount={product.amount}
            recommended={product.metadata.recommended}
            mediaUrl={product.mediaUrl}
            mediaType={product.mediaType}
            currency={currency}
          />
        ))}
      </div>
    </div>
  );
}

import classNames from 'classnames';
import { useRef, useState } from 'react';
import { FaCircle, FaRegCircle } from 'react-icons/fa';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { Carousel } from 'react-responsive-carousel';

import { ChptrType, ChptrVisibility } from '../../gql/generated';
import ContributeCard from './ContributeCard';

export type PromptCarouselProps = {
  chptr: {
    firstName: string;
    visibility: ChptrVisibility;
    type: ChptrType;
  };
  prompts: string[];
  gradient: {
    from: string;
    to: string;
  };
  textClassName?: string;
};

const PromptCarousel = ({
  chptr,
  prompts,
  gradient,
  textClassName,
}: PromptCarouselProps) => {
  const [currentPrompt, setCurrentPrompt] = useState(0);

  const carouselRef = useRef<Carousel>(null);

  return (
    <ContributeCard
      style={{
        backgroundImage: `linear-gradient(to right, ${gradient.from}, ${gradient.to})`,
      }}
    >
      <p className="font-heading text-base text-neutral-500">Add your memory</p>

      <Carousel
        ref={carouselRef}
        infiniteLoop
        onChange={setCurrentPrompt}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        showThumbs={false}
        renderItem={(node) => (
          <div
            className={classNames(
              'mb-6 mt-1 text-left font-heading leading-tight md:text-3xl',
              textClassName,
            )}
          >
            {node}
          </div>
        )}
      >
        {prompts.map((text) => (
          <p key={text}>{text.replace('{firstName}', chptr.firstName)}</p>
        ))}
      </Carousel>

      <div className="flex flex-row items-center justify-between">
        <FiArrowLeft
          className="cursor-pointer text-neutral-800"
          size={20}
          onClick={() => {
            if (carouselRef.current) {
              carouselRef.current.onClickPrev();
            }
          }}
        />
        <div className="flex flex-row">
          {prompts.map((text, idx) => {
            const Component = idx === currentPrompt ? FaCircle : FaRegCircle;

            return (
              <Component
                size={8}
                className="mr-1  text-neutral-800"
                key={text}
              />
            );
          })}
        </div>
        <FiArrowRight
          className="cursor-pointer text-neutral-800"
          size={20}
          onClick={() => {
            if (carouselRef.current) {
              carouselRef.current.onClickNext();
            }
          }}
        />
      </div>
    </ContributeCard>
  );
};

export default PromptCarousel;

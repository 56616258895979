import { zodResolver } from '@hookform/resolvers/zod';
import { compareAsc, isValid, parseISO } from 'date-fns';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

import { useAnalytics } from '../contexts/analytics.tsx';
import { usePurchase } from '../contexts/purchase.tsx';
import { useUser } from '../contexts/user.tsx';
import {
  ChptrType,
  usePlacementQuery,
  useUpdateChptrMutation,
} from '../gql/generated.tsx';
import { splitDate } from '../lib/utils.ts';
import { DEFAULT_CONFIG } from '../modules/Constants.ts';
import AffiliateInput from './AffiliateInput.tsx';
import Button from './ui/Button.tsx';
import FormImageFileInput from './ui/FormImageFileInput.tsx';
import FormInput from './ui/FormInput.tsx';
import FormPhoneInput from './ui/FormPhoneInput.tsx';

const PurchaseDetailsForm = () => {
  const { placementId } = useParams<{ placementId: string }>();

  const { loaded: analyticsLoaded, segmentTrack } = useAnalytics();

  const { data, loading } = usePlacementQuery({
    variables: { placementId: placementId ?? '' },
  });
  const type = data?.placement?.chptrType;

  useEffect(() => {
    if (analyticsLoaded) {
      segmentTrack('Viewed Enterprise —- Create Chptr');
    }
  }, [analyticsLoaded, segmentTrack]);

  const config = data?.placement?.configuration;

  return (
    !loading && (
      <div className="space-y-6 rounded-[10px] border-[1px] border-[#E2E2E2] px-4 pt-6">
        <p className="font-heading text-[22px] leading-[28px]">
          Basic Information
        </p>
        <p className="text-[17px] leading-[22px] text-[#3F3F3F]">
          {config?.chptrCreateText ??
            DEFAULT_CONFIG.CHPTR_CREATE_TEXT[
              data?.placement?.chptrType ?? ChptrType.Memorial
            ]}
        </p>
        {type === ChptrType.Wedding ? (
          <WeddingForm />
        ) : type === ChptrType.Celebration ? (
          <CelebrationForm />
        ) : (
          <MemorialForm />
        )}
      </div>
    )
  );
};

const MemorialMainSignupSchema = z
  .object({
    picture: z.instanceof(File).optional(),
    firstName: z.string().min(1, { message: 'Required' }),
    lastName: z.string().min(1, { message: 'Required' }),
    location: z.string().min(1, { message: 'Required' }),
    dateOfBirth: z
      .string()
      .min(1, { message: 'Required' })
      .regex(/\d{4}-\d{1,2}-\d{1,2}/, {
        message: 'Invalid format, must be MM-DD-YYYY',
      })
      .refine(
        (value) => {
          const parsedDate = parseISO(value);
          return isValid(parsedDate) && parsedDate.getFullYear() > 1111; // 1111 is a backend requirement
        },
        { message: 'Invalid Date' },
      ),
    dateOfDeath: z.union([
      z
        .string()
        .regex(/\d{4}-\d{1,2}-\d{1,2}/, {
          message: 'Invalid format, must be MM-DD-YYYY',
        })
        .refine(
          (value) => {
            const parsedDate = parseISO(value);
            return isValid(parsedDate) && parsedDate.getFullYear() > 1111; // 1111 is a backend requirement
          },
          { message: 'Invalid Date' },
        )
        .refine(
          (value) => {
            const parsedDate = parseISO(value);
            return compareAsc(parsedDate, new Date()) <= 0;
          },
          { message: 'Date of death can not be in the future' },
        ),
      z.string().max(0),
    ]),
    funeralHome: z.boolean().optional(),
    propertyId: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (
      data.dateOfDeath &&
      compareAsc(data.dateOfBirth, data.dateOfDeath) >= 0
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Date of death can not be before the date of birth',
        path: ['dateOfDeath'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Date of birth can not be after the date of death',
        path: ['dateOfBirth'],
      });
    }
    if (data.funeralHome) {
      if (!data.propertyId) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Please indicate your organization - if your organization isn't listed, select Other",
          path: ['propertyId'],
        });
      }
    }
  });

const RepGroupsSchema = z.array(
  z.object({
    name: z.string().optional(),
    email: z.union([z.literal(''), z.string().email()]).optional(),
    phone: z
      .union([
        z.literal(''),
        z.string().refine((value) => isValidPhoneNumber(value), {
          message: 'Invalid Phone Number',
        }),
      ])
      .optional(),
  }),
);

const MemorialSignupSchema = z.object({
  mainChptrSignup: MemorialMainSignupSchema,
  repGroups: RepGroupsSchema,
});

export type MemorialSignupSchemaType = z.infer<typeof MemorialSignupSchema>;

const MemorialForm = () => {
  const { show, submittedDetails, saveDetails, createdChptrId } = usePurchase();

  const [user] = useUser();

  const { segmentTrack } = useAnalytics();

  const [loading, setLoading] = useState<boolean>(false);

  const { showBoundary } = useErrorBoundary();

  const methods = useForm<MemorialSignupSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(MemorialSignupSchema),
    defaultValues: submittedDetails,
  });

  const { fields, append } = useFieldArray({
    control: methods.control,
    name: 'repGroups',
  });

  useEffect(() => {
    if (!submittedDetails?.repGroups) {
      append({ name: '', email: '', phone: '' });
    }
  }, [append, submittedDetails?.repGroups]);

  const formValues = methods.watch();

  const [updateChptr] = useUpdateChptrMutation();

  const onSubmit: SubmitHandler<MemorialSignupSchemaType> = async (data) => {
    setLoading(true);

    const cleanReps = data.repGroups
      ? data.repGroups.filter(
          (rep) =>
            rep.name?.trim() !== '' ||
            rep.email?.trim() !== '' ||
            rep.phone?.trim() !== '',
        )
      : [];

    if (createdChptrId) {
      try {
        await updateChptr({
          variables: {
            input: {
              id: createdChptrId,
              picture: data.mainChptrSignup.picture,
              firstName: data.mainChptrSignup.firstName,
              lastName: data.mainChptrSignup.lastName,
              bornAt: splitDate(data.mainChptrSignup.dateOfBirth),
              passedAt: data.mainChptrSignup.dateOfDeath
                ? splitDate(data.mainChptrSignup.dateOfDeath)
                : undefined,
              location: data.mainChptrSignup.location,
              propertyId:
                data.mainChptrSignup.propertyId === 'other'
                  ? null
                  : data.mainChptrSignup.propertyId,
              reps: cleanReps,
            },
          },
        });
      } catch (e) {
        return showBoundary(e);
      }
    }

    saveDetails(data);
    setLoading(false);

    if (!user?.token || user.isContributionUser) {
      return show('REGISTER');
    } else {
      show('CHECKOUT');
    }
  };

  const isTrackField = (input: HTMLInputElement) =>
    !methods.getFieldState(input.name as keyof MemorialSignupSchemaType)
      .invalid &&
    methods.getFieldState(input.name as keyof MemorialSignupSchemaType).isDirty;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="space-y-6 pb-6 text-[#3F3F3F]"
      >
        <div className="space-y-2">
          <FormImageFileInput
            name="mainChptrSignup.picture"
            label="Add Photo"
            fileTypeLabel="Please use a .png or .jpeg file"
          />
        </div>
        <div className="grid gap-4 md:grid-cols-2">
          <FormInput
            label="First Name*"
            name="mainChptrSignup.firstName"
            onBlur={(e) =>
              e.currentTarget.value
                ? segmentTrack('Chptr First Name Added', {
                    chptrType: ChptrType.Memorial,
                  })
                : null
            }
          />
          <FormInput
            label="Last Name*"
            name="mainChptrSignup.lastName"
            onBlur={(e) =>
              e.currentTarget.value
                ? segmentTrack('Chptr Last Name Added', {
                    chptrType: ChptrType.Memorial,
                  })
                : null
            }
          />
          <div className="col-span-1 grid grid-cols-2 gap-2 sm:col-span-2 sm:grid-cols-1 md:grid-cols-2">
            <FormInput
              type="date"
              label="Date of Birth*"
              name="mainChptrSignup.dateOfBirth"
              min="1111-01-01"
              max="2100-12-31"
              onBlur={(i) => {
                methods.trigger([
                  'mainChptrSignup.dateOfBirth',
                  'mainChptrSignup.dateOfDeath',
                ]);
                if (isTrackField(i.target)) {
                  segmentTrack('Chptr DOB Added', {
                    chptrType: ChptrType.Memorial,
                  });
                }
              }}
            />
            <FormInput
              type="date"
              label="Date of Death"
              name="mainChptrSignup.dateOfDeath"
              max="2100-12-31"
              onBlur={(i) => {
                methods.trigger([
                  'mainChptrSignup.dateOfBirth',
                  'mainChptrSignup.dateOfDeath',
                ]);
                if (isTrackField(i.target)) {
                  segmentTrack('Chptr DOP Added', {
                    chptrType: ChptrType.Memorial,
                  });
                }
              }}
            />
          </div>
          <FormInput
            label="Location*"
            name="mainChptrSignup.location"
            onBlur={(e) =>
              e.currentTarget.value
                ? segmentTrack('Chptr Location Added', {
                    chptrType: ChptrType.Memorial,
                  })
                : null
            }
          />
        </div>
        <div className="space-y-2 rounded-lg border border-[#D9D9D9] p-4">
          <p className="font-heading text-[20px] font-medium text-[#282828]">
            Funeral Home Details
          </p>
          <div>
            <input
              type="checkbox"
              id="funeralHome"
              name="mainChptrSignup.funeralHome"
              className="mr-2 h-4 w-4"
              checked={formValues.mainChptrSignup?.funeralHome || false}
              onChange={(e) => {
                methods.setValue(
                  'mainChptrSignup.funeralHome',
                  e.currentTarget.checked,
                  {
                    shouldValidate: true,
                  },
                );
              }}
            ></input>
            <label
              htmlFor="funeralHome"
              className="text-[17px] font-medium leading-[22px] text-[#3F3F3F]"
            >
              Funeral Home
            </label>
            <p className="text-[16px] font-normal leading-[22px] text-[#3F3F3F]">
              I’m a funeral home creating this on another’s behalf.
            </p>
          </div>
          {formValues.mainChptrSignup?.funeralHome && (
            <div className="space-y-4 pt-4">
              <p className="text-[16px] font-normal leading-[20px] text-[#3F3F3F]">
                Select the affiliated funeral home.
              </p>
              <AffiliateInput
                name="mainChptrSignup.propertyId"
                value={formValues.mainChptrSignup?.propertyId}
                error={
                  methods.getFieldState('mainChptrSignup.propertyId').error
                    ?.message
                }
              ></AffiliateInput>
              <p className="text-[16px] font-normal leading-[20px] text-[#3F3F3F]">
                Please provide the name, email, and phone of a point of contact.
                This is how we contact the family about their listings and
                videos.
              </p>
              {fields.map((_, index) => (
                <div key={index} className="space-y-4 pb-4">
                  <FormInput
                    placeholder="Name"
                    name={`repGroups.${index}.name`}
                    onBlur={(e) =>
                      e.currentTarget.value
                        ? segmentTrack('Chptr RepName Added', {
                            chptrType: ChptrType.Memorial,
                          })
                        : null
                    }
                  />
                  <FormInput
                    placeholder="Email"
                    name={`repGroups.${index}.email`}
                    onBlur={(e) =>
                      e.currentTarget.value
                        ? segmentTrack('Chptr repEmail Added', {
                            chptrType: ChptrType.Memorial,
                          })
                        : null
                    }
                  />
                  <FormPhoneInput
                    name={`repGroups.${index}.phone`}
                    placeholder="Phone"
                  />
                </div>
              ))}
              {fields.length < 5 && (
                <p
                  onClick={() => append({ name: '', email: '', phone: '' })}
                  className="w-fit cursor-pointer text-base underline"
                >
                  Add new contact person...
                </p>
              )}
            </div>
          )}
        </div>
        <div className="text-center">
          <Button
            className="w-[250px] text-[19px]"
            loading={loading}
            disabled={loading}
            variant="primary"
            type="submit"
          >
            Continue
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

const CelebrationMainSignupSchema = z.object({
  picture: z.instanceof(File).optional(),
  firstName: z.string().min(1, { message: 'Required' }),
  lastName: z.string().min(1, { message: 'Required' }),
  location: z.string().min(1, { message: 'Required' }),
  dateOfBirth: z
    .string()
    .min(1, { message: 'Required' })
    .regex(/\d{4}-\d{1,2}-\d{1,2}/, {
      message: 'Invalid format, must be MM-DD-YYYY',
    })
    .refine(
      (value) => {
        const parsedDate = parseISO(value);
        return isValid(parsedDate) && parsedDate.getFullYear() > 1111; // 1111 is a backend requirement
      },
      { message: 'Invalid Date' },
    ),
});

const CelebrationSignupSchema = z.object({
  mainChptrSignup: CelebrationMainSignupSchema,
});

export type CelebrationSignupSchemaType = z.infer<
  typeof CelebrationSignupSchema
>;

const CelebrationForm = () => {
  const { show, submittedDetails, saveDetails, createdChptrId } = usePurchase();

  const [user] = useUser();

  const { segmentTrack } = useAnalytics();

  const [loading, setLoading] = useState<boolean>(false);

  const { showBoundary } = useErrorBoundary();

  const methods = useForm<CelebrationSignupSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(CelebrationSignupSchema),
    defaultValues: submittedDetails,
  });

  const [updateChptr] = useUpdateChptrMutation();

  const onSubmit: SubmitHandler<CelebrationSignupSchemaType> = async (data) => {
    setLoading(true);

    if (createdChptrId) {
      try {
        await updateChptr({
          variables: {
            input: {
              id: createdChptrId,
              picture: data.mainChptrSignup.picture,
              firstName: data.mainChptrSignup.firstName,
              lastName: data.mainChptrSignup.lastName,
              bornAt: splitDate(data.mainChptrSignup.dateOfBirth),
              location: data.mainChptrSignup.location,
            },
          },
        });
      } catch (e) {
        return showBoundary(e);
      }
    }

    saveDetails(data);
    setLoading(false);
    if (!user?.token || user.isContributionUser) {
      return show('REGISTER');
    } else {
      show('CHECKOUT');
    }
  };

  const isTrackField = (input: HTMLInputElement) =>
    !methods.getFieldState(input.name as keyof CelebrationSignupSchemaType)
      .invalid &&
    methods.getFieldState(input.name as keyof CelebrationSignupSchemaType)
      .isDirty;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="space-y-6 pb-6 text-[#3F3F3F]"
      >
        <div className="space-y-2">
          <FormImageFileInput
            name="mainChptrSignup.picture"
            label="Add Photo*"
          />
        </div>
        <div className="grid gap-4 md:grid-cols-2">
          <FormInput
            label="First Name*"
            name="mainChptrSignup.firstName"
            onBlur={(e) =>
              e.currentTarget.value
                ? segmentTrack('Chptr First Name Added', {
                    chptrType: ChptrType.Celebration,
                  })
                : null
            }
          />
          <FormInput
            label="Last Name*"
            name="mainChptrSignup.lastName"
            onBlur={(e) =>
              e.currentTarget.value
                ? segmentTrack('Chptr Last Name Added', {
                    chptrType: ChptrType.Celebration,
                  })
                : null
            }
          />
          <div className="col-span-1 grid grid-cols-1 gap-2 sm:col-span-2 md:grid-cols-2">
            <FormInput
              type="date"
              label="Date of Celebration*"
              name="mainChptrSignup.dateOfBirth"
              min="1111-01-01"
              max="2100-12-31"
              onBlur={(i) => {
                methods.trigger(['mainChptrSignup.dateOfBirth']);
                if (isTrackField(i.target)) {
                  segmentTrack('Celebration Date Added', {
                    chptrType: ChptrType.Celebration,
                  });
                }
              }}
            />
            <FormInput
              label="Location*"
              name="mainChptrSignup.location"
              onBlur={(e) =>
                e.currentTarget.value
                  ? segmentTrack('Chptr Location Added', {
                      chptrType: ChptrType.Memorial,
                    })
                  : null
              }
            />
          </div>
        </div>
        <div className="text-center">
          <Button
            className="w-[250px] text-[19px]"
            loading={loading}
            disabled={!methods.formState.isValid || loading}
            variant="primary"
            type="submit"
          >
            Continue
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

const WeddingMainSignupSchema = z
  .object({
    picture: z.instanceof(File).optional(),
    firstName: z.string().min(1, { message: 'Required' }),
    lastName: z.string().min(1, { message: 'Required' }),
    location: z.string().min(1, { message: 'Required' }),
    dateOfBirth: z
      .string()
      .min(1, { message: 'Required' })
      .regex(/\d{4}-\d{1,2}-\d{1,2}/, {
        message: 'Invalid format, must be MM-DD-YYYY',
      })
      .refine(
        (value) => {
          const parsedDate = parseISO(value);
          return isValid(parsedDate) && parsedDate.getFullYear() > 1111; // 1111 is a backend requirement
        },
        { message: 'Invalid Date' },
      ),
    funeralHome: z.boolean().optional(),
    propertyId: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.funeralHome) {
      if (!data.propertyId) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required',
          path: ['propertyId'],
        });
      }
    }
  });

const WeddingSignupSchema = z.object({
  mainChptrSignup: WeddingMainSignupSchema,
  repGroups: RepGroupsSchema,
});

export type WeddingSignupSchemaType = z.infer<typeof WeddingSignupSchema>;

const WeddingForm = () => {
  const { show, submittedDetails, saveDetails, createdChptrId } = usePurchase();

  const [user] = useUser();

  const { segmentTrack } = useAnalytics();

  const [loading, setLoading] = useState<boolean>(false);

  const { showBoundary } = useErrorBoundary();

  const methods = useForm<WeddingSignupSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(WeddingSignupSchema),
    defaultValues: submittedDetails,
  });

  const { fields, append } = useFieldArray({
    control: methods.control,
    name: 'repGroups',
  });

  useEffect(() => {
    if (!submittedDetails?.repGroups) {
      append({ name: '', email: '', phone: '' });
    }
  }, [append, submittedDetails?.repGroups]);

  const formValues = methods.watch();

  const [updateChptr] = useUpdateChptrMutation();

  const onSubmit: SubmitHandler<WeddingSignupSchemaType> = async (data) => {
    setLoading(true);

    const cleanReps = data.repGroups
      ? data.repGroups.filter(
          (rep) =>
            rep.name?.trim() !== '' ||
            rep.email?.trim() !== '' ||
            rep.phone?.trim() !== '',
        )
      : [];

    if (createdChptrId) {
      try {
        await updateChptr({
          variables: {
            input: {
              id: createdChptrId,
              picture: data.mainChptrSignup.picture,
              firstName: data.mainChptrSignup.firstName,
              lastName: data.mainChptrSignup.lastName,
              bornAt: splitDate(data.mainChptrSignup.dateOfBirth),
              location: data.mainChptrSignup.location,
              propertyId:
                data.mainChptrSignup.propertyId === 'other'
                  ? null
                  : data.mainChptrSignup.propertyId,
              reps: cleanReps,
            },
          },
        });
      } catch (e) {
        return showBoundary(e);
      }
    }

    saveDetails(data);
    setLoading(false);
    if (!user?.token || user.isContributionUser) {
      return show('REGISTER');
    } else {
      show('CHECKOUT');
    }
  };

  const isTrackField = (input: HTMLInputElement) =>
    !methods.getFieldState(input.name as keyof WeddingSignupSchemaType)
      .invalid &&
    methods.getFieldState(input.name as keyof WeddingSignupSchemaType).isDirty;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="space-y-6 pb-6 text-[#3F3F3F]"
      >
        <div className="space-y-2">
          <FormImageFileInput
            name="mainChptrSignup.picture"
            label="Add Photo*"
          />
        </div>
        <div className="grid gap-4 md:grid-cols-2">
          <FormInput
            label="Full Name 1*"
            name="mainChptrSignup.firstName"
            onBlur={(e) =>
              e.currentTarget.value
                ? segmentTrack('First Spouse Entered', {
                    chptrType: ChptrType.Wedding,
                  })
                : null
            }
          />
          <FormInput
            label="Full Name 2*"
            name="mainChptrSignup.lastName"
            onBlur={(e) =>
              e.currentTarget.value
                ? segmentTrack('Second Spouse Entered', {
                    chptrType: ChptrType.Wedding,
                  })
                : null
            }
          />
          <div className="col-span-1 grid grid-cols-1 gap-2 sm:col-span-2 md:grid-cols-2">
            <FormInput
              type="date"
              label="Date of Wedding*"
              name="mainChptrSignup.dateOfBirth"
              min="1111-01-01"
              max="2100-12-31"
              onBlur={(i) => {
                methods.trigger(['mainChptrSignup.dateOfBirth']);
                if (isTrackField(i.target)) {
                  segmentTrack('Wedding Date Added', {
                    chptrType: ChptrType.Wedding,
                  });
                }
              }}
            />
            <FormInput
              label="Location*"
              name="mainChptrSignup.location"
              onBlur={(e) =>
                e.currentTarget.value
                  ? segmentTrack('Chptr Location Added', {
                      chptrType: ChptrType.Memorial,
                    })
                  : null
              }
            />
          </div>
        </div>
        <div className="space-y-2 rounded-lg border border-[#D9D9D9] p-4">
          <p className="font-heading text-[20px] font-medium text-[#282828]">
            Affiliate Details
          </p>
          <div>
            <input
              type="checkbox"
              id="funeralHome"
              name="mainChptrSignup.funeralHome"
              className="mr-2 h-4 w-4"
              onChange={(e) => {
                methods.setValue(
                  'mainChptrSignup.funeralHome',
                  e.currentTarget.checked,
                  {
                    shouldValidate: true,
                  },
                );
              }}
            ></input>
            <label
              htmlFor="funeralHome"
              className="text-[17px] font-medium leading-[22px] text-[#3F3F3F]"
            >
              Affiliate Partner
            </label>
            <p className="text-[16px] font-normal leading-[22px] text-[#3F3F3F]">
              I’m an affiliate creating this on another’s behalf.
            </p>
          </div>
          {formValues.mainChptrSignup?.funeralHome && (
            <div className="space-y-4 pt-4">
              <p className="text-[16px] font-normal leading-[20px] text-[#3F3F3F]">
                Select the affiliated partner.
              </p>
              <AffiliateInput
                name="mainChptrSignup.propertyId"
                value={formValues.mainChptrSignup?.propertyId}
              ></AffiliateInput>
              <p className="text-[16px] font-normal leading-[20px] text-[#3F3F3F]">
                Please provide the name, email and phone of a point of contact.
              </p>
              {fields.map((_, index) => (
                <div key={index} className="space-y-4 pb-4">
                  <FormInput
                    placeholder="Name"
                    name={`repGroups.${index}.name`}
                    onBlur={(e) =>
                      e.currentTarget.value
                        ? segmentTrack('Chptr RepName Added', {
                            chptrType: ChptrType.Memorial,
                          })
                        : null
                    }
                  />
                  <FormInput
                    placeholder="Email"
                    name={`repGroups.${index}.email`}
                    onBlur={(e) =>
                      e.currentTarget.value
                        ? segmentTrack('Chptr repEmail Added', {
                            chptrType: ChptrType.Memorial,
                          })
                        : null
                    }
                  />
                  <FormPhoneInput
                    name={`repGroups.${index}.phone`}
                    placeholder="Phone"
                  />
                </div>
              ))}
              {fields.length < 5 && (
                <p
                  onClick={() => append({ name: '', email: '', phone: '' })}
                  className="w-fit cursor-pointer text-base underline"
                >
                  Add new contact person...
                </p>
              )}
            </div>
          )}
        </div>
        <div className="text-center">
          <Button
            className="w-[250px] text-[19px]"
            loading={loading}
            disabled={!methods.formState.isValid || loading}
            variant="primary"
            type="submit"
          >
            Continue
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default PurchaseDetailsForm;

import { useEffect } from 'react';

import { usePurchase } from '../contexts/purchase.tsx';
import CheckoutForm from './CheckoutForm.tsx';
import PurchaseAuthenticationForm from './PurchaseAuthenticationForm.tsx';
import PurchaseDetailsForm from './PurchaseDetailsForm.tsx';
import PurchasePackage from './PurchasePackage.tsx';
import PurchasePaymentForm from './PurchasePaymentForm.tsx';

export default function PurchaseChptrDetails() {
  const { screen } = usePurchase();

  useEffect(() => {
    (window.top || window.parent).postMessage(['scrollToTop'], '*');
  }, [screen]);

  return (
    <div className="space-y-6">
      {screen === 'PACKAGE' && <PurchasePackage />}
      {screen === 'DETAILS' && <PurchaseDetailsForm />}
      {screen === 'REGISTER' && (
        <PurchaseAuthenticationForm defaultMode="register" />
      )}
      {screen === 'SIGNIN' && (
        <PurchaseAuthenticationForm defaultMode="login" />
      )}
      {screen === 'CHECKOUT' && <CheckoutForm />}
      {screen === 'PAY' && <PurchasePaymentForm />}
    </div>
  );
}

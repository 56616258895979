import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingFlower from '../assets/loading-flower.png';
import {
  Property,
  PropertyType,
  useGetAffiliatesQuery,
} from '../gql/generated';
import DropDown from './ui/DropDown';

export default function AffiliateInput({
  name,
  value,
  defaultValue,
  disabled,
  error,
}: {
  name?: string;
  value?: string;
  defaultValue?: Partial<Property> | null;
  disabled?: boolean;
  error?: string;
}) {
  const { placementId } = useParams();

  const { data: affiliatesData } = useGetAffiliatesQuery({
    variables: {
      placementId: placementId ?? '',
    },
    fetchPolicy: 'no-cache',
  });

  const affiliates = useMemo(() => {
    const allOptions = [];
    if (
      affiliatesData?.placement?.property.affiliates &&
      affiliatesData.placement.property.type === PropertyType.Primary
    ) {
      allOptions.push(...affiliatesData.placement.property.affiliates);
    } else if (
      affiliatesData?.placement?.property.affiliates &&
      affiliatesData.placement.property.type === PropertyType.Affiliate
    ) {
      allOptions.push({
        id: affiliatesData.placement.property.id,
        name: affiliatesData.placement.property.name,
        imageUrl: affiliatesData.placement.property.imageUrl,
      });
    }
    allOptions.push({ id: 'other', name: 'Other', imageUrl: undefined });
    return allOptions;
  }, [affiliatesData]);

  const [selectedAffiliate, setSelectedAffiliate] = useState<
    Partial<Property> | null | undefined
  >(defaultValue);

  useEffect(() => {
    if (value && !defaultValue) {
      const affiliate = affiliates.find((e) => e.id === value);

      if (affiliate) {
        setSelectedAffiliate(affiliate);
      }
    }
  }, [affiliates, defaultValue, value]);

  return (
    <DropDown
      name={name}
      value={value}
      disabled={disabled}
      error={error}
      listClassName="!mt-0 w-full rounded-b-xl px-4"
      buttonClassName={classNames(
        'flex bg-[#F8F8F8] rounded-t-xl py-2 px-4 text-base border border-[#D9D9D9] text-[#666666] justify-between w-[100%]',
        { 'text-black': selectedAffiliate },
      )}
      buttonLabel={
        selectedAffiliate ? (
          <div className="flex items-center gap-2">
            {selectedAffiliate.imageUrl !== undefined && (
              <img
                className="h-[22px] w-[22px]"
                src={selectedAffiliate.imageUrl ?? LoadingFlower}
              />
            )}
            <span>{selectedAffiliate.name}</span>
          </div>
        ) : (
          'Affiliate'
        )
      }
      elements={affiliates.map((p) => {
        return {
          id: p.id,
          label: (
            <div className="flex items-center gap-2">
              {p.imageUrl !== undefined && (
                <img
                  className="h-[22px] w-[22px]"
                  src={p.imageUrl ?? LoadingFlower}
                />
              )}
              <span>{p.name}</span>
            </div>
          ),
          onClick: () => {
            setSelectedAffiliate(p);
          },
        };
      })}
    ></DropDown>
  );
}

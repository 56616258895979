import { ReactNode, createContext, useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

type Screen =
  | 'DETAILS'
  | 'PACKAGE'
  | 'REGISTER'
  | 'SIGNIN'
  | 'CHECKOUT'
  | 'PAY';

export interface SubmittedPurchaseDetails {
  mainChptrSignup: {
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    dateOfDeath?: string;
    propertyId?: string;
    location?: string;
    picture?: File;
    phone?: string;
  };
  repGroups?: {
    name?: string | undefined;
    email?: string | undefined;
    phone?: string | undefined;
  }[];
}

interface ContextInterface {
  screen: Screen;
  submittedDetails?: SubmittedPurchaseDetails;
  createdChptrId: string | null;
  setCreatedChptrId: (id: string) => void;
  createdOrderId: string | null;
  setCreatedOrderId: (id: string) => void;
  setPaymentIntentClientSecret: (id: string) => void;
  paymentIntentClientSecret?: string;
  selectedPackageId: string | null;
  selectedProducts: string[];
  show: (screen: Screen) => void;
  savePackage: (id: string) => void;
  saveDetails: (details: SubmittedPurchaseDetails) => void;
  goBack: () => void;
}

const PurchaseContext = createContext<ContextInterface | undefined>(undefined);

const usePurchaseStore = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const { placementId } = useParams<{ placementId: string }>();

  const [screen, setScreen] = useState<Screen>('PACKAGE');

  const [selectedPackageId, setSelectedPackageId] = useState<string | null>(
    null,
  );

  const [createdChptrId, setCreatedChptrId] = useState<string | null>(null);

  const [createdOrderId, setCreatedOrderId] = useState<string | null>(null);

  const [submittedDetails, setSubmittedDetails] = useState<
    SubmittedPurchaseDetails | undefined
  >(undefined);

  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState<
    string | undefined
  >(undefined);

  return {
    screen,
    submittedDetails,
    paymentIntentClientSecret,
    setPaymentIntentClientSecret,
    selectedPackageId,
    createdChptrId,
    setCreatedChptrId,
    createdOrderId,
    setCreatedOrderId,
    get selectedProducts() {
      return [...(selectedPackageId ? [selectedPackageId] : [])];
    },
    show: (screen: Screen) => setScreen(screen),
    savePackage: (id: string) => setSelectedPackageId(id),
    saveDetails: (details: SubmittedPurchaseDetails) =>
      setSubmittedDetails(details),
    goBack: () => {
      if (location.state?.redirectBack) {
        return navigate(location.state.redirectBack);
      }

      switch (screen) {
        case 'DETAILS':
          return setScreen('PACKAGE');
        case 'PACKAGE':
          return navigate(`/${placementId}`);
        case 'REGISTER':
          return setScreen('DETAILS');
        case 'SIGNIN':
          return setScreen('DETAILS');
        case 'CHECKOUT':
          return setScreen('DETAILS');
        case 'PAY':
          return setScreen('CHECKOUT');
        default:
          return navigate(`/${placementId}`);
      }
    },
  };
};

export const PurchaseContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => (
  <PurchaseContext.Provider value={usePurchaseStore()}>
    {children}
  </PurchaseContext.Provider>
);

// eslint-disable-next-line react-refresh/only-export-components
export const usePurchase = () => {
  const purchase = useContext(PurchaseContext);

  if (purchase === undefined) {
    throw new Error(
      'usePurchase must be used within a PurchaseContextProvider',
    );
  }
  return purchase;
};

import classnames from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';

export default function Button({
  variant = 'primary',
  loading,
  disabled,
  className,
  children,
  type = 'button',
  ...rest
}: {
  variant?: 'primary' | 'secondary' | 'stroke' | 'blue';
  loading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>) {
  const cn = classnames(
    'inline-flex h-[42px] items-center justify-center rounded-3xl px-[77px] font-heading text-[17px] leading-[22px] transition-colors text-nowrap whitespace-nowrap',
    {
      'bg-[#FFC612] border-2 border-transparent hover:bg-[#FFCF36]':
        variant === 'primary',
    },
    {
      'bg-[#282828] border-2 border-transparent hover:bg-[#5A5A5A] text-white':
        variant === 'secondary',
    },
    {
      'text-black bg-white bg-none border-2 border-[#282828] hover:bg-[#F5F5F5]':
        variant === 'stroke',
    },
    {
      'bg-[#2E5E6B] text-white hover:border hover:border-neutral-500 hover:bg-neutral-100 hover:text-black':
        variant === 'blue',
    },
    { 'opacity-70': disabled },
    className,
  );

  return (
    <button type={type} disabled={disabled} className={cn} {...rest}>
      {loading ? <AiOutlineLoading className="mr-2 animate-spin" /> : children}
    </button>
  );
}

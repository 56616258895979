import { useEffect, useState } from 'react';

const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
  heightOffset: number = 2,
  maxHeight: number = 160,
) => {
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (textAreaRef) {
      // Reset the height momentarily to get the correct scrollHeight
      textAreaRef.style.height = '0px';
      const scrollHeight = textAreaRef.scrollHeight;

      // Set the height directly, outside of the render loop
      // Trying to set this with state or a ref will produce an incorrect value.
      if (scrollHeight + heightOffset >= maxHeight) {
        textAreaRef.style.height = maxHeight + 'px';
      } else {
        textAreaRef.style.height = scrollHeight + heightOffset + 'px';
      }

      setHeight(parseInt(textAreaRef.style.height));

      textAreaRef.scrollTop = scrollHeight + heightOffset;
    }
  }, [textAreaRef, value, maxHeight, heightOffset]);

  return {
    height,
  };
};

export default useAutosizeTextArea;

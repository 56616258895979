import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetPlacementPartnerTermsQuery } from '../gql/generated';

const usePlacementTerms = ({
  mode,
}: {
  mode: 'register' | 'login';
}): {
  hasPartnerTerms: boolean;
  chptrTermsAccepted: boolean;
  setChptrTermsAccepted: Dispatch<SetStateAction<boolean>>;
  partnerTermsAccepted: boolean;
  setPartnerTermsAccepted: Dispatch<SetStateAction<boolean>>;
  validateTerms: (cb: (isValid: boolean) => void) => void;
} => {
  const { placementId } = useParams<{ placementId: string }>();

  if (placementId === undefined) {
    throw new Error('no placement id found');
  }

  const { data } = useGetPlacementPartnerTermsQuery({
    variables: {
      placementId,
    },
  });

  // Originally, we were going to go dynamic with partner terms with the additionalTerms field on partner, but an urgent request came in to just hardcode it for now.

  // The following id is ken's local test id.
  // const hasPartnerTerms =
  //   data?.placement?.partner.id === '6ea30c4d-9e0b-47ce-943f-7715894dfc2d';

  // The following id is sinclair's partner id.
  const hasPartnerTerms =
    data?.placement?.partner?.id === 'e91132c9-7c11-4859-b682-ed587bf34948';

  const [chptrTermsAccepted, setChptrTermsAccepted] = useState<boolean>(false);
  const [partnerTermsAccepted, setPartnerTermsAccepted] =
    useState<boolean>(false);

  const validateTerms = useCallback(
    (cb: (isValid: boolean) => void) => {
      // If the user is logging in, we don't need to check the terms
      if (mode === 'login') {
        return cb(true);
      }

      if (!hasPartnerTerms && !chptrTermsAccepted) {
        cb(false);
        return;
      }

      if (hasPartnerTerms && (!chptrTermsAccepted || !partnerTermsAccepted)) {
        cb(false);
        return;
      }

      cb(true);
    },
    [chptrTermsAccepted, partnerTermsAccepted, hasPartnerTerms, mode],
  );

  return {
    hasPartnerTerms,
    chptrTermsAccepted,
    setChptrTermsAccepted,
    partnerTermsAccepted,
    setPartnerTermsAccepted,
    validateTerms,
  };
};

export default usePlacementTerms;

import { ErrorInfo, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import IntercomMessenger from '../components/IntercomMessenger.ts';
import PurchaseChptrDetails from '../components/PurchaseChptrDetails.tsx';
import PurchaseNavigation from '../components/PurchaseNavigation.tsx';
import Problem from '../components/ui/Problem.tsx';
import { PurchaseContextProvider } from '../contexts/purchase.tsx';
import { useUser } from '../contexts/user.tsx';

const Purchase = () => {
  const logError = (error: Error, info: ErrorInfo) => {
    // TODO log error to sentry
    console.error(error, info.componentStack);
  };
  const [user] = useUser();

  useEffect(() => {
    IntercomMessenger(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      <PurchaseContextProvider>
        <div className="mx-auto max-w-[1200px] px-4 py-[20px]">
          <div className="space-y-6">
            <PurchaseNavigation />
            <PurchaseChptrDetails />
          </div>
        </div>
      </PurchaseContextProvider>
    </ErrorBoundary>
  );
};

const ErrorFallback = () => {
  return (
    <Problem
      title="Something Went Wrong"
      description={
        <p className="text-[#666666]">
          We are sorry, but something went wrong and we were unable to create
          your memorial. If you would like additional assistance, please reach
          out to{' '}
          <a href="mailto:support@chptr.com" className="underline">
            support@chptr.com
          </a>{' '}
          and we will be happy to help.
        </p>
      }
    />
  );
};

export default Purchase;

import classNames from 'classnames';

import IconContributePhoto from '../../assets/icons/contribute-photo.png';
import IconContributeText from '../../assets/icons/contribute-text.png';
import IconContributeVideo from '../../assets/icons/contribute-video.png';

// eslint-disable-next-line react-refresh/only-export-components
export enum ContributionMediaType {
  Image = 'IMAGE',
  Video = 'VIDEO',
  Text = 'TEXT',
}

type ContributeMediaTypeButtonProps = {
  inverted?: boolean;
  type: ContributionMediaType;
  onClick: () => void;
};

const BUTTON_TYPE_MAP: Record<
  ContributionMediaType,
  {
    icon: {
      className?: string;
      src: string;
    };
    text: string;
  }
> = {
  [ContributionMediaType.Image]: {
    icon: {
      src: IconContributePhoto,
      className: 'h-[24px]',
    },
    text: 'Add Photo',
  },
  [ContributionMediaType.Text]: {
    icon: {
      src: IconContributeText,
      className: 'h-[18px]',
    },
    text: 'Write Post',
  },
  [ContributionMediaType.Video]: {
    icon: {
      src: IconContributeVideo,
      className: 'h-[24px]',
    },
    text: 'Add Video',
  },
};

const ContributeMediaTypeButton = ({
  type,
  onClick,
  inverted,
}: ContributeMediaTypeButtonProps) => (
  <button
    className={classNames(
      'group w-full rounded-full border-2 border-[#2E5E6B] p-2 font-heading',
      {
        'bg-white text-[#2E5E6B] hover:bg-[#2E5E6B] hover:text-white':
          !inverted,
        'bg-[#2E5E6B] text-white hover:bg-white hover:text-[#2E5E6B]': inverted,
      },
    )}
    type="button"
    onClick={onClick}
  >
    <div className="flex flex-row items-center justify-center">
      <img
        src={BUTTON_TYPE_MAP[type].icon.src}
        className={classNames(BUTTON_TYPE_MAP[type].icon.className, {
          'group-hover:brightness-0 group-hover:grayscale group-hover:invert':
            !inverted,
          'pb-1 brightness-[800%] saturate-0 group-hover:brightness-100 group-hover:saturate-100':
            inverted,
        })}
      />
      <p className="ml-2 hidden md:block">{BUTTON_TYPE_MAP[type].text}</p>
    </div>
  </button>
);

export default ContributeMediaTypeButton;

import classnames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';

export default function Callout({
  children,
  variant = 'error',
  className,
  ...rest
}: {
  children: ReactNode;
  variant: 'error';
} & HTMLAttributes<HTMLDivElement>) {
  const cn = classnames(
    'inline-block rounded-[10px] border px-4 py-4 md:px-[42px]',
    {
      'border-[#EF341E] bg-[#FAE4E1]': variant === 'error',
    },
    className,
  );

  return (
    <div className="flex justify-center">
      <div className={cn} {...rest}>
        <p className="text-sm text-[#282828] md:text-base">{children}</p>
      </div>
    </div>
  );
}

import { isMobileSafari, isSafari } from 'react-device-detect';

const objectUrls = new Map<string, string>();

const thumbnailFromFile = async (file: File): Promise<string> => {
  const reader = new FileReader();
  const canvas = document.createElement('canvas');

  return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      if (!event.target || !event.target.result) {
        reject(new Error('No result'));
      } else {
        const img = new Image();
        img.onload = function () {
          canvas.width = img.width;
          canvas.height = img.height;
          canvas.getContext('2d')!.drawImage(img, 0, 0);

          resolve(canvas.toDataURL());
        };

        const src =
          typeof event.target.result === 'string'
            ? event.target.result
            : new TextDecoder('utf-8').decode(event.target.result);

        img.src = src;
      }
    };

    reader.readAsDataURL(file);
  });
};

const thumbnailFromVideo = (video: HTMLVideoElement) => {
  const canvas = document.createElement('canvas');

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  canvas.getContext('2d')!.drawImage(video, 0, 0, canvas.width, canvas.height);

  const image = canvas.toDataURL();

  return image;
};

const thumbnailFromObjectURL = async (url: string) =>
  new Promise<string>((resolve) => {
    const snap = () => {
      const result = thumbnailFromVideo(video);

      URL.revokeObjectURL(url);

      resolve(result);
    };

    const video = document.createElement('video');

    const timeupdate = function () {
      video.removeEventListener('timeupdate', timeupdate);
      video.pause();

      snap();
    };

    video.addEventListener('loadeddata', function () {
      video.removeEventListener('timeupdate', timeupdate);

      snap();
    });

    video.addEventListener('timeupdate', timeupdate);
    video.preload = 'metadata';
    video.src = url;
    // Load video in Safari / IE11
    video.muted = true;
    video.playsInline = true;
    video.currentTime = 5;
    video.play();
  });

const ThumbnailFromFile = async (file: File) => {
  if (!isSafari && !isMobileSafari) {
    const objectUrl = URL.createObjectURL(file);

    objectUrls.set(file.name, objectUrl);

    return thumbnailFromObjectURL(objectUrl);
  }

  return thumbnailFromFile(file);
};

export const revokeUrl = (file: File) => {
  const objectUrl = objectUrls.get(file.name);

  if (objectUrl) {
    URL.revokeObjectURL(objectUrl);
  }
};

export const revokeAllUrls = () => {
  objectUrls.forEach((url) => URL.revokeObjectURL(url));
};

export default ThumbnailFromFile;

import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useState } from 'react';

const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;

const useGoogleMaps = (): {
  isAPIReady: boolean;
  fetchPredictions: (
    input: string,
  ) => Promise<google.maps.places.AutocompletePrediction[]>;
  fetchPlace: (placeId: string) => Promise<google.maps.places.PlaceResult>;
  fitMapBounds: (place: google.maps.places.PlaceResult) => void;
  resetSession: () => void;
  getPlaceDetails: (placeId: string) => Promise<google.maps.places.PlaceResult>;
} => {
  const map = useMap();
  const places = useMapsLibrary('places');

  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();

  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);

  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null);

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [map, places]);

  const fetchPredictions = useCallback(
    async (input: string) => {
      if (!autocompleteService || !input) {
        return [];
      }

      const result = await autocompleteService.getPlacePredictions({
        input,
        sessionToken,
      });

      return result.predictions;
    },
    [autocompleteService, sessionToken],
  );

  const fetchPlace = useCallback(
    async (placeId: string): Promise<google.maps.places.PlaceResult> =>
      new Promise((resolve, reject) => {
        if (!places || !placesService) return;

        placesService.getDetails(
          {
            placeId,
            fields: [
              'place_id',
              'geometry',
              'name',
              'formatted_address',
              'address_components',
            ],
            sessionToken,
          },
          (result: google.maps.places.PlaceResult | null) => {
            if (result === null) {
              return reject(null);
            }
            resolve(result);
          },
        );
      }),
    [places, placesService, sessionToken],
  );

  const getPlaceDetails = useCallback(
    async (placeId: string): Promise<google.maps.places.PlaceResult> => {
      const baseUrl = 'https://maps.googleapis.com/maps/api/place/details/json';
      const fields = 'name,rating,formatted_phone_number';
      const url = `${baseUrl}?place_id=${placeId}&fields=${fields}&key=${API_KEY}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        throw new Error(`Error fetching place details: ${error}`);
      }
    },
    [],
  );

  const fitMapBounds = useCallback(
    (place: google.maps.places.PlaceResult) => {
      if (!map || !place?.geometry?.viewport) return;

      map.fitBounds(place.geometry.viewport);
    },
    [map],
  );

  const resetSession = useCallback(() => {
    if (!places) return;

    setSessionToken(new places.AutocompleteSessionToken());
  }, [places]);

  return {
    isAPIReady: !!places && !!map && !!autocompleteService && !!placesService,
    fetchPredictions,
    fetchPlace,
    fitMapBounds,
    resetSession,
    getPlaceDetails,
  };
};

export default useGoogleMaps;

import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { GoLink } from 'react-icons/go';

import useParentUrl from '../../hooks/useParentUrl';

type CopyLinkButtonProps = {
  url: string;
  label?: string;
  className?: string;
};

const CopyLinkButton = ({ url, label, className }: CopyLinkButtonProps) => {
  const [notify, setNotify] = useState(false);

  const onClick = useCallback(() => {
    navigator.clipboard.writeText(url);
    setNotify(true);

    console.log('== Copy', url);

    setTimeout(() => {
      setNotify(false);
    }, 2000);
  }, [url]);

  return (
    <div className={classNames('relative', className)}>
      <button
        className="flex h-9 w-full items-center justify-center gap-1 rounded-3xl border border-neutral-200 bg-neutral-100 p-5 text-sm font-light text-neutral-800 active:bg-neutral-300"
        onClick={onClick}
      >
        <GoLink />
        {label || 'Copy Link'}
      </button>

      {notify && (
        <div className="mt-2 rounded-full bg-[#2E5E6B] p-2 px-4 text-xs text-white">
          Link Copied!
        </div>
      )}
    </div>
  );
};

export default CopyLinkButton;

export const CopyChtprLinkButton = ({
  chptrId,
  ...rest
}: Omit<CopyLinkButtonProps, 'url'> & { chptrId: string }) => {
  const { parentUrl } = useParentUrl();

  let url = `${window.location.origin}?chptrId${chptrId}`;

  if (parentUrl) {
    const parsedUrl = new URL(parentUrl);
    const searchParams = new URLSearchParams(parsedUrl.search);

    searchParams.set('chptrId', chptrId);
    parsedUrl.search = searchParams.toString();

    url = parsedUrl.toString();
  }

  return <CopyLinkButton {...rest} url={url} />;
};

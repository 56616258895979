import { UpChunk } from '@mux/upchunk';
import classNames from 'classnames';
import heic2any from 'heic2any';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import { MdPhotoLibrary } from 'react-icons/md';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Logo from '../assets/logo.png';
import { useAnalytics } from '../contexts/analytics';
import { useApplication } from '../contexts/application';
import { useUser } from '../contexts/user';
import {
  ContributionType,
  useAuthenticateUserMutation,
  useCreateImageContributionsMutation,
  useCreateTextContributionsMutation,
  useCreateVideoContributionUploadsMutation,
  useRegisterContributionUserMutation,
  useUpdateUserMutation,
} from '../gql/generated';
import ThumbnailFromFile, { revokeUrl } from '../modules/ThumbnailFromFile';
import Button from './ui/Button';
import { ContributionMediaType } from './ui/ContributeMediaTypeButton';
import {
  ContributeAccountInformationPayload,
  ContributeContributorInformationPayload,
  ContributeFlowContributorInformationStep,
  ContributeFlowModalHeader,
  ContributeFlowThankYouStep,
} from './ui/ContributeModalStep';
import LoadingFlowers from './ui/LoadingFlowers';
import Modal, { ModalProps } from './ui/Modal';

const MAX_LENGTH_DESCRIPTION = 100;
const MAX_LENGTH_TEXT = 440;

type OnProgressFn = (progress: number) => void;

type ContributeImperativeProps = {
  onSubmit: (chptr: { id: string }) => unknown | Promise<unknown>;
  reset: () => void;
  onProgress?: (fn: OnProgressFn) => void;
};

type BaseContributionModalProps = {
  placementId: string;
  chptr: { id: string };
  onContributeClick: () => unknown | Promise<unknown>;
};

type ImageContributionProps = BaseContributionModalProps & {
  files?: File[];
};

type VideoContributionProps = BaseContributionModalProps & {
  file: File | null;
};

const ImageContribution = forwardRef<
  ContributeImperativeProps,
  ImageContributionProps
>((props, ref) => {
  const { parentWindowHeight } = useApplication();
  const { segmentTrack } = useAnalytics();

  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [objectUrl, setObjectUrl] = useState<string | null | undefined>();
  const [descriptions, setStateDescriptions] = useState<string[]>([]);
  const [index, setIndex] = useState<number>(0);

  const setDescription = useCallback((index: number, description: string) => {
    setStateDescriptions((prevDescriptions) => {
      const newDescriptions = [...prevDescriptions];
      newDescriptions[index] = description.slice(0, MAX_LENGTH_TEXT);
      return newDescriptions;
    });
  }, []);

  const [createContribution] = useCreateImageContributionsMutation();

  const clearFile = useCallback((index: number) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);

      if (index > 0) {
        setIndex(index - 1);
      }

      return newFiles;
    });
    setStateDescriptions((prevDescriptions) => {
      const newDescriptions = [...prevDescriptions];
      newDescriptions.splice(index, 1);
      return newDescriptions;
    });
  }, []);

  const onDrop = useCallback((files: File[]) => {
    setLoading(true);
    setIndex(0);

    Promise.all(
      files.map(async (file) => {
        if (file.name.toLowerCase().endsWith('.heic')) {
          return heic2any({
            blob: file,
            toType: 'image/png',
            quality: 1,
          })
            .then((result) => {
              return new File(
                Array.isArray(result) ? result : [result],
                file.name.replace('.heic', '.png'),
              );
            })
            .catch(() => {
              return null;
            });
        }

        return file;
      }),
    ).then((file) => {
      const populatedFiles = file.filter((f): f is File => f !== null);

      setFiles(populatedFiles);
      setLoading(false);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png', '.heic'],
    },
    multiple: true,
    maxFiles: 10,
  });

  const onSubmit: ContributeImperativeProps['onSubmit'] = useCallback(
    async (chptr) => {
      try {
        await createContribution({
          variables: {
            input: files.map((file, index) => ({
              chptrId: chptr.id,
              caption:
                descriptions[index] && descriptions[index].trim().length > 0
                  ? descriptions[index].trim()
                  : null,
              type: ContributionType.Memory,
              image: file,
              placementId: props.placementId,
            })),
          },
        });

        segmentTrack('Contribution(s) Created', {
          chptrId: chptr.id,
          type: ContributionMediaType.Image,
        });
      } catch (e) {
        throw new Error('Failed to post contribution');
      }
    },
    [createContribution, files, descriptions, props.placementId, segmentTrack],
  );

  const reset: ContributeImperativeProps['reset'] = useCallback(() => {
    setFiles([]);
    setStateDescriptions([]);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit,
      reset,
    }),
    [onSubmit, reset],
  );

  useEffect(() => {
    const file = files[index];

    if (file) {
      try {
        setObjectUrl((prevObjectUrl) => {
          if (prevObjectUrl) {
            URL.revokeObjectURL(prevObjectUrl);
          }

          return URL.createObjectURL(file);
        });
      } catch {
        setObjectUrl(null);
      }
    }
  }, [files, index]);

  const file = files[index];

  return (
    <>
      <div className="max-h-40v">
        {file && (
          <div className="flex flex-row justify-center py-6">
            <div className="relative flex max-w-[90%] flex-row justify-center md:max-w-[66%]">
              {objectUrl && (
                <img
                  src={objectUrl}
                  className="rounded-md"
                  style={{
                    maxHeight: parentWindowHeight * 0.4,
                  }}
                />
              )}

              {!objectUrl && (
                <div className="rounded-lg bg-neutral-300 shadow-md">
                  {file.name}
                </div>
              )}

              <div className="absolute -right-2 -top-2 rounded-full bg-neutral-100 p-0.5">
                <IoMdClose
                  size={18}
                  className="cursor-pointer"
                  onClick={() => clearFile(index)}
                />
              </div>
            </div>
          </div>
        )}

        {!file && (
          <>
            {loading && (
              <div className="mx-auto h-36 w-48 animate-pulse rounded-xl bg-neutral-300" />
            )}

            {!loading && (
              <div
                {...getRootProps({
                  className:
                    'dropzone p-10 my-6 bg-neutral-100 border-dashed border-2 border-neutral-400 rounded-lg flex flex-col items-center',
                })}
              >
                <input {...getInputProps()} />
                <MdPhotoLibrary className="text-neutral-400" size={36} />
                <p className="pt-2 text-neutral-400">
                  Drag and drop media or click to upload. You can submit a
                  single image or folder with up to 10 photos.
                </p>
              </div>
            )}
          </>
        )}
      </div>

      <div className="flex flex-col">
        <p className="font-heading text-lg text-neutral-500">Description</p>
        <div className="relative mb-4">
          <textarea
            onChange={(e) => setDescription(index, e.target.value)}
            className="w-full rounded-lg border border-neutral-400 bg-neutral-100 p-4 text-neutral-700"
            maxLength={MAX_LENGTH_DESCRIPTION}
            rows={3}
            value={descriptions[index] || ''}
          />

          <div className="absolute bottom-4 right-4">
            <p className="text-xs text-neutral-500">
              {(descriptions[index] || '').length}/{MAX_LENGTH_DESCRIPTION}
            </p>
          </div>
        </div>

        {!loading && files.length > 0 && (
          <div className="mb-4 flex items-center justify-center">
            <div className="flex flex-row items-center">
              <Button
                className="!h-fit !px-4 !py-1 disabled:opacity-50"
                variant="stroke"
                disabled={index === 0}
                onClick={() => {
                  if (index > 0) {
                    setIndex(index - 1);
                  }
                }}
              >
                <BsArrowLeft size={20} />
              </Button>
              <p className="px-10 font-heading tracking-[0.2em]">
                {index + 1}/{files?.length}
              </p>
              <Button
                variant="stroke"
                className="!h-fit !px-4 !py-1 disabled:opacity-50"
                disabled={index === files?.length - 1}
                onClick={() => {
                  if (index < files?.length - 1) {
                    setIndex(index + 1);
                  }
                }}
              >
                <BsArrowRight size={20} />
              </Button>
            </div>
          </div>
        )}

        <div className="flex justify-center">
          <button
            disabled={!file}
            onClick={props.onContributeClick}
            className={classNames(
              'mb-2 w-full rounded-full bg-amber-400 p-2 font-heading text-lg hover:opacity-80 md:w-1/2',
              { 'bg-neutral-300': !file },
            )}
          >
            Contribute
          </button>
        </div>
      </div>
    </>
  );
});

const VideoContribution = forwardRef<
  ContributeImperativeProps,
  VideoContributionProps
>((props, ref) => {
  const onProgressRef = useRef<OnProgressFn>();

  const { parentWindowHeight } = useApplication();
  const { segmentTrack } = useAnalytics();

  const [file, setFile] = useState<File | null>(props.file);
  const [description, setStateDescription] = useState<string | null>(null);
  const [thumbnail, setThumbnail] = useState<string | null>(null);

  const setDescription = useCallback((description: string) => {
    setStateDescription(description.slice(0, MAX_LENGTH_TEXT));
  }, []);

  const [createContributionUpload] =
    useCreateVideoContributionUploadsMutation();

  const clearFile = useCallback(() => {
    setFile(null);
    setThumbnail(null);
  }, []);

  const onDrop = useCallback((files: File[]) => {
    setFile(files[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'video/*': [],
    },
    multiple: false,
  });

  const onSubmit: ContributeImperativeProps['onSubmit'] = useCallback(
    async (chptr) => {
      if (!file) {
        throw new Error('No file');
      }

      const uploadResponse = await createContributionUpload({
        variables: {
          input: {
            chptrId: chptr.id,
            title: description && description.length > 0 ? description : null,
            type: ContributionType.Memory,
            placementId: props.placementId,
          },
        },
      });

      if (!uploadResponse.data?.createVideoContributionUploads) {
        throw new Error('Failed to create video contribution upload');
      }

      const uploadUrl =
        uploadResponse.data.createVideoContributionUploads[0].uploadUrl;

      segmentTrack('Contribution Created', {
        chptrId: chptr.id,
        type: ContributionMediaType.Video,
      });

      return new Promise<void>((resolve, reject) => {
        const upload = UpChunk.createUpload({
          endpoint: uploadUrl,
          file,
          dynamicChunkSize: true,
        });

        upload.on('error', (err) => {
          reject(err);
        });

        upload.on('progress', (progress) => {
          if (onProgressRef.current) {
            onProgressRef.current(progress.detail);
          }
        });

        upload.on('success', () => {
          resolve();
        });
      });
    },
    [
      createContributionUpload,
      file,
      description,
      props.placementId,
      segmentTrack,
    ],
  );

  const reset: ContributeImperativeProps['reset'] = useCallback(() => {
    setFile(null);
    setStateDescription(null);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit,
      reset,
      onProgress: (fn) => {
        onProgressRef.current = fn;
      },
    }),
    [onSubmit, reset],
  );

  useEffect(() => {
    if (file) {
      revokeUrl(file);

      ThumbnailFromFile(file).then(setThumbnail);
    }

    return () => {
      if (file) {
        revokeUrl(file);
      }
    };
  }, [file]);

  return (
    <>
      <div className="max-h-40v">
        {file && thumbnail && (
          <div className="flex flex-row justify-center py-6">
            <div className="relative flex max-w-[90%] flex-row justify-center md:max-w-[66%]">
              <img
                src={thumbnail}
                className="rounded-md"
                style={{
                  maxHeight: parentWindowHeight * 0.5,
                }}
              />

              <div className="absolute -right-2 -top-2 rounded-full bg-neutral-100 p-0.5">
                <IoMdClose
                  size={18}
                  className="cursor-pointer"
                  onClick={clearFile}
                />
              </div>
            </div>
          </div>
        )}

        {!file && (
          <div
            {...getRootProps({
              className:
                'dropzone p-10 my-6 bg-neutral-100 border-dashed border-2 border-neutral-400 rounded-lg flex flex-col items-center',
            })}
          >
            <input {...getInputProps()} />
            <MdPhotoLibrary className="text-neutral-400" size={36} />
            <p className="pt-2 text-neutral-400">
              Drag and drop media or click to upload. You can submit a single
              image or folder with up to 10 photos.
            </p>
          </div>
        )}
      </div>

      <div className="flex flex-col">
        <p className="font-heading text-lg text-neutral-500">Description</p>
        <div className="relative mb-4">
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            className="w-full rounded-lg border border-neutral-400 bg-neutral-100 p-4 text-neutral-700"
            maxLength={MAX_LENGTH_DESCRIPTION}
            rows={3}
            value={description || ''}
          />

          <div className="absolute bottom-4 right-4">
            <p className="text-xs text-neutral-500">
              {(description || '').length}/{MAX_LENGTH_DESCRIPTION}
            </p>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            disabled={!file}
            onClick={props.onContributeClick}
            className={classNames(
              'mb-2 w-full rounded-full bg-amber-400 p-2 font-heading text-lg hover:opacity-80 md:w-1/2',
              { 'bg-neutral-300': !file },
            )}
          >
            Contribute
          </button>
        </div>
      </div>
    </>
  );
});

type TextContributionProps = BaseContributionModalProps;

const TextContribution = forwardRef<
  ContributeImperativeProps,
  TextContributionProps
>((props, ref) => {
  const { segmentTrack } = useAnalytics();

  const [description, setDescription] = useState<string>('');

  const [createContribution] = useCreateTextContributionsMutation();

  const onSubmit: ContributeImperativeProps['onSubmit'] = useCallback(
    async (chptr) => {
      try {
        await createContribution({
          variables: {
            input: {
              chptrId: chptr.id,
              caption: description,
              type: ContributionType.Memory,
              placementId: props.placementId,
            },
          },
        });

        segmentTrack('Contribution Created', {
          chptrId: chptr.id,
          type: ContributionMediaType.Text,
        });
      } catch (e) {
        throw new Error('Failed to post contribution');
      }
    },
    [createContribution, description, props.placementId, segmentTrack],
  );

  const reset: ContributeImperativeProps['reset'] = useCallback(() => {
    setDescription('');
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit,
      reset,
    }),
    [onSubmit, reset],
  );

  return (
    <>
      <div className="flex flex-col pt-6">
        <div className="relative mb-4">
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            className="w-full rounded-lg border border-neutral-400 bg-neutral-100 p-4 text-neutral-700"
            rows={6}
            value={description || ''}
          />
        </div>
        <div className="flex justify-center">
          <button
            disabled={description.length === 0}
            onClick={props.onContributeClick}
            className="mb-2 w-full rounded-full bg-amber-400 p-2 font-heading text-lg hover:opacity-80 disabled:bg-neutral-500 disabled:text-neutral-200 disabled:hover:opacity-100 md:w-1/2"
          >
            Contribute
          </button>
        </div>
      </div>
    </>
  );
});

type ContributeModalTypeImage = {
  type: ContributionMediaType.Image;
  file: File | null;
};

type ContributeModalTypeVide = {
  type: ContributionMediaType.Video;
  file: File | null;
};

type ContributeModalTypeText = {
  type: ContributionMediaType.Text;
};

export type ContributeModalType =
  | ContributeModalTypeImage
  | ContributeModalTypeVide
  | ContributeModalTypeText;

type ContributeModalProps = ModalProps & {
  type: ContributeModalType;
  placementId: string;
  chptr: { id: string };
  reset: (type: ContributeModalType) => unknown;
  top?: number;
};

const contributeTypeTitleMap = {
  [ContributionMediaType.Image]: 'Share Photo',
  [ContributionMediaType.Video]: 'Share Video',
  [ContributionMediaType.Text]: 'Write Post',
};

const ContributeModal = ({
  placementId,
  chptr,
  type: incomingType,
  isOpen,
  onClose,
  top,
}: ContributeModalProps) => {
  const [user, setUser] = useUser();

  const [registerUser] = useRegisterContributionUserMutation();
  const [updateUser] = useUpdateUserMutation({
    onCompleted: (data) => {
      setUser((prevUser) => ({
        ...prevUser!,
        ...data.updateUser,
        email: data.updateUser.email as string,
      }));
    },
  });
  const [authenticateUser] = useAuthenticateUserMutation({
    onCompleted: (data) => {
      setUser({
        ...data.authenticateUser.user,
        email: data.authenticateUser.user.email as string,
        token: data.authenticateUser.token,
      });
    },
  });

  const [type, setType] = useState<ContributeModalType>(incomingType);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState<string | null>(null);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const [contribuionError, setContributionError] = useState<Error | null>(null);

  const typeContributionRef = useRef<ContributeImperativeProps>(null);

  const reset = useCallback(
    (newType: ContributionMediaType) => {
      typeContributionRef.current?.reset();

      setStep(0);

      setType({
        type: newType,
        file: null,
      });
    },
    [setType, setStep],
  );

  const onSubmitContribution = useCallback(async () => {
    setLoading('Posting Your Memory');
    setLoadingProgress(0);
    setContributionError(null);

    try {
      typeContributionRef.current?.onProgress?.((progress) => {
        setLoadingProgress(progress);
      });

      await typeContributionRef.current?.onSubmit(chptr);

      setStep(2);
    } catch (error) {
      setContributionError(error as Error);
    } finally {
      setLoading(null);
    }
  }, [chptr]);

  const onContributorInformation = useCallback(
    async (payload: ContributeContributorInformationPayload) => {
      setLoading('Preparing Your Memory');

      try {
        if (!user) {
          const registerUserResponse = await registerUser({
            variables: {
              input: {
                firstName: payload.firstName,
                lastInitial: payload.lastInitial,
                placementId,
              },
            },
          });

          if (!registerUserResponse.data?.registerContributionUser) {
            throw new Error('Failed to register user');
          }

          setUser({
            token: registerUserResponse.data.registerContributionUser.token,
            ...registerUserResponse.data.registerContributionUser.user,
            email: registerUserResponse.data.registerContributionUser.user
              .email as string,
          });
        }

        await new Promise((resolve) => setTimeout(resolve, 50));

        await onSubmitContribution();
      } catch (error) {
        console.error(error);

        throw error;
      } finally {
        setLoading(null);
      }
    },
    [user, setUser, registerUser, placementId, onSubmitContribution],
  );

  const onContributeClick = useCallback(async () => {
    if (user) {
      await onSubmitContribution();
    } else {
      setStep((prev) => prev + 1);
    }
  }, [user, onSubmitContribution]);

  const onAccountInformation = useCallback(
    async (payload: ContributeAccountInformationPayload) => {
      setLoading('Creating Your Account');

      try {
        if ('provider' in payload) {
          return authenticateUser({
            variables: {
              input: {
                email: payload.email,
                provider: payload.provider,
                externalId: payload.externalId,
                firstName: payload.firstName,
                lastName: payload.lastName,
                pictureUrl: payload.pictureUrl,
                placementId,
              },
            },
          });
        }

        return updateUser({
          variables: {
            input: {
              id: user!.id,
              email: payload.email,
              password: payload.password,
            },
          },
        });
      } catch (error) {
        console.error(error);

        throw error;
      } finally {
        setLoading(null);
      }
    },
    [updateUser, authenticateUser, placementId, user],
  );

  return (
    <Modal
      enableMobile
      isOpen={isOpen}
      onClose={onClose}
      containerClassName={classNames('w-full sm:mx-[5%]', {
        '!p-0': step === 2,
      })}
      style={{
        top: top || '10%',
      }}
    >
      {loading && (
        <div>
          <div className="flex flex-col items-center justify-center px-10 pb-6 pt-12">
            <LoadingFlowers className="w-full" />
          </div>

          <div className="flex flex-col items-center">
            <p className="font-heading text-xl text-neutral-600">{loading}</p>
          </div>

          {typeContributionRef.current &&
            typeContributionRef.current.onProgress && (
              <div className="mx-8 my-6 rounded-full border-[1px] border-neutral-300 p-1">
                <div
                  className="h-2 rounded-full bg-amber-400"
                  style={{ width: `${loadingProgress}%` }}
                />
              </div>
            )}

          <div className="mb-8 flex flex-row items-center justify-center">
            <p className="font-heading text-sm text-neutral-800">Powered by</p>
            <img src={Logo} className="ml-2 h-4" />
          </div>
        </div>
      )}

      <div
        className={classNames('relative', {
          hidden: loading,
        })}
      >
        <div
          className={classNames({
            hidden: step !== 0,
          })}
        >
          {contribuionError && (
            <div className="p-4 text-center font-semibold text-red-500">
              {contribuionError.message}
            </div>
          )}

          <ContributeFlowModalHeader
            title={contributeTypeTitleMap[type.type]}
            RightComponent={
              <IoMdClose
                size={24}
                className="cursor-pointer"
                onClick={onClose}
              />
            }
          />

          {type.type === ContributionMediaType.Image && (
            <ImageContribution
              ref={typeContributionRef}
              chptr={chptr}
              placementId={placementId}
              onContributeClick={onContributeClick}
              {...type}
            />
          )}

          {type.type === ContributionMediaType.Video && (
            <VideoContribution
              ref={typeContributionRef}
              chptr={chptr}
              placementId={placementId}
              onContributeClick={onContributeClick}
              {...type}
            />
          )}

          {type.type === ContributionMediaType.Text && (
            <TextContribution
              ref={typeContributionRef}
              chptr={chptr}
              placementId={placementId}
              onContributeClick={onContributeClick}
            />
          )}
        </div>

        <div className={classNames({ hidden: step !== 1 })}>
          <ContributeFlowContributorInformationStep
            step={step}
            setStep={setStep}
            onClose={onClose}
            onContributorInformation={onContributorInformation}
            user={user}
          />
        </div>

        <div className={classNames({ hidden: step !== 2 })}>
          <ContributeFlowThankYouStep
            step={step}
            chptr={chptr}
            setStep={setStep}
            onClose={onClose}
            onAccountInformation={onAccountInformation}
            onMediaTypeClick={(type) => reset(type)}
            user={user}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ContributeModal;

import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { SyntheticEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAnalytics } from '../contexts/analytics.tsx';
import { usePurchase } from '../contexts/purchase.tsx';
import { OrderStatus, useUpdateOrderMutation } from '../gql/generated.tsx';
import Button from './ui/Button';
import Callout from './ui/Callout.tsx';

export default function StripeForm() {
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const { segmentTrack } = useAnalytics();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { placementId } = useParams<{ placementId: string }>();

  const [message, setMessage] = useState<string | null>(null);

  const [updateOrder] = useUpdateOrderMutation({});

  const { createdOrderId } = usePurchase();

  const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const route = `/${placementId}/order/${createdOrderId}`;
    const returnUrl = `${window.location.origin}${route}`;
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: returnUrl,
      },
    });

    setIsLoading(false);

    if (error) {
      console.error(error);

      if (
        (error.type === 'card_error' || error.type === 'validation_error') &&
        error.message
      ) {
        return setMessage(error.message);
      }

      return setMessage(
        'Could not process payment. Please try again or change payment method.',
      );
    }

    const { data, errors } = await updateOrder({
      variables: {
        input: {
          id: createdOrderId as string,
          status: OrderStatus.Succeeded,
        },
      },
    });

    if (errors) {
      console.error(errors);
      return setMessage(
        'Could not process payment. Please contact support@chptr.com for assistance.',
      );
    }

    if (data) {
      return navigate(
        `${route}?payment_intent=${paymentIntent.id}&payment_intent_client_secret=${paymentIntent.client_secret}&status=${paymentIntent.status}`,
      );
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        options={{}}
        onChange={(e) =>
          e.complete ? segmentTrack('Payment Info Entered') : null
        }
      />
      <div className="!mt-0 space-y-[28px] py-[30px]">
        <div className="space-y-2 text-center">
          <Button
            className="w-[250px] text-[19px]"
            type="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            Purchase
          </Button>
        </div>
        <p className="text-center">
          By placing your order, you agree to our terms of service and privacy
          policy.
        </p>
        {message && <Callout variant="error">{message}</Callout>}
      </div>
    </form>
  );
}

import { ChptrType } from '../gql/generated';

const LifeSpan = (
  bornAt: string | number | null | undefined,
  passedAt: string | number | null | undefined,
  chptrType: ChptrType | undefined,
) => {
  let lifeSpan = null;

  if (bornAt && passedAt) {
    lifeSpan = `${bornAt} - ${passedAt}`;
  } else if (chptrType === ChptrType.Memorial) {
    if (bornAt) {
      lifeSpan = `b. ${bornAt}`;
    } else if (passedAt) {
      lifeSpan = `d. ${passedAt}`;
    }
  }

  return lifeSpan;
};

export default LifeSpan;

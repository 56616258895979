import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { IoIosSearch } from 'react-icons/io';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';

import {
  Chptr,
  ChptrType,
  ChptrVisibility,
  useGetRecentChptrsLazyQuery,
} from '../gql/generated';
import FromDateParts from '../modules/FromDateParts';
import LifeSpan from '../modules/LifeSpan';
import Button from './ui/Button';
import { ChptrPlaceholderProfileImageSingle } from './ui/ChptrPlaceholderProfileImage';

const Header = ({
  headerText,
  headerSubText,
  purchaseButtonText,
  searchTitleText,
  searchPlaceholderText,
}: {
  headerText: string;
  headerSubText: string;
  purchaseButtonText: string;
  searchTitleText: string;
  searchPlaceholderText: string;
}) => {
  const { placementId } = useParams();

  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);

  const location = useLocation();

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="flex h-[210px] flex-col justify-center font-heading sm:h-[280px] md:h-[340px]">
          <p className="py-2 text-xl font-medium text-white sm:py-4 sm:text-3xl md:py-6 md:text-4xl">
            {headerText}
          </p>
          <p className="max-w-[560px] pb-2 font-sans text-sm font-medium text-white sm:text-base md:text-lg">
            {headerSubText}
          </p>

          <Link
            to="./purchase"
            className="w-1/5"
            state={{ redirectBack: location.pathname }}
          >
            <Button className="block !px-[40px] text-sm sm:!px-[70px] sm:text-base">
              {purchaseButtonText}
            </Button>
          </Link>
        </div>

        <div
          className={classNames(
            'w-full rounded-2xl border border-[#d9d9d9] p-4',
            {
              invisible: showSearchModal,
            },
          )}
        >
          <p className="mb-2 font-heading text-lg text-[#282828]">
            {searchTitleText}
          </p>

          <IoIosSearch className="absolute ml-3 mt-3 h-5 w-5 text-[#282828]" />

          <input
            placeholder={searchPlaceholderText}
            onFocus={(i) => i.currentTarget.blur()}
            className="flex h-[45px] w-full rounded-[50px] border-[1px] border-[#E2E2E2] bg-neutral-100 pl-10 pr-1 font-heading focus:outline-none"
            onClick={() => setShowSearchModal(true)}
          />
        </div>
      </div>
      <SearchModal
        searchTitleText={searchTitleText}
        searchPlaceholderText={searchPlaceholderText}
        isOpen={showSearchModal}
        placementId={placementId}
        onClose={() => setShowSearchModal(false)}
      />
    </>
  );
};

const SearchModal = ({
  isOpen,
  placementId,
  onClose,
  searchTitleText,
  searchPlaceholderText,
}: {
  isOpen: boolean;
  placementId: string | undefined;
  onClose: () => void;
  searchTitleText: string;
  searchPlaceholderText: string;
}) => {
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState<string>('');

  const [searching, setSearching] = useState<boolean>(false);

  const [getResults, { error, data }] = useGetRecentChptrsLazyQuery({
    variables: {
      visibilities: [ChptrVisibility.Public, ChptrVisibility.GlobalPublic],
      chptrsFirst: 3,
      contributionsFirst: 1,
      placementId: placementId ?? '',
    },
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setSearching(false);
    },
  });

  const debouncedQuery = useDebounceValue(searchText, 500)[0];
  useEffect(() => {
    getResults({
      fetchPolicy: 'no-cache',
      variables: {
        query: debouncedQuery,
        placementId: placementId ?? '',
      },
    });
  }, [placementId, debouncedQuery, getResults]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen scrollable container */}
      <div className="fixed inset-0 top-[70px] w-screen overflow-y-auto md:top-[79px]">
        {/* Container to center the panel */}
        <div className="relative mx-6 flex items-center justify-center">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="mx-auto w-full rounded-2xl bg-white p-4 shadow-[0_2px_8px_0_rgba(18,18,18,0.2)] 2xl:mx-auto 2xl:w-10/12">
            <div className="space-y-2">
              <p className="mb-2 font-heading text-lg text-[#282828]">
                {searchTitleText}
              </p>

              <div className="w-full">
                <div className="absolute ml-3 mt-3">
                  {searching ? (
                    <AiOutlineLoading className="h-5 w-5 animate-spin" />
                  ) : (
                    <IoIosSearch className="h-5 w-5 text-[#282828]" />
                  )}
                </div>

                <input
                  placeholder={searchPlaceholderText}
                  onChange={(e) => {
                    setSearching(true);
                    setSearchText(e.currentTarget.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      navigate(`/${placementId}/search?q=${searchText}`);
                    }
                  }}
                  className="flex h-[45px] w-full rounded-[50px] border-[1px] border-[#E2E2E2] bg-neutral-100 pl-10 pr-1 font-heading focus:outline-none"
                />
              </div>
              <div className="!mt-4">
                {!searching &&
                  data?.placement?.chptrs.edges &&
                  data?.placement?.chptrs.edges.length > 0 &&
                  searchText !== '' && (
                    <div className="space-y-4">
                      {data?.placement?.chptrs.edges.map((chptr) => (
                        <SearchResult
                          key={chptr.node.id}
                          chptr={chptr.node as Chptr}
                        />
                      ))}
                    </div>
                  )}
                {!searching &&
                  searchText !== '' &&
                  (data?.placement?.chptrs.edges.length === 0 || error) && (
                    <p className="mb-4 font-heading">
                      No results for "{searchText}"
                    </p>
                  )}
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

const SearchResult = ({ chptr }: { chptr: Chptr }) => {
  const navigate = useNavigate();

  const { placementId } = useParams();

  const bornAt = FromDateParts(chptr.bornAt);
  const passedAt = FromDateParts(chptr.passedAt);
  const lifeSpan = LifeSpan(bornAt, passedAt, chptr?.type);
  const dates = chptr.type === ChptrType.Memorial ? lifeSpan : bornAt;

  return (
    <div
      className="flex cursor-pointer hover:bg-[#F5F5F5]"
      onClick={() => navigate(`/${placementId}/chptr/${chptr.id}`)}
    >
      <div className="h-[80px] w-[80px]">
        {(chptr.pictureUrl || chptr.webPictureUrl) && (
          <img
            className="h-full w-full object-cover"
            src={chptr.webPictureUrl ? chptr.webPictureUrl : chptr.pictureUrl!}
            alt="Profile Banner Image"
          />
        )}

        {!chptr.pictureUrl && !chptr.webPictureUrl && (
          <ChptrPlaceholderProfileImageSingle
            className="h-full w-full"
            iconWrapperClassName="scale-75"
          />
        )}
      </div>
      <div className="min-w-0 flex-1 space-y-1 truncate py-3 pl-4">
        <p className="truncate font-heading text-[22px] leading-[30px]">
          {chptr.type === ChptrType.Wedding
            ? `${chptr.firstName} & ${chptr.lastName}`
            : `${chptr.firstName} ${chptr.lastName}`}
        </p>
        {dates && (
          <p className="text-[17px] leading-[22px] text-[#666666]">{dates}</p>
        )}
      </div>
    </div>
  );
};

export default Header;

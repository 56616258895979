import { zodResolver } from '@hookform/resolvers/zod';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useState } from 'react';
import AppleLogin from 'react-apple-login';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import ButtonDownloadApple from '../../assets/button-download-apple.png';
import ButtonDownloadGoogle from '../../assets/button-download-google.png';
import IconApple from '../../assets/icon-apple.png';
import IconGoogle from '../../assets/icon-google.png';
import { User } from '../../contexts/user';
import { AuthProvider, AuthenticateUserInput } from '../../gql/generated';
import useGoogleLogin from '../../hooks/useGoogleLogin';
import usePlacementTerms from '../../hooks/usePlacementTerms';
import { ContributeFlowTextInput } from './ContributeModalStep';

const isValidPassword = (password: string) => {
  if (password.length < 8) {
    return false;
  }

  if (!/[a-z]/.test(password)) {
    return false;
  }

  if (!/[A-Z]/.test(password)) {
    return false;
  }

  if (!/\d/.test(password)) {
    return false;
  }

  return true;
};

const SCHEMA_UPDATE = z
  .object({
    email: z.string().email(),
    password: z
      .string()
      .refine(
        isValidPassword,
        'Password must contain at least 8 characters, including uppercase, lowercase, and numbers',
      ),
    passwordConfirm: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Password doesn't match",
    path: ['passwordConfirm'],
  });

type SchemaUpdate = z.infer<typeof SCHEMA_UPDATE>;

const SCHEMA_CREATE = z
  .object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().email(),
    password: z
      .string()
      .refine(
        isValidPassword,
        'Password must contain at least 8 characters, including uppercase, lowercase, and numbers',
      ),
    passwordConfirm: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Password doesn't match",
    path: ['passwordConfirm'],
  });

type SchemaCreate = z.infer<typeof SCHEMA_CREATE>;

const SCHEMA_LOGIN = z.object({
  email: z.string().email(),
  password: z.string().min(1),
});

type SchemaLogin = z.infer<typeof SCHEMA_LOGIN>;

type LoginRegisterProps<F> = {
  onFormSubmit: (data: F) => unknown;
  onFormError: (error: Error) => unknown;
  onModeChange: (mode: 'register' | 'login') => unknown;
};

const Login = ({
  onFormSubmit,
  onFormError,
  onModeChange,
}: LoginRegisterProps<SchemaLogin>) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(SCHEMA_LOGIN),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log(data);
      await onFormSubmit(data);
    } catch (error) {
      console.error(error);

      onFormError(error as Error);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-4">
        <ContributeFlowTextInput
          required
          label="Email"
          containerClassName="w-full"
          error={errors.email?.message}
          {...register('email')}
        />

        <ContributeFlowTextInput
          required
          type="password"
          label="Password"
          containerClassName="w-full"
          autoComplete="current-password"
          error={errors.password?.message}
          {...register('password')}
        />
      </div>

      <div className="flex flex-col items-center pt-4">
        <button className="w-full rounded-full bg-amber-400 p-2 font-heading text-lg hover:opacity-80 md:w-2/3">
          Sign In
        </button>

        <p className="pt-3">
          Don't have an account with Chptr?{' '}
          <button
            onClick={() => onModeChange('register')}
            className="underline"
          >
            Create an Account
          </button>
        </p>
      </div>
    </form>
  );
};

const RegisterUpdate = ({
  onFormSubmit,
  onFormError,
  onModeChange,
}: LoginRegisterProps<SchemaUpdate>) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
    },
    resolver: zodResolver(SCHEMA_UPDATE),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log(data);
      await onFormSubmit(data);
    } catch (error) {
      console.error(error);

      onFormError(error as Error);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-4">
        <ContributeFlowTextInput
          required
          label="Email"
          containerClassName="w-full"
          error={errors.email?.message}
          {...register('email')}
        />

        <ContributeFlowTextInput
          required
          type="password"
          label="Password"
          containerClassName="w-full"
          error={errors.password?.message}
          {...register('password')}
        />

        <ContributeFlowTextInput
          required
          type="password"
          label="Password Confirmation"
          containerClassName="w-full"
          error={errors.passwordConfirm?.message}
          {...register('passwordConfirm')}
        />
      </div>

      <div className="flex flex-col items-center pt-4">
        <button className="w-full rounded-full bg-amber-400 p-2 font-heading text-lg hover:opacity-80 md:w-2/3">
          Create Account
        </button>

        <p className="pt-3">
          Already have an account with Chptr?{' '}
          <button onClick={() => onModeChange('login')} className="underline">
            Sign In
          </button>
        </p>
      </div>
    </form>
  );
};

const RegisterCreate = ({
  onFormSubmit,
  onFormError,
  onModeChange,
}: LoginRegisterProps<SchemaCreate>) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
    },
    resolver: zodResolver(SCHEMA_CREATE),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log(data);
      await onFormSubmit(data);
    } catch (error) {
      console.error(error);

      onFormError(error as Error);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-4 pt-2">
        <div className="flex flex-row gap-4">
          <ContributeFlowTextInput
            required
            label="First Name"
            containerClassName="w-full"
            error={errors.firstName?.message}
            {...register('firstName')}
          />

          <ContributeFlowTextInput
            required
            label="Last Name"
            containerClassName="w-full"
            error={errors.lastName?.message}
            {...register('lastName')}
          />
        </div>

        <ContributeFlowTextInput
          required
          label="Email"
          containerClassName="w-full"
          error={errors.email?.message}
          {...register('email')}
        />

        <ContributeFlowTextInput
          required
          type="password"
          label="Password"
          containerClassName="w-full"
          error={errors.password?.message}
          {...register('password')}
        />

        <ContributeFlowTextInput
          required
          type="password"
          label="Password Confirmation"
          containerClassName="w-full"
          error={errors.passwordConfirm?.message}
          {...register('passwordConfirm')}
        />
      </div>

      <div className="flex flex-col items-center pt-4">
        <button className="w-full rounded-full bg-amber-400 p-2 font-heading text-lg hover:opacity-80 md:w-2/3">
          Create Account
        </button>

        <p className="pt-3">
          Already have an account with Chptr?{' '}
          <button onClick={() => onModeChange('login')} className="underline">
            Sign In
          </button>
        </p>
      </div>
    </form>
  );
};

type RegisterFormProps = {
  subtitle?: string;
  user?: User;
  hideAppDownload?: boolean;
  hideGoogleLogin?: boolean;
  hideAppleLogin?: boolean;
  defaultMode?: 'login' | 'register';
  onLogin: (data: { email: string; password: string }) => Promise<unknown>;
  onRegisterCreate: (data: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    passwordConfirm: string;
  }) => Promise<unknown>;
  onRegisterUpdate: (data: {
    email: string;
    password: string;
    passwordConfirm: string;
  }) => Promise<unknown>;
  onAuthenticate: (data: {
    firstName: string;
    lastName: string;
    email: string;
    provider: AuthProvider;
    externalId: string;
  }) => Promise<unknown>;
};

export const LoginRegisterForm = ({
  subtitle,
  user,
  hideAppDownload,
  hideGoogleLogin,
  hideAppleLogin,
  onLogin,
  onRegisterCreate,
  onRegisterUpdate,
  onAuthenticate,
  defaultMode = 'register',
}: RegisterFormProps) => {
  const [mode, setMode] = useState<'register' | 'login'>(defaultMode);
  const [error, setError] = useState<Error | null>(null);

  const awaitHandleError = useCallback(async (fn: Promise<unknown>) => {
    try {
      console.log(await fn);
    } catch (error) {
      setError(error as Error);
    }
  }, []);

  const { login: googleLogin } = useGoogleLogin({
    onSuccess: async (profile) => {
      await awaitHandleError(
        onAuthenticate({
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          provider: AuthProvider.Google,
          externalId: profile.externalId,
        }),
      );
    },
  });

  const onAppleLogin = useCallback(
    async (data: {
      authorization: {
        id_token: string;
        user?: {
          name: { firstName: string; lastName: string };
          email: string;
        };
      };
    }) => {
      const decoded = jwtDecode(data.authorization.id_token) as {
        email: string;
        sub: string;
      };

      const authenData: AuthenticateUserInput = {
        email: decoded.email,
        provider: AuthProvider.Apple,
        externalId: decoded.sub,
        firstName: 'Apple',
        lastName: 'User',
        placementId: undefined,
      };

      if (data.authorization.user) {
        const { firstName, lastName } = data.authorization.user.name;

        authenData.firstName = firstName;
        authenData.lastName = lastName;
      }

      await awaitHandleError(onAuthenticate(authenData));
    },
    [onAuthenticate, awaitHandleError],
  );

  const {
    hasPartnerTerms,
    chptrTermsAccepted,
    setChptrTermsAccepted,
    partnerTermsAccepted,
    setPartnerTermsAccepted,
    validateTerms,
  } = usePlacementTerms({ mode });

  const checkTerms = useCallback(
    (cb: () => void) => {
      validateTerms((isValid) => {
        if (!isValid) {
          setError(new Error('Please accept the terms and conditions'));
          return;
        }

        cb();
        setError(null);
      });
    },
    [validateTerms],
  );

  return (
    <div className="sm:rounded-2xl sm:border-[1px] sm:border-neutral-200 sm:p-4 sm:px-8 sm:shadow-sm">
      <div className="mb-4">
        <div className="flex flex-col items-center pb-4">
          <p className="font-heading text-xl">
            {mode === 'register' ? 'Create an account' : 'Sign in'}
          </p>

          {subtitle && (
            <p className="pt-2 text-sm text-neutral-600">{subtitle}</p>
          )}

          {mode === 'register' && (
            <div className="pb-2 pt-6 text-[#282828]">
              <div>
                <input
                  type="checkbox"
                  defaultChecked={chptrTermsAccepted}
                  onClick={() => setChptrTermsAccepted((v) => !v)}
                />{' '}
                <div className="inline">
                  By checking this box and creating an account, you also agree
                  to be bound by{' '}
                  <a
                    href="https://www.chptr.com/privacy"
                    target="_blank"
                    className="underline"
                  >
                    Chptr’s Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.chptr.com/terms"
                    target="_blank"
                    className="underline"
                  >
                    Chptr’s Terms of Service
                  </a>
                  . You also expressly agree that Chptr, Inc. and its
                  affiliates, subsidiaries and partners may access your personal
                  information and communicate with you using this information,
                  subject to{' '}
                  <a
                    href="https://www.chptr.com/privacy"
                    target="_blank"
                    className="underline"
                  >
                    Chptr’s Privacy Policy
                  </a>
                  .
                </div>
              </div>
              {hasPartnerTerms && (
                <div>
                  <input
                    type="checkbox"
                    defaultChecked={partnerTermsAccepted}
                    onClick={() => setPartnerTermsAccepted((v) => !v)}
                  />{' '}
                  <div className="inline">
                    By checking this box and creating an account, you (1)
                    represent and warrant that you are at least eighteen (18)
                    years of age and (2) agree to be bound by{' '}
                    <a
                      href="https://sbgi.net/privacy-policy/"
                      target="_blank"
                      className="underline"
                    >
                      Sinclair’s Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://sbgi.net/terms-conditions/"
                      target="_blank"
                      className="underline"
                    >
                      Sinclair’s Terms & Conditions
                    </a>
                    . You also expressly agree that Sinclair, Inc. and its
                    affiliates, subsidiaries and partners may access your
                    personal information and communicate with you using this
                    information, subject to{' '}
                    <a
                      href="https://sbgi.net/privacy-policy/"
                      target="_blank"
                      className="underline"
                    >
                      Sinclair’s Privacy Policy
                    </a>
                    .
                  </div>
                </div>
              )}
            </div>
          )}

          {error && (
            <div className="rounded-lg bg-[#FAE4E1] px-6 py-2">
              <p className="text-sm text-red-500">{error.message}</p>
            </div>
          )}
        </div>

        <div className="flex flex-col items-center space-y-4 lg:px-[10%]">
          {!hideGoogleLogin && (
            <button
              onClick={(e) => {
                e.preventDefault();
                checkTerms(() => googleLogin());
              }}
              className="flex w-full flex-row items-center justify-center space-x-2 rounded-full border-2 border-neutral-300 p-2.5 font-bold text-neutral-700 hover:border-neutral-400 hover:bg-neutral-50"
            >
              <img className="h-4" src={IconGoogle} />
              <p>Continue with Google</p>
            </button>
          )}

          {!hideAppleLogin && (
            <AppleLogin
              usePopup
              clientId="com.chptr.chptr.enterprise"
              callback={onAppleLogin}
              scope="name email"
              redirectURI="https://partners.chptr.com"
              render={({ onClick }) => (
                <button
                  className="flex w-full flex-row items-center justify-center space-x-2 rounded-full border-2 border-neutral-300 p-2.5 font-bold text-neutral-700 hover:border-neutral-400 hover:bg-neutral-50"
                  onClick={(e) => {
                    checkTerms(() => onClick(e));
                  }}
                >
                  <img className="h-4" src={IconApple} />
                  <p>Continue with Apple</p>
                </button>
              )}
            />
          )}

          <p className="pb-2 pt-1">or</p>
        </div>

        {mode === 'register' && user && user.isContributionUser && (
          <RegisterUpdate
            onFormSubmit={(data) =>
              checkTerms(() => awaitHandleError(onRegisterUpdate(data)))
            }
            onFormError={setError}
            onModeChange={setMode}
          />
        )}
        {mode === 'register' && !user && (
          <RegisterCreate
            onFormSubmit={(data) =>
              checkTerms(() => awaitHandleError(onRegisterCreate(data)))
            }
            onFormError={setError}
            onModeChange={setMode}
          />
        )}
        {mode === 'login' && (
          <Login
            onFormSubmit={(data) => awaitHandleError(onLogin(data))}
            onFormError={setError}
            onModeChange={setMode}
          />
        )}
      </div>

      {!hideAppDownload && (
        <div className="flex flex-col items-center">
          <p className="pt-2 text-sm text-neutral-600">
            Download the Chptr app to continue building their digital legacy.
          </p>

          <div className="flex flex-row items-center justify-center pt-4">
            <a
              href="https://apps.apple.com/app/apple-store/id1672492101?pt=124689463&mt=8"
              target="_blank"
            >
              <img className="mx-2 h-10" src={ButtonDownloadApple} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.chptr.chptr.v2"
              target="_blank"
            >
              <img className="mx-2 h-10" src={ButtonDownloadGoogle} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

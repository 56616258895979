import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const operationLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    ...operation.getContext(),
    headers: {
      ...operation.getContext().headers,
      'x-apollo-operation-name': operation.operationName,
    },
  });

  return forward(operation);
});

export const createClient = (
  uri: string,
  resolveToken: () => string | null | Promise<string | null>,
  onUnauthorizedError: () => void,
) => {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          chptrs: relayStylePagination(['query']),
        },
      },
      Chptr: {
        fields: {
          contributions: relayStylePagination(['chptrId']),
        },
      },
      Placement: {
        fields: {
          chptrsV2: relayStylePagination(['query']),
          eventsV2: relayStylePagination(['query']),
        },
      },
      Contribution: {
        fields: {
          commentsV2: relayStylePagination(['contributionId']),
        },
      },
    },
  });

  const httpLink = createUploadLink({
    uri,
  });

  const authLink = setContext(async (_, { headers }) => {
    const tokenOrNull = await resolveToken();

    return {
      headers: {
        ...headers,
        ...(tokenOrNull ? { Authorization: tokenOrNull } : {}),
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );

        if (message.includes('Not Authorised')) {
          onUnauthorizedError();
          return;
        }
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const apollo = new ApolloClient({
    link: from([operationLink, authLink, errorLink, httpLink]),
    cache,
  });

  return apollo;
};

import { ApolloProvider } from '@apollo/client';
import { useEffect, useMemo, useRef } from 'react';
import { useInterval } from 'usehooks-ts';

import { createClient } from './client.ts';
import { AnalyticsProvider } from './contexts/analytics.tsx';
import { useApplication } from './contexts/application.tsx';
import { getToken, useUser } from './contexts/user.tsx';
import useParentUrl from './hooks/useParentUrl.ts';

const URL_PLATFORM = import.meta.env.VITE_API_URL || 'http://0.0.0.0:3002/gql';

function App({ children }: { children: React.ReactNode }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setUser] = useUser();

  const { setParentUrl } = useParentUrl();
  const { setScrollPosition, setParentWindowHeight, setChptrId } =
    useApplication();

  const containerHeight = useRef<number>(1000);

  useInterval(() => {
    // Don't send height if we're in a modal. Otherwise, we will be sending a resize message to the root iframe which will make the root wonky.
    if (window.location.pathname.includes('modals')) {
      return;
    }

    const nextHeight = document.querySelector('#root')!.scrollHeight + 100;

    // This is a hack to allow the host page to get the proper height of the content so that the iframe doesn't have to scroll.
    if (nextHeight !== containerHeight.current) {
      containerHeight.current = nextHeight;
      window.parent.postMessage(['setHeight', containerHeight.current], '*');
    }
  }, 100);

  const client = useMemo(
    () => createClient(URL_PLATFORM, getToken, () => setUser(null)),
    [setUser],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const parentUrl = searchParams.get('parentUrl');

    if (parentUrl) {
      setParentUrl(parentUrl);
    }

    const chptrId = searchParams.get('chptrId');

    if (chptrId) {
      setChptrId(chptrId);
    }

    const listener = (event: MessageEvent) => {
      if (typeof event.data === 'string') {
        try {
          const parsed = JSON.parse(event.data);

          if (parsed.type === 'parentUrl') {
            setParentUrl(parsed.location);
          } else if (parsed.type === 'scroll') {
            setScrollPosition(parsed.scrollPosition || 0);
          } else if (parsed.type === 'resize') {
            setParentWindowHeight(parsed.height || 500);
          }
        } catch {
          // No-op
        }
      }
    };

    window.addEventListener('message', listener);

    // Handling stale dynamic imports between vite deployments
    window.addEventListener('vite:preloadError', () => {
      window.location.reload();
    });

    return () => {
      window.removeEventListener('message', listener);

      // Handling stale dynamic imports between vite deployments
      window.removeEventListener('vite:preloadError', () => {
        window.location.reload();
      });
    };
  }, [setParentUrl, setScrollPosition, setParentWindowHeight, setChptrId]);

  return (
    <ApolloProvider client={client}>
      <AnalyticsProvider>{children}</AnalyticsProvider>
    </ApolloProvider>
  );
}

export default App;

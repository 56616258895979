import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export const USER_KEY = 'user';

export interface User {
  id: string;
  token: string;
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  isContributionUser?: boolean;
}

type UserContext = [user: User | null, Dispatch<SetStateAction<User | null>>];

const UserContext = createContext<UserContext | undefined>(undefined);

export const UserProvider = ({
  user: initialUser,
  children,
}: {
  user: User | null;
  children: React.ReactNode;
}) => {
  const [user, setUser] = useLocalStorage<User | null>(USER_KEY, initialUser);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const getToken = () => {
  const item = window.localStorage.getItem(USER_KEY);

  if (!item) {
    return null;
  }

  const user = JSON.parse(item) as User;

  return user?.token || null;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useUser = () => {
  const user = useContext(UserContext);

  if (user === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return user as UserContext;
};

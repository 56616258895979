import classnames from 'classnames';
import { LegacyRef, ReactNode, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

export default function DropDown({
  buttonClassName,
  listClassName,
  elementClassName,
  buttonLabel,
  elements,
  disabled,
  name,
  value,
  error,
}: {
  buttonClassName?: string;
  listClassName?: string;
  elementClassName?: string;
  buttonLabel: string | ReactNode;
  elements: { id?: string; label: string | ReactNode; onClick: () => void }[];
  disabled?: boolean;
  name?: string;
  value?: string;
  error?: string;
}) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const inputRef = useRef<LegacyRef<HTMLInputElement>>();
  const methods = useFormContext();

  return (
    <div className="relative">
      <button
        className={classnames(
          '',
          { 'cursor-default': disabled },
          { 'rounded-xl': !expanded },
          { 'rounded-b-none rounded-t-xl': expanded },
          { 'border border-red-600': error },
          buttonClassName,
        )}
        onClick={() => !disabled && setExpanded(!expanded)}
        onBlur={() => setTimeout(() => setExpanded(false), 200)}
        type="button"
      >
        {buttonLabel}
        {!disabled && expanded && (
          <IoIosArrowDown className="pt-0.5" size={22} />
        )}
        {!disabled && !expanded && (
          <IoIosArrowUp className="pt-0.5" size={22} />
        )}
      </button>
      {error && <span className="text-sm text-red-600">{error}</span>}

      {expanded && (
        <ul
          className={classnames(
            'absolute z-50 mt-2 border border-[#D9D9D9] bg-white p-2 shadow',
            listClassName,
          )}
        >
          {elements.map((e, ind) => (
            <li
              key={ind}
              className={classnames('cursor-pointer py-1', elementClassName)}
              onClick={() => {
                e.onClick();
                name &&
                  methods.setValue(name, e.id, {
                    shouldValidate: true,
                  });
              }}
            >
              {e.label}
            </li>
          ))}
        </ul>
      )}
      {name && (
        <input
          ref={inputRef.current}
          name={name}
          className="hidden"
          value={value || ''}
          disabled
        />
      )}
    </div>
  );
}

import { useLocalStorage } from 'usehooks-ts';

const useParentUrl = () => {
  const [parentUrl, setParentUrl] = useLocalStorage<string | null>(
    'chptrParentUrl',
    null,
  );

  return {
    parentUrl,
    setParentUrl,
  };
};

export default useParentUrl;

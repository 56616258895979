import * as qr from '@bitjson/qr-code';
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
// import { isDesktop } from 'react-device-detect';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// import QrHeartLogo from '../assets/qr-heart-logo.png';
import { useApplication } from '../contexts/application';
import { ChptrVisibility } from '../gql/generated';
import { DEFAULT_CONFIG } from '../modules/Constants';
// import useParentUrl from '../hooks/useParentUrl';
import ContributeModal, { ContributeModalType } from './ContributeModal';
import ContributeCard from './ui/ContributeCard';
import ContributeMediaTypeButton, {
  ContributionMediaType,
} from './ui/ContributeMediaTypeButton';
import PromptCarousel, { PromptCarouselProps } from './ui/PromptCarousel';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSXElements {
    export interface QrCodeAttributes extends HTMLAttributes {
      children?: ReactNode;
      style?: CSSProperties;
    }
  }
}

type ContributeProps = {
  chptr: PromptCarouselProps['chptr'] & { id: string };
  placementId: string;
  className?: string;
};

const ContributeBar = ({ chptr, placementId, className }: ContributeProps) => {
  // const { parentUrl } = useParentUrl();
  const { getScrollPosition } = useApplication();
  const [type, setType] = useState<ContributeModalType | null>(null);

  const [scroll, setScroll] = useState<number>(getScrollPosition());

  const reset = useCallback((newType?: ContributeModalType) => {
    setType(newType || null);
  }, []);

  useEffect(() => {
    qr.defineCustomElements(window);
  }, []);

  useEffect(() => {
    setScroll(getScrollPosition());
  }, [type, getScrollPosition]);

  return (
    <div className={className}>
      <div className="relative z-[1000] px-4">
        <PromptCarousel
          chptr={chptr}
          gradient={{ from: '#ecf6e1', to: '#d4e6f8' }}
          prompts={
            chptr.visibility === ChptrVisibility.GlobalPublic
              ? DEFAULT_CONFIG.BANNER_ITEMS[chptr.type].GLOBAL.DESCRIPTIONS
              : DEFAULT_CONFIG.BANNER_ITEMS[chptr.type].NON_GLOBAL.DESCRIPTIONS
          }
          textClassName="text-xl"
        />
      </div>

      <ContributeCard className="relative -top-4 z-[500]">
        <div className="flex flex-row px-4 py-4 md:px-8 md:pb-0 md:pt-6">
          <ContributeMediaTypeButton
            inverted
            type={ContributionMediaType.Video}
            onClick={() =>
              setType({
                type: ContributionMediaType.Video,
                file: null,
              })
            }
          />
          <div className="w-10 py-2 md:py-0" />
          <ContributeMediaTypeButton
            inverted
            type={ContributionMediaType.Image}
            onClick={() =>
              setType({
                type: ContributionMediaType.Image,
                file: null,
              })
            }
          />
          <div className="w-10 py-2 md:py-0" />
          <ContributeMediaTypeButton
            inverted
            type={ContributionMediaType.Text}
            onClick={() =>
              setType({
                type: ContributionMediaType.Text,
              })
            }
          />
        </div>

        {/*{isDesktop && (*/}
        {/*  <div className="mt-2 md:mt-6">*/}
        {/*    <div className="m-auto flex w-3/4 items-center">*/}
        {/*      <div className="aspect-square">*/}
        {/*        <qr-code*/}
        {/*          contents={`${parentUrl}?chptrId=${chptr.id}`}*/}
        {/*          module-color="black"*/}
        {/*          position-ring-color="black"*/}
        {/*          position-center-color="black"*/}
        {/*          mask-x-to-y-ratio="1.2"*/}
        {/*          style={{*/}
        {/*            width: '100px',*/}
        {/*            height: '100px',*/}
        {/*            backgroundColor: '#fff',*/}
        {/*            border: '7px solid #ff2e34',*/}
        {/*            borderRadius: 12,*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <img*/}
        {/*            src={QrHeartLogo}*/}
        {/*            slot="icon"*/}
        {/*            style={{*/}
        {/*              backgroundColor: 'white',*/}
        {/*              padding: 2,*/}
        {/*            }}*/}
        {/*          />*/}
        {/*        </qr-code>*/}
        {/*      </div>*/}

        {/*      <p className="ml-6 font-heading text-[18px] leading-[26px] text-neutral-600">*/}
        {/*        Want to record a video or audio memory or access your camera*/}
        {/*        roll? Scan to download the Chptr app.*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </ContributeCard>

      {type && (
        <ContributeModal
          isOpen
          onClose={() => reset()}
          reset={reset}
          placementId={placementId}
          chptr={chptr}
          type={type}
          top={scroll + 100}
        />
      )}
    </div>
  );
};

export default ContributeBar;

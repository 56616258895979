import { BsArrowLeft } from 'react-icons/bs';

import { usePurchase } from '../contexts/purchase';
import Button from './ui/Button.tsx';

export default function PurchaseNavigation() {
  const purchase = usePurchase();

  return (
    <Button
      variant="stroke"
      onClick={() => purchase.goBack()}
      className="!h-[32px] w-[93px] !px-2"
    >
      <BsArrowLeft className="mr-2" /> Back
    </Button>
  );
}

import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { PropsWithChildren, useEffect } from 'react';

import useBreakpoints from '../../hooks/useBreakpoints';

export type ModalProps = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  containerClassName?: string;
  style?: React.CSSProperties;
  enableMobile?: boolean;
}>;

const Modal = ({
  isOpen,
  onClose,
  containerClassName,
  children,
  style,
  enableMobile,
}: ModalProps) => {
  const { isXs } = useBreakpoints();

  useEffect(() => {
    if (enableMobile && isXs) {
      window.parent.postMessage(['lockScroll'], '*');
    }

    return () => {
      window.parent.postMessage(['unlockScroll'], '*');
    };
  }, [enableMobile, isXs]);

  if (enableMobile && isXs && isOpen) {
    return (
      <div className="fixed inset-0 z-[9999]">
        <div
          className={classNames(
            'relative flex h-full w-full justify-center overflow-hidden bg-white',
            containerClassName,
          )}
        >
          <div className="absolute w-full bg-white px-4" style={style}>
            {children}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-[9999]">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      {/* Full-screen scrollable container */}
      <div className="fixed inset-0 w-screen overflow-y-auto">
        {/* Container to center the panel */}
        <div
          className="relative flex items-center justify-center md:p-4"
          style={style}
        >
          {/* The actual dialog panel  */}
          <Dialog.Panel
            className={classNames(
              'mx-auto rounded-2xl bg-white p-6',
              containerClassName,
            )}
          >
            <div>{children}</div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;

import cn from 'classnames';
import { TextareaHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

export default function FormTextarea({
  name,
  label,
  inputClassName,
  ...rest
}: {
  name: string;
  label?: string;
  inputClassName?: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement>) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const err = name in errors ? errors[name] : undefined;

  return (
    <div className="flex flex-col space-y-2">
      {label && (
        <label
          htmlFor={name}
          className={cn('font-heading', { 'text-[#EF341E]': err })}
        >
          {label}
        </label>
      )}
      <textarea
        className={cn(
          'w-full resize-y rounded-lg border border-neutral-400 bg-neutral-100 p-4 text-neutral-700',
          inputClassName,
        )}
        {...register(name)}
        {...rest}
      />
      {err && (
        <p className="!mt-1 text-[13px] leading-[16px] text-[#EF341E]">
          {err.message as string}
        </p>
      )}
    </div>
  );
}

import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useUser } from '../contexts/user';
import {
  AuthenticateUserInput,
  LoginUserInput,
  RegisterUserInput,
  UpdateUserInput,
  useAuthenticateUserMutation,
  useLoginUserMutation,
  useRegisterUserMutation,
  useUpdateUserMutation,
} from '../gql/generated';
import { LoginRegisterForm } from './ui/LoginRegisterForm';

const Authentication = () => {
  const { placementId } = useParams();
  const [user, setUser] = useUser();

  const [authenticateUser] = useAuthenticateUserMutation({
    onCompleted: (data) => {
      setUser({
        ...data.authenticateUser.user,
        email: data.authenticateUser.user.email as string,
        token: data.authenticateUser.token,
      });
    },
  });

  const [loginUser] = useLoginUserMutation({
    onError: console.log,
    onCompleted: (data) => {
      setUser({
        ...data.loginUser.user,
        email: data.loginUser.user.email as string,
        token: data.loginUser.token,
      });
    },
  });

  const [updateUser] = useUpdateUserMutation({
    onCompleted: (data) => {
      setUser((prevUser) => ({
        ...prevUser!,
        ...data.updateUser,
        email: data.updateUser.email as string,
      }));
    },
  });

  const [createUser] = useRegisterUserMutation({
    onCompleted: (data) => {
      setUser({
        ...data.registerUser.user,
        email: data.registerUser.user.email as string,
        token: data.registerUser.token,
      });
    },
  });

  const onAuthenticate = useCallback(
    async (data: AuthenticateUserInput) => {
      try {
        const result = await authenticateUser({
          variables: {
            input: {
              ...data,
              placementId,
            },
          },
          errorPolicy: 'all',
        });

        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
      } catch (e) {
        console.error(e);

        if (e instanceof Error) {
          console.log(e.stack);
        }

        throw e;
      }
    },
    [authenticateUser, placementId],
  );

  const onLogin = useCallback(
    async (data: LoginUserInput) => {
      const result = await loginUser({
        variables: {
          input: data,
        },
        errorPolicy: 'all',
      });

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
    },
    [loginUser],
  );

  const onUpdateUser = useCallback(
    async (data: Omit<UpdateUserInput, 'id'>) => {
      const result = await updateUser({
        variables: {
          input: {
            id: user!.id,
            ...data,
          },
        },
        errorPolicy: 'all',
      });

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
    },
    [updateUser, user],
  );

  const onRegisterUser = useCallback(
    async (data: RegisterUserInput) => {
      const result = await createUser({
        variables: {
          input: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            placementId,
          },
        },
        errorPolicy: 'all',
      });

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
    },
    [createUser, placementId],
  );

  return (
    <LoginRegisterForm
      onAuthenticate={onAuthenticate}
      onLogin={onLogin}
      onRegisterCreate={onRegisterUser}
      onRegisterUpdate={onUpdateUser}
    />
  );
};

export default Authentication;
